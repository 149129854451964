<template>
    <div id="policy" v-if="policy">
        <div class="row mb-5">
            <div class="col-12"><h1>{{pageTitle}}</h1></div>
        </div>
        <div class="row mt-5">
            <div class="col-12 col-lg-6 pt-3">
              <div class="row">
                <div class="col-1 d-none d-md-block">
                  <div v-bind:class="'status float-left '+policy.status"></div>
                </div>
                <div class="col-11 pl-2 pl-md-4">
                  <div class="policy-title">{{policy.insurance.name}}</div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 pt-5 pt-lg-0">
                <div class="row justify-content-center justify-content-md-end">
                    <div class="col-2 col-sm-2 px-0 py-0 policy-tab text-center" v-show="policy.telemedicine_url">
                        <a :href="policy.telemedicine_url" target="_blank" rel="noreferrer">
                            <div class="py-3 px-0"><span class="green"><svg viewBox="0 0 24 24" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15 3.5H20.5M20.5 3.5V9M20.5 3.5L12.5 11.5" stroke="#FFF"></path> <path d="M11.5 5.5H7.5C6.39543 5.5 5.5 6.39543 5.5 7.5V16.5C5.5 17.6046 6.39543 18.5 7.5 18.5H16.5C17.6046 18.5 18.5 17.6046 18.5 16.5V12.5" stroke="#FFF" stroke-linecap="round"></path> </g></svg></span><p class="d-none d-sm-block mb-0">{{'telemedicine_button_label'|translate}}</p></div>
                        </a>
                    </div>
                    <div class="col-2 col-sm-2 px-0 py-0 policy-tab text-center">
                        <router-link :to="{ name: 'policy_details'}">
                            <div class="py-3 px-0"><span><i class="icon-details"></i></span><p class="d-none d-sm-block mb-0">{{'details_label'|translate}}</p></div>
                        </router-link>
                    </div>
                    <div class="col-2 col-sm-2 px-0 py-0 policy-tab text-center" v-show="!policy.disabled_finance">
                        <router-link :to="{ name: 'policy_finance'}">
                            <div class="py-3 px-0"><span><i class="icon-finance"></i></span><p class="d-none d-sm-block mb-0">{{'finance_label'|translate}}</p></div>
                        </router-link>
                    </div>
                    <div class="col-2 col-sm-2 px-0 py-0 policy-tab text-center" v-show="policy.is_downloadable">
                        <div class="py-3 px-0" role="button"><div v-on:click="downloadPolicy()" id="download_link" target="_blank"><span><i class="icon-download"></i></span><p class="d-none d-sm-block mb-0">{{'download_policy_label'|translate}}</p></div>
                        </div>
                    </div>
                    <div class="col-2 col-sm-2 px-0 py-0 policy-tab text-center">
                        <router-link :to="{ name: 'policy_emergency_contacts'}">
                            <div class="py-3 px-0"><span><i class="icon-contacts"></i></span><p class="d-none d-sm-block mb-0">{{'emergency_contacts_label'|translate}}</p></div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <modal name="policy-download" @before-open="beforeOpen" class="vue-dialog">
            <div class="dialog-content" style="padding:0;">
                <div class="center-text">
                    <img src="~@/assets/images/swisscare-logo.svg" class="dialog-logo" alt="Swisscare">
                    {{'you_can_download_the_policy_label'|translate}}
                    <counter></counter> {{'seconds_label'|translate}}
                    <a :href="pdfUrl" class="btn" target="_blank">{{'download_policy_label'|translate}}</a>
                </div>
            </div>
            <div class="vue-dialog-buttons">
                <button type="button" class="vue-dialog-button" v-on:click="$modal.hide('policy-download')" style="flex: 1 1 50%;">{{'close_label'|translate}}</button>
            </div>
        </modal>


        <modal name="invoice-download" @before-open="beforeOpen" class="vue-dialog">
            <div class="dialog-content" style="padding:0;">
                <div class="center-text">
                    <img src="~@/assets/images/swisscare-logo.svg" class="dialog-logo" alt="Swisscare">
                    {{'you_can_download_the_invoice_label'|translate}}
                    <counter></counter> {{'seconds_label'|translate}}
                    <a :href="invoicePDF" class="btn" target="_blank">{{'download_invoice_label'|translate}}</a>
                </div>
            </div>
            <div class="vue-dialog-buttons">
                <button type="button" class="vue-dialog-button" v-on:click="$modal.hide('invoice-download')" style="flex: 1 1 50%;">{{'close_label'|translate}}</button>
            </div>
        </modal>

        <modal name="receipt-download" @before-open="beforeOpen" class="vue-dialog">
          <div class="dialog-content" style="padding:0;">
            <div class="center-text">
              <img src="~@/assets/images/swisscare-logo.svg" class="dialog-logo" alt="Swisscare">
              {{'you_can_download_the_receipt_label'|translate}}
              <counter></counter> {{'seconds_label'|translate}}
              <a :href="invoicePDF" class="btn" target="_blank">{{'download_receipt_label'|translate}}</a>
            </div>
          </div>
          <div class="vue-dialog-buttons">
            <button type="button" class="vue-dialog-button" v-on:click="$modal.hide('invoice-download')" style="flex: 1 1 50%;">{{'close_label'|translate}}</button>
          </div>
        </modal>

        <router-view :policy="policy"/>

    </div>
</template>

<script>
    import router from '../router'
    import counter from '@/components/modules/counter.vue'
    var qs = require('qs');

    export default {
        name: 'policy',
        components:{
            counter
        },
        data() {
            return {
                pageTitle: null,
                policyHash: null,
                policyId: null,
                policy: null,
                enableDownload: false,
                pdfUrl: false,
                invoicePDF: false,
            }
        },
        methods:{
            getPolicyByHash: function(){
                var self = this

                self.axios({
                    data: qs.stringify({
                        language: 'en',
                        timezone: '+2',
                        device: 'desktop',
                        version: '1.0',

                        authtoken: this.$root.user.authtoken,
                        hash: this.policyHash,
                    }),
                    method: 'post',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    url: self.$root.apiUrl+'getPolicyByHash'
                }).then(function (response) {
                    //console.log(response);
                    if(response.data.result){
                        self.policy = response.data.data
                    }else{
                        self.$notify({
                          group: 'notif',
                          type:'danger',
                          text: response.data.msg
                        });

                        router.replace({ path: "/policies"})
                    }
                }).catch(function (error) {
                    //console.log(error);
                });
            },
            beforeOpen: function(){

            },
            downloadPolicy: function(){
                var self = this

                self.axios({
                    data: qs.stringify({
                        language: 'en',
                        timezone: '+2',
                        device: 'desktop',
                        version: '1.0',

                        authtoken: this.$root.user.authtoken,
                        id: self.policy.id,
                    }),
                    method: 'post',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    url: self.$root.apiUrl+'downloadPolicyPDF'
                }).then(function (response) {
                    if(response.data.result){
                        self.pdfUrl = response.data.data;
                        self.$modal.show('policy-download', {
                        });
                        self.enableDownload = true;
                    }else{
                        self.checkToken(response.data.code);
                        self.showModal('', response.data.msg,[{title: self.$root.labels['close_label']}])
                    }
                }).catch(function (error) {
                    //console.log(error);
                });
            }
        },
        beforeMount(){
            this.policyHash = this.$route.params.id
            this.getPolicyByHash()
        },
        watch: {
            '$route' (to, from) {
                if(from.params.id != to.params.id){
                    this.$router.go()
                }
            }
      }
    }
</script>

<style scoped>
    .policy-title{
        font-size: 32px;
        letter-spacing: 0;
        line-height: 36px;
        font-weight:normal;
    }
    .policy-tab{
        font-size: 11px;
        color: #4E206C;
        letter-spacing: 0;
        line-height: 15px;
        text-transform:uppercase;
        border: 1px solid transparent;
    }

    .policy-tab a{
        color:inherit;
    }

    .policy-tab a > div{
        border: 1px solid transparent;
        border-radius:8px;
    }

    .policy-tab .current > div{
        background: #FFFFFF;
        border-color: #E9E8F3;
    }

    .policy-tab:hover a > div{
        background: #FFFFFF;
        border-color: #E9E8F3;
        box-shadow: 0 10px 20px 0 rgba(159,152,200,0.13);
    }

    .policy-tab span{
        background-image: linear-gradient(-130deg, #C3A6F3 0%, #7B8BF2 100%);
        border-radius:50%;
        width:30px;
        height:30px;
        margin:auto;
        margin-bottom:10px;
        display:block;
        text-align:center;
        color:#fff;
        line-height:30px;
        font-size:14px;

        &.green{
          background-image: linear-gradient(-130deg, rgb(200,229,195) 0%, rgb(145,214,213) 100%);
        }
    }

    .center-text{
        text-align:center;
        color: #4E206C;
        padding:40px;
    }
    .center-text .btn{
        margin-top:30px;
        color:#fff;
    }

    .dialog-logo{
        height:30px;
        display:table;
        margin:0 auto 20px;
    }
</style>

<template>
    <div id="popright">
        <div class="close" v-on:click="$parent.showPopright = false"><i class="icon-cross"></i></div>
        <div class="scrolling-inner">
            <div class="inner px-3 py-5 px-md-5">
                <changeAddress :policy="policy"></changeAddress>
            </div>
        </div>
    </div>
</template>

<script>
    import changeAddress from '@/components/modules/pr_changeAddress.vue'  

    var qs = require('qs'); 

    export default {
        components:{
            changeAddress
        },
        props:['policy'],
    }
</script>

<style scoped>
    #popright{
        width:90%;
        max-width:700px;
        background:#fff;
        position:fixed;
        right:0;
        top:0;
        bottom:0;
        left:auto;
        z-index:1000;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.13);
        -webkit-transition: 600ms all;  
        -moz-transition:     600ms all;
        -ms-transition:      600ms all; 
        -o-transition:       600ms all;  
    }

    #popright .close{
        width:30px;
        height:30px;
        line-height:30px;
        border-radius:50%;
        background: #4E206C;
        color: #FFFFFF;
        text-align:center;
        font-size:14px;
        position:absolute;
        z-index:1002;
        top: 30px;
        left: -15px;
        opacity:1;
        float:none;
    }
    
    #popright:before{
        background: rgba(123,118,152,0.30);
        position:fixed;
        left:0;
        width:100vw;
        height:100vh;
        top:0;
        content:'';
        z-index:-1;
    }
    
    #popright .inner{
        background:#fff;
        z-index:1001;
        position:relative;
        height:100vh;
        width:100%;
        overflow:auto;
    }
</style>
import axios from 'axios'
import {baseUrl, generateClientTokenEndpoint, startPaymentWithNonceEndpoint, startChangeCardWithNonceEndpoint} from "./config";

var qs = require('qs'); 

const backendService = axios.create({
    baseURL: baseUrl,
})

backendService.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export const getNewClientToken = (payloadForClientTokenGeneration) => backendService.post(generateClientTokenEndpoint, qs.stringify(payloadForClientTokenGeneration))

export const startPaymentOnServer = (payload) => backendService.post(startPaymentWithNonceEndpoint, qs.stringify(payload))
export const startChangeCardOnServer = (payload) => backendService.post(startChangeCardWithNonceEndpoint, qs.stringify(payload))

export default backendService;

<template>
    <div>
      <div class="row my-5 justify-content-end">
        <div class="col-12 text-center">
          <div class="btn-tab mx-auto" v-if="this.policy.insurance.reoccuring == 2" v-on:click="showChangeCard = true"><i class="icon-edit"></i>{{'change_card_label'|translate}}</div>
        </div>
      </div>

      <div class="row my-3" v-if="payments.unpaid.length > 1">
        <div class="col-12">
          <div class="alert alert-info">
            <div class="alert-heading">
              <strong>Important notice</strong>
            </div>
            <br/>
            Please be advised that there are multiple open invoices in your account. As per our payment policy, we prioritize settling the oldest invoice first.  To ensure uninterrupted insurance coverage and complete all outstanding payments, we strongly recommend paying all open invoices.
            <br/>
            <br/>
            Thank you for your attention to this matter.
          </div>
        </div>
      </div>
        <div class="row mb-5" v-if="payments.paid || payments.unpaid || payments.refunded">
            <div class="col-12 mb-5" v-show="payments.unpaid.length">
                <h3 class="text-uppercase">{{'outstanding_payments_label'|translate}}</h3>
                <div class="table">
                    <div v-for="(payment,index) in payments.unpaid" class="row tbody justify-content-end mb-3 mb-md-0 pt-3 pb-0">
                        <div class="col-12">
                            <div class="row mb-3">
                                <div class="th col-1"><div v-bind:class="'status '+payment.status"></div></div>
                                <div class="td col-4 col-md-2"><div><span class="d-block mr-2"><small>{{'from_label'|translate}}:</small></span>{{payment.from_date}}</div></div>
                                <div class="td col-4 col-md-2">
                                    <div>
                                        <span class="d-block mr-2">
                                            <small>{{'to_label'|translate}}:</small>
                                        </span>{{payment.to_date}}
                                    </div>
                                </div>
                                <div class="td col-5 col-md-1">
                                  <div class="w-100 text-right" v-show="payment.remaining_premium > 0 && index === 0 && (policy.status != 'cancelled' || ['SPSS', 'HMIE'].includes(policy.insurance.code))">
                                    <em v-on:click="downloadInvoice(payment.id, 1)" class="btn-tab mt-1 d-inline-block" :title="'download_invoice_label'|translate"><i class="icon-download"></i></em>
                                  </div>
                                </div>
                                <div class="td col-3 col-md-1 text-center offset-md-2"><div v-show="payment.remaining_premium > 0"><small>{{payment.currency}}</small><span>{{payment.remaining_premium}}</span></div></div>
                                <div class="td col-12 col-md-3 pt-2 ">
                                  <div class="mx-auto cpointer" v-if="policy.insurance.reoccuring != 2" v-bind:class="'status-badge '+payment.status" v-on:click="loadPay(payment, policy.insurance.code)" v-show="payment.remaining_premium > 0 && index === 0 && (policy.status != 'cancelled' || ['SPSS', 'HMIE'].includes(policy.insurance.code))">{{'pay_now_label'|translate}}</div>
                                  <div class="mx-auto cpointer" v-if="policy.insurance.reoccuring == 2" v-bind:class="'status-badge '+payment.status" v-on:click="showChangeCard = true" v-show="payment.remaining_premium > 0 && policy.status != 'cancelled'">{{'change_card_label'|translate}}</div>
                                </div>
                                <div class="td col-12 col-md-1 pt-2" v-show="payment.payments.length"><i class="icon-down-arrow my-md-3 mx-auto" v-on:click="toggleView($event)"></i></div>

                            </div>
                            <div class="row payment-details mt-4" v-show="payment.payments.length">
                                <div class="col-12 px-4 py-4">
                                    <div class="row mb-3">
                                        <div class="col col-md-3 th-col">{{'payment_type_label'|translate}}</div>
                                        <div class="col col-md-6 th-col">{{'paid_on_label'|translate}}</div>
                                        <div class="col col-md-3 th-col">{{'amount_label'|translate}}</div>
                                    </div>
                                    <div class="row" v-for="detail in payment.payments">
                                        <div class="col col-md-3 td-col py-4">{{detail.payment_type}}</div>
                                        <div class="col col-md-6 td-col py-4">{{detail.payment_date}}</div>
                                        <div class="col col-md-3 td-col py-4">{{detail.value}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-12 mb-5" v-show="payments.paid.length">
                <h3 class="text-uppercase">{{'completed_payments_label'|translate}}</h3>
                <div class="table">
                    <div v-for="payment in payments.paid" class="row tbody justify-content-end mb-3 mb-md-0 pt-3 pb-0">
                        <div class="col-12">
                            <div class="row mb-3">
                                <div class="th col-1 col-md-1"><div v-bind:class="'status '+payment.status"></div></div>
                                <div class="td col-3 col-md-2"><div><span class="d-block mr-2"><small>{{'from_label'|translate}}:</small></span>{{payment.from_date}}</div></div>
                                <div class="td col-3 col-md-2">
                                    <div>
                                        <span class="d-block mr-2">
                                            <small>{{'to_label'|translate}}:</small>
                                        </span>{{payment.to_date}}
                                    </div>
                                </div>
                                <div class="td col-5 col-md-1">
                                  <div class="w-100 text-right">
                                    <em v-on:click="downloadInvoice(payment.id, 0)" class="btn-tab mt-1 d-inline-block" :title="'download_receipt_label'|translate"><i class="icon-download"></i></em>
                                  </div>
                                </div>
                                <div class="td col-6 offset-1 offset-md-0 col-md-2">
                                    <div>
                                        <span class="d-block mr-md-2">
                                            <small>{{'payment_date_label'|translate}}:</small>
                                        </span>
                                        {{payment.latest_payment ? payment.latest_payment.payment_date : ''}}
                                    </div>
                                </div>
                                <div class="td col-5 col-md-1 pr-4 pr-md-0 text-center"><div class="text-right w-100"><small>{{payment.currency}}</small><span>{{payment.premium}}</span></div></div>
                                <div class="td col-12 col-md-2 pt-3 pt-md-0">
                                  <div class="mx-auto" v-bind:class="'status-badge '+payment.status">{{'paid_label'|translate}}</div>
                                </div>
                                <div class="td col-12 col-md-1 pt-3 pt-md-0 " v-show="payment.payments.length"><i class="icon-down-arrow my-md-3 mx-auto" v-on:click="toggleView($event)"></i></div>
                            </div>
                            <div class="row payment-details mt-4" v-show="payment.payments.length">
                                <div class="col-12 px-4 py-4">
                                    <div class="row mb-3">
                                        <div class="col col-md-3 th-col">{{'payment_type_label'|translate}}</div>
                                        <div class="col col-md-6 th-col">{{'paid_on_label'|translate}}</div>
                                        <div class="col col-md-3 th-col">{{'amount_label'|translate}}</div>
                                    </div>
                                    <div class="row" v-for="detail in payment.payments">
                                        <div class="col col-md-3 td-col py-4">{{detail.payment_type}}</div>
                                        <div class="col col-md-6 td-col py-4">{{detail.payment_date}}</div>
                                        <div class="col col-md-3 td-col py-4">{{detail.value}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mb-5" v-show="payments.refunded.length">
                <h3 class="text-uppercase">{{'refund_payments_label'|translate}}</h3>
                <div class="table">
                    <div v-for="payment in payments.refunded" class="row tbody justify-content-end mb-3 mb-md-0 pt-3 pb-0">
                        <div class="col-12">
                            <div class="row mb-3">
                                <div class="th col-1"><div v-bind:class="'status '+payment.status"></div></div>
                                <div class="td col-2"><div><span class="d-block mr-2"><small>{{'from_label'|translate}}:</small></span>{{payment.from_date}}</div></div>
                                <div class="td col-2">
                                    <div>
                                        <span class="d-block mr-2">
                                            <small>{{'to_label'|translate}}:</small>
                                        </span>{{payment.to_date}}
                                    </div>
                                </div>
                                <div class="td col-2">
                                    <div>
                                        <span class="d-block mr-2">
                                            <small>{{'payment_date_label'|translate}}:</small>
                                        </span>
                                        {{payment.latest_payment ? payment.latest_payment.payment_date : ''}}
                                    </div>
                                </div>
                                <div class="td col-1 text-center"><div><small>{{payment.currency}}</small><span>{{payment.premium}}</span></div></div>
                                <div class="td col-3"><em v-on:click="downloadInvoice(payment.id, 0)" class="btn-tab mt-1" :title="'download_receipt_label'|translate"><i class="icon-download"></i></em><div class="mx-auto" v-bind:class="'status-badge '+payment.status">{{'refunded_label'|translate}}</div></div>
                                <div class="td col-1" v-show="payment.payments.length"><i class="icon-down-arrow my-3" v-on:click="toggleView($event)"></i></div>

                            </div>
                            <div class="row payment-details mt-4" v-show="payment.payments.length">
                                <div class="col-12 px-4 py-4">
                                    <div class="row mb-3">
                                        <div class="col-12 col-md-3 th-col">{{'payment_type_label'|translate}}</div>
                                        <div class="col-12 col-md-6 th-col">{{'paid_on_label'|translate}}</div>
                                        <div class="col-12 col-md-3 th-col">{{'amount_label'|translate}}</div>
                                    </div>
                                    <div class="row" v-for="detail in payment.payments">
                                        <div class="col-12 col-md-3 td-col py-4">{{detail.payment_type}}</div>
                                        <div class="col-12 col-md-6 td-col py-4">{{detail.payment_date}}</div>
                                        <div class="col-12 col-md-3 td-col py-4">{{detail.value}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PayNow v-show="showPayNow" :profile="$root.user" :formCode="this.formCode"></PayNow>
        <changeCard v-if="showChangeCard" :policy="this.$parent.policy.id" :profile="$root.user"></changeCard>
    </div>
</template>

<script>
import PayNow from '@/components/modules/PayNow.vue'
import changeCard from '@/components/modules/changeCard.vue'
var qs = require('qs');

export default {
    name: 'policy_finance',
    props:['policy'],
    components:{
        PayNow,
        changeCard
    },
    data(){
        return{
            showPayNow : false,
            formCode: null,
            showChangeCard : false,
            provider: null,
            instructions: null,
            selectedPayment : null,
            methods: null,
            card: null,
            payments : {
                paid: null,
                unpaid: null,
                refunded: null,
            }
        }
    },
    methods:{
        /*downloadInvoice(id){
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: self.$root.user.authtoken,
                    id: id,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'downloadInvoicePDF'
            }).then(function (response) {
                if(response.data.result){
                    window.open(response.data.data,'_blank');
                }else{
                    self.$notify({
                      group: 'notif',
                      type:'danger',
                      text: self.$root.labels['cannot_download_receipt_label']
                    });
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
            return false;
        },*/

        downloadInvoice(id, target){
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: this.$root.user.authtoken,
                    id: id,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'downloadInvoicePDF'
            }).then(function (response) {
                if(response.data.result){
                    self.$parent.invoicePDF = response.data.data;
                    if(target){
                      self.$modal.show('invoice-download');
                    } else {
                      self.$modal.show('receipt-download');
                    }
                }else{
                    self.$notify({
                      group: 'notif',
                      type:'danger',
                      text: self.$root.labels['cannot_download_receipt_label']
                    });
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                //console.log(error);
            });
        },
        toggleView(event){
            $(event.target).closest('.tbody').toggleClass('active');
        },
        loadPay(payment, code){
              this.showPayNow = true
                this.selectedPayment = payment
                this.formCode = code
        },
        toggleChangeCard(){
              this.showChangeCard = true
        },
        getPolicyPayments: function(){
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: self.$root.user.authtoken,
                    id: self.$parent.policy.id,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getPolicyPayments'
            }).then(function (response) {
                if(response.data.result){
                    //console.log(response.data.data)
                    self.card = response.data.data.card
                    self.payments.paid = response.data.data.paid
                    self.payments.unpaid = response.data.data.unpaid
                    self.payments.refunded = response.data.data.refunded
                    self.provider = response.data.data.provider
                    self.instructions = response.data.data.instructions
                    self.methods = response.data.data.methods

                  if(location.href.indexOf("pay") > -1 && self.payments.unpaid){
                    self.loadPay(response.data.data.unpaid[0], response.data.data.code)
                  }

                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                //console.log(error);
            });
        }
    },
    beforeMount() {
        this.getPolicyPayments()
        this.$parent.pageTitle = 'Finance'
    }
}
</script>

<style scoped>
    .cpointer{
        cursor:pointer;
    }
    .payment-details{
        background-color: rgba(199,215,224,0.15);
    }
    .payment-details .th-col{
        font-weight: 600;
        font-size: 12px;
        color: #3A3A3A;
        letter-spacing: 0;
        text-transform:uppercase;
    }
    .payment-details .td-col{
        border-top: 1px solid #E4EBEF;
        font-size: 12px;
        color: #737E88;
    }

    .row .payment-details{
        display:none;
    }
    .row.active .payment-details{
        display:block;
    }
    .row.active{
        border-color:#ECEBF4;
        box-shadow: 0 5px 20px 0 rgba(78,75,96,0.10);
    }

    .table .tbody:hover{
        cursor: default;
    }
    .table .tbody.active .icon-down-arrow{
        transform:rotate(180deg);
    }

    .icon-down-arrow{
        cursor:pointer;
    }

    .td span{
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>

<template>
    <div>
        <div class="row mt-5">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-5" v-for="contact in erContacts">
                        <h6 class="text-uppercase mb-3 mt-3">{{contact.category.name}}</h6>
                        <div class="card px-3 py-3 px-md-5 py-md-5">
                            <div class="row">
                                <div class="col-12"><p class="mb-0">{{contact.name}}</p></div>
                                <div class="col-12"><p class="mb-0">{{contact.address}}</p></div>
                                <div class="col-12"><p class="mb-0">{{contact.zip}} {{contact.city}}</p></div>
                                <div class="col-12"><p class="mb-0">{{contact.country_obj.name}}</p></div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12" v-show="contact.phone"><p class="mb-1 text-info"><a :href="'tel:'+contact.phone"><i class="icon-phone mr-2"> </i> {{contact.phone}}</a></p></div>
                                <div class="col-12" v-show="contact.fax"><p class="mb-1 text-info"><a :href="'tel:'+contact.fax"><i class="icon-fax mr-2"> </i> {{contact.fax}}</a></p></div>
                                <div class="col-12" v-show="contact.email"><p class="text-info text-truncate mb-0"><a :href="contact.email"><i class="icon-envelope mr-2"> </i> {{contact.email}}</a></p></div>
                                <div class="col-12" v-show="contact.opening"><p class="text-info mb-0"><i class="icon-time mr-2 h-100"> </i> {{contact.opening}}</p></div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12" v-show="contact.description"><p class="mb-1 text-info" :inner-html.prop="contact.description"></p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
var qs = require('qs'); 
    
export default {
    name: 'policy_emergency_contacts',
    data(){
        return{
            erContacts: null,
            contactUrl: 'https://swisscare.com/contact-us/'
        }
    },
    methods:{
        getErContacts: function(){
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: self.$root.user.authtoken,
                    id: self.$parent.policy.id,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getErContacts'
            }).then(function (response) {
                if(response.data.result){
                    self.erContacts = response.data.data
                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });  
        }
    },
    mounted() {
        this.getErContacts()
        this.$parent.pageTitle = 'Contacts'
    }
}
</script>

<style scoped>
    .icon-time{
        border:1px solid #7B7698;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        text-align: center;
        line-height: 14px;
        font-size: 10px;
        display:inline-block;
        padding-right:2px;
    }
</style>
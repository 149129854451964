<template>
    <div class="login-box">
        <div data-vv-scope="validate-form">
            <div class="row justify-content-center">
                <div class="col-auto mb-3 mt-5">
                    <img src="~@/assets/images/swisscare-logo.svg" width="190px" height="35px" alt="Swisscare">
                </div>
                <div class="col-12 mt-5">
                    <p class="text-center small text-info">{{'verification_sent_by_mail'|translate}} {{$parent.input.email}}</p>
                </div>
                <div class="col-12 mt-5">
                    <fieldset>
                        <label class="text-center">{{'your_verification_code_label'|translate}}</label>
                        <input type="text" id="verification" name="verification" v-model="$parent.input.verification" v-validate="'required'" class="text-center" v-on:keyup.enter="login2fa" tabindex="1">
                    </fieldset>
                    <span class="field-error">{{ errors.first('validate-form.verification') }}</span>
                </div>
                <div class="col-auto mt-5">
                    <button type="button" class="btn" v-on:click="login2fa" tabindex="2">{{'sign_in_securely_label'|translate}}</button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-auto mt-3">
                <a href="#" class="link" v-on:click="cancelLogin()">{{'cancel_label'|translate}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router'
var qs = require('qs');

export default {
    name: 'twofa',
    methods:{
        login2fa() {
            var self = this
            self.$validator.validate().then(result => {
                if(result){
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            username: this.$parent.input.email,
                            password: this.$parent.input.password,
                            token: this.$parent.input.verification,
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'login2fa'
                    }).then(function (response) {
                        if(response.data.result){
                            self.$root.user.isValid = true
                            self.$root.user = response.data.data
                            localStorage.authtoken = response.data.data.authtoken
                            localStorage.need_brokerage = response.data.data.need_brokerage
                            setTimeout(function(){
                              router.replace({ path: "/policies" })
                            },1000);
                        }else{
                            self.showModal(self.$root.labels['login_label'], response.data.msg,[{title: self.$root.labels['close_label']}])
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            });
        },
        cancelLogin: function(){
            this.$parent.user.isValid = false;
            this.$parent.clickedLogin = false;
        }
    }
}
</script>

<style scoped>
    .text-info{
        text-transform:none;
        font-size:14px;
        color:#3A3A3A;
    }
</style>

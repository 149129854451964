<template>
    <div v-if="$root.user"> 
        <form data-vv-scope="claims-form">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-12">
                    <div class="row  justify-content-center">
                        
                        <div class="col-12 col-md-4">
                            <div class="row">
                                <div class="col-12"><h5 class="mt-5">{{'account_details_label'|translate}}</h5></div>
                                <div class="col-12 pt-3">
                                    <label class="cb-container mb-4">{{'use_iban_label'|translate}}
                                        <input type="checkbox" name="use_iban" v-model="$parent.claim.bank.use_iban">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <!--<div class="col-12">
                                    <p class="text-muted">{{'use_iban_description'|translate}}</p>
                                </div>-->
                                <div class="col-12" v-show="$parent.claim.bank.use_iban">
                                    <fieldset>
                                        <label>{{'iban_label'|translate}}</label>
                                        <input type="text" id="iban" name="iban" v-model="$parent.claim.bank.iban" v-validate="$parent.claim.bank.use_iban ? 'required' : ''">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.iban')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12" v-show="!$parent.claim.bank.use_iban">
                                    <fieldset>
                                        <label>{{'bank_account_number_label'|translate}}</label>
                                        <input type="text" id="account_number" name="account_number" v-model="$parent.claim.bank.account_number" v-validate="!$parent.claim.bank.use_iban ? 'required' : ''">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.account_number')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12" v-if="!$parent.claim.bank.use_iban">
                                    <fieldset>
                                        <label>{{'swift_label'|translate}}</label>
                                        <input type="text" id="swift" name="swift" v-model="$parent.claim.bank.swift">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.swift')">{{'this_field_required_label'|translate}}</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 col-md-4" v-if="!$parent.claim.bank.use_iban" v-bind:class="{'fadedOut': $parent.claim.bank.use_iban}">
                            <div class="row">
                                <div class="col-12 mb-4"><h5 class="mt-5">{{'bank_details_label'|translate}}</h5></div>
                                <div class="col-12 mt-5">
                                    <fieldset>
                                        <label>{{'institute_name_label'|translate}}</label>
                                        <input type="text" id="name" name="name" v-model="$parent.claim.bank.name" v-validate="$parent.claim.bank.use_iban ? '' : 'required'">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.name')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'address1_label'|translate}}</label>
                                        <input type="text" id="address1" name="address1" v-model="$parent.claim.bank.address1" v-validate="$parent.claim.bank.use_iban ? '' : 'required'">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.address1')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'address2_label'|translate}}</label>
                                        <input type="text" id="address2" name="address2" v-model="$parent.claim.bank.address2">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.address2')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'zip_code_label'|translate}}</label>
                                        <input type="text" id="bank_zip" name="bank_zip" v-model="$parent.claim.bank.zip" v-validate="$parent.claim.bank.use_iban ? '' : 'required'">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.bank_zip')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'city_label'|translate}}</label>
                                        <input type="text" id="bank_city" name="bank_city" v-model="$parent.claim.bank.city" v-validate="$parent.claim.bank.use_iban ? '' : 'required'">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.bank_city')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'country_label'|translate}}</label>
                                        <div class="select-wrapper">
                                            <select id="bank_country" name="bank_country" v-model="$parent.claim.bank.country" v-validate="$parent.claim.bank.use_iban ? '' : 'required'">
                                                <option v-for="country in countries" v-bind:value="country.name">{{country.name}}</option>    
                                            </select>
                                        </div>
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.bank_country')">{{'this_field_required_label'|translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="row">
                                <div class="col-12"><h5 class="mt-5">{{'account_holder_details_label'|translate}}</h5></div>
                                <div class="col-12 pt-3">
                                    <label class="cb-container mb-4">{{'im_company_label'|translate}}
                                        <input type="checkbox" name="holder_iscompany" v-model="$parent.claim.holder.iscompany">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-12" v-show="$parent.claim.holder.iscompany">
                                    <fieldset>
                                        <label>{{'company_label'|translate}}</label>
                                        <input type="text" id="holder_company" name="holder_company" v-model="$parent.claim.holder.company" v-validate="$parent.claim.holder.iscompany ? 'required' : ''">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.firstname')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'firstname_label'|translate}}</label>
                                        <input type="text" id="firstname" name="firstname" v-model="$parent.claim.holder.firstname" v-validate="!$parent.claim.holder.iscompany ? 'required' : ''">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.firstname')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'lastname_label'|translate}}</label>
                                        <input type="text" id="lastname" name="lastname" v-model="$parent.claim.holder.lastname" v-validate="!$parent.claim.holder.iscompany ? 'required' : ''">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.lastname')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'address_label'|translate}}</label>
                                        <input type="text" id="address" name="address" v-model="$parent.claim.holder.address" v-validate="'required'">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.address')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'address2_label'|translate}}</label>
                                        <input type="text" id="address2" name="address2" v-model="$parent.claim.holder.address2">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.address2')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'zip_code_label'|translate}}</label>
                                        <input type="text" id="zip" name="zip" v-model="$parent.claim.holder.zip" v-validate="'required'">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.zip')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'city_label'|translate}}</label>
                                        <input type="text" id="city" name="city" v-model="$parent.claim.holder.city" v-validate="'required'">
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.city')">{{'this_field_required_label'|translate}}</span>
                                </div>
                                <div class="col-12">
                                    <fieldset>
                                        <label>{{'country_label'|translate}}</label>
                                        <div class="select-wrapper">
                                            <select id="country" name="country" v-model="$parent.claim.holder.country" v-validate="'required'">
                                                <option v-for="country in countries" v-bind:value="country.name">{{country.name}}</option>    
                                            </select>
                                        </div>
                                    </fieldset>
                                    <span class="field-error" v-show="errors.first('claims-form.country')">{{'this_field_required_label'|translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-5">
                        <div class="col-auto"><button class="btn empty" type="button" v-on:click="prevStep"><i class="icon-arrow_left mr-3"></i>{{'step_back_label'|translate}}</button></div>
                        <div class="col-auto"><button class="btn" type="button" v-on:click="nextStep">{{'next_label'|translate}}</button></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import router from '../router'
var qs = require('qs'); 
    
export default {
    name: 'claims_bank',
    components:{
          
    },
    props:{
        
    },
    data(){
        return{
            countries: null,
        }
    },
    methods:{
        getCountries:function(){
            var self = this
            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: self.$root.user.authtoken,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getCountries'
            }).then(function (response) {
                if(response.data.result){
                    self.countries = response.data.data
                }else{
                    self.$notify({
                      group: 'notif',
                      type:'danger',
                      text: response.data.msg
                    });
                    
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);

                self.$notify({
                  group: 'notif',
                  type:'danger',
                  text: error
                });
            });
        },
        nextStep: function(){
            var self = this
            self.$validator.validateAll('claims-form').then((result) => {
                if (result){
                    this.$parent.currentStep = 6;  
                    router.replace({ path: "/claims/finish"})
                }
            });
        },
        prevStep:function(){
             this.$parent.currentStep = 4;  
            router.replace({ path: "/claims/upload"})
        }
    },
    beforeMount() {
        this.getCountries()
        if(this.$parent.currentStep != 5){
            this.$parent.currentStep = 1
            router.replace({ path: "/claims/policies"});
        }
        //this.$parent.currentStep = 5
    }
}
</script>

<style scoped>
    fieldset{
        background: rgba(58,58,58,0.05);
        border-radius:4px;
        padding:10px;
        border:none;
        margin:5px 0;
    }
    
    fieldset.no-border{
        background:none;
    }
    
    h5{
        font-weight:600;
        font-size: 13px;
        color: #3A3A3A;
        letter-spacing: 0;
        line-height: 18px;
    }
    
    .text-muted{
        font-size:12px;
    }
    
    .fadedOut{
        opacity:.1;
    }
</style>
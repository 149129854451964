import axios from 'axios'
import {baseUrl} from "./config";

const backendService = axios.create({
    baseURL: baseUrl,
})

backendService.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

export default backendService;

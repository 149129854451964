<template>
    <div id="dashboard">
      <div class="alert text-center" v-if="$root.user.need_switching" >
        <span v-html="$root.labels['switching_banner_label']"></span>

        <router-link :to="{path: '/switching-plans'}"><button class="btn ml-4">Switch now</button></router-link>
      </div>
        <div class="container content-container">
            <div class="row mt-5 mb-5 justify-content-md-center px-2 px-md-0">
                <div class="d-none d-sm-block col-sm-4">
                    <nav class="float-left pt-2">
                        <ol>
                            <li class="float-left mr-3 mr-lg-5"><router-link :to="{path: '/policies'}"><i class="icon-policies"></i>{{'policies_label'|translate}}</router-link></li>
                            <li class="float-left mr-3 mr-lg-5"><router-link :to="{path: '/claims/policies'}"><i class="icon-claims"></i>{{'claims_label'|translate}}</router-link></li>
                        </ol>
                        <div class="clear"></div>
                    </nav>
                </div>
                <div class="col-8 col-sm-4 text-md-center logo">
                    <router-link :to="{path: '/policies'}"><img src="~@/assets/images/swisscare-logo.svg" class="h-100 mw-100" alt="Swisscare"></router-link>
                </div>
                <div class="col-4 col-sm-4">
                    <nav class="row justify-content-end float-right pt-2">
                        <ol>
                            <li class="float-left ml-3 ml-lg-4" v-on:click="showNotif = !showNotif">
                                <span class="circle" v-if="notifications.length"></span>
                                <i class="icon-notification"></i>
                                <Notifications v-if="showNotif && notifications.length"></Notifications>
                            </li>
                            <li class="float-left ml-3 ml-lg-4" >
                                <Slide right class="d-block d-sm-none" noOverlay>
                                    <ol class="d-block ml-5">
                                        <li class="mb-2 d-block mb-4 text-left"><router-link :to="{path: '/policies'}"><i class="icon-policies d-inline-block mr-3 mb-0 float-left mt-0"></i>{{'policies_label'|translate}}</router-link></li>
                                        <li class="mb-2 d-block mb-4 text-left"><router-link :to="{path: '/claims/policies'}"><i class="icon-claims d-inline-block mr-3 mb-0 float-left mt-0"></i>{{'claims_label'|translate}}</router-link></li>
                                        <li class="mb-2 d-block mb-4 text-left"><router-link :to="{path: '/profile'}"><i class="icon-profile d-inline-block mr-3 mb-0 float-left mt-0"></i>{{'profile_label'|translate}}</router-link></li>
                                        <li class="mb-2 d-block mb-4 text-left"><a :href="contactPage" target="_blank"><i class="icon-envelope d-inline-block mr-3 mb-0 float-left mt-0"></i>{{'contact_label'|translate}}</a></li>
                                    </ol>
                                </Slide>
                            </li>
                            <li class="float-left ml-3 ml-lg-4 d-none d-sm-block"><router-link :to="{path: '/profile'}"><i class="icon-profile"></i></router-link></li>
                            <li class="float-left ml-3 ml-lg-4 d-none d-sm-block"><a :href="contactPage" target="_blank"><i class="icon-envelope"></i></a></li>
                        </ol>
                        <div class="clear"></div>
                    </nav>
                </div>
            </div>

            <router-view/>

        </div>

        <footer class="px-3 pt-md-5 pb-4 px-md-5 mt-5" v-cloak>
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3">
                    <ol>
                        <li><a :href="$root.urls.documents" target="_blank">{{'document_center_label'|translate}}</a></li>
                        <li><a :href="$root.urls.ios" target="_blank">{{'ios_app_label'|translate}}</a></li>
                        <li><a :href="$root.urls.android" target="_blank">{{'android_app_label'|translate}}</a></li>
                    </ol>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <ol>
                        <li><a :href="$root.urls.broker" target="_blank">{{'become_a_broker_label'|translate}}</a></li>
                        <li><a :href="$root.urls.affiliate" target="_blank">{{'get_affiliate_label'|translate}}</a></li>
                        <li><a :href="$root.urls.risk" target="_blank">{{'risk_carrier_label'|translate}}</a></li>
                        <li><a :href="$root.urls.service_providers" target="_blank">{{'service_providers_label'|translate}}</a></li>
                    </ol>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <ol>
                        <li><a :href="$root.urls.about" target="_blank">{{'about_swisscare_label'|translate}}</a></li>
                        <li><a :href="$root.urls.about" target="_blank">{{'legal_information_label'|translate}}</a></li>
                        <li><a :href="$root.urls.gdpr" target="_blank">{{'gdpr_data_protection_label'|translate}}</a></li>
                    </ol>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <ol class="socials float-none float-lg-right mt-5 mt-lg-0">
                        <li class="mr-4 ml-0 ml-lg-4 mr-lg-0"><a href="#"><i class="icon-facebook text-info"></i></a></li>
                        <li class="mr-4 ml-0 ml-lg-4 mr-lg-0"><a href="#"><i class="icon-twitter text-info"></i></a></li>
                        <li class="mr-4 ml-0 ml-lg-4 mr-lg-0"><a href="#"><i class="icon-linkedin text-info"></i></a></li>
                    </ol>
                </div>
            </div>
            <div class="row justify-content-center mt-5 pt-3 copyright">
                <div class="col-auto">{{'copyright_label'|translate}}</div>
            </div>
        </footer>
    </div>
</template>

<script>
import router from '../router'
import Notifications from '@/components/Notifications.vue'
import { Slide } from 'vue-burger-menu'
var qs = require('qs');

export default {
    name: 'Dashboard',
    components:{
        Notifications,
        Slide
    },
    data(){
        return{
            notifications: null,
            showNotif: false
        }
    },
    methods: {

        getNotifs:function(){
            var self = this
            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: this.$root.user.authtoken,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getNotifications'
            }).then(function (response) {
                if(response.data.result){
                    self.notifications = response.data.data
                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    },
    beforeMount(){
        this.getNotifs();
        if(!this.$root.user.authtoken){
            //localStorage.clear();
            localStorage.removeItem("authtoken");
            localStorage.removeItem("need_brokerage");
        }
    }
}
</script>

<style scoped>
    #dashboard .wavesanim{
        top:0;
        bottom:auto;
        position: absolute;
        transform: rotate(180deg) translateY(50%);
    }

    .logo{
        height:33px;
    }

    .circle{
        width:8px;
        height:8px;
        background:red;
        display:inline-block;
        border-radius:50%;
        position:absolute;
    }

</style>

<template>
    <div v-if="$root.user">
        <form data-vv-scope="claims-form">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="row">
                        <div class="col-12">
                            <fieldset>
                                <label>{{'date_of_the_event_label'|translate}}</label>

                                <VueDatePicker
                                    v-model="selectedDate"
                                    format="YYYY-MM-DD"
                                    placeholder=""
                                    @onChange="setDate()"
                                    :min-date="this.$parent.claim.is_migrated ? new Date('2010-01-01') : this.$parent.claim.start_date ? new Date(this.$parent.claim.start_date) : new Date()"
                                    :max-date="this.$parent.claim.end_date < new Date() ? new Date(this.$parent.claim.end_date) : new Date()"

                                />

                            </fieldset>
                            <span class="field-error" v-show="this.datepickerError">{{'this_field_required_label'|translate}}</span>
                        </div>
                        <div class="col-6 col-md-6" v-if="currencies">
                            <fieldset>
                                <label>{{'currency_label'|translate}}</label>
                                <div class="select-wrapper">
                                    <select id="currency" name="currency" v-model="$parent.claim.price.currency" v-validate="'required'">
                                        <option v-for="currency in currencies.primary" v-bind:value="currency.currency">{{currency.currency}}</option>
                                        <option disabled>----</option>
                                        <option v-for="currency in currencies.secondary" v-bind:value="currency.currency">{{currency.currency}}</option>
                                    </select>
                                </div>
                            </fieldset>
                            <span class="field-error" v-show="errors.first('claims-form.currency')">{{'this_field_required_label'|translate}}</span>
                        </div>
                        <div class="col-6 col-md-6">
                            <fieldset>
                                <label>{{'amount_label'|translate}}</label>
                                <input type="number" id="amount" name="amount" v-model="$parent.claim.price.amount" v-validate="'required'">
                            </fieldset>
                            <span class="field-error" v-show="errors.first('claims-form.amount')">{{'this_field_required_label'|translate}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="row">
                        <div class="col-12 col-md-12 mt-3 mt-md-0">
                            <fieldset class="no-border">
                                <label class="mb-4">{{'are_you_working_label'|translate}}</label>

                                <label class="cb-container mt-3">{{'no_label'|translate}}
                                    <input type="radio" name="working" value="0" v-model="$parent.claim.working" v-validate="'required'">
                                    <span class="checkmark"></span>
                                </label>

                                <label class="cb-container mt-3">{{'yes_more_than_label'|translate}}
                                    <input type="radio" name="working" value="1" v-model="$parent.claim.working" v-validate="'required'">
                                    <span class="checkmark"></span>
                                </label>

                            </fieldset>

                            <span class="field-error" v-show="errors.first('claims-form.working')">{{'please_select_label'|translate}}</span>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center mt-2 mt-md-5">
                <div class="col-auto"><button class="btn empty" type="button" v-on:click="prevStep"><i class="icon-arrow_left mr-3"></i>{{'step_back_label'|translate}}</button></div>
                <div class="col-auto"><button class="btn" type="button" v-on:click="nextStep">{{'next_label'|translate}}</button></div>
            </div>
        </form>
    </div>
</template>

<script>
import router from '../router'
var qs = require('qs');
import DatePicker from './Datepicker/DatePicker.vue'
import moment from 'moment'

export default {
    name: 'claims_details',
    components:{
        DatePicker
    },
    props:{

    },
    data(){
        return{
            dp_lang: null,
            currencies: null,
            datepickerError: false,
            selectedDate: null
        }
    },
    methods:{
        getCurrencies:function(){
            var self = this
            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: self.$root.user.authtoken,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getCurrencies'
            }).then(function (response) {
                if(response.data.result){
                    self.currencies = response.data.data
                }else{
                    self.$notify({
                      group: 'notif',
                      type:'danger',
                      text: response.data.msg
                    });
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);

                self.$notify({
                  group: 'notif',
                  type:'danger',
                  text: error
                });
            });
        },
        nextStep: function(){
            var self = this
            self.datepickerError = false
            if(!self.$parent.claim.date){self.datepickerError = true}
            self.$validator.validateAll('claims-form').then((result) => {
                if (result && !self.datepickerError){
                    this.$parent.currentStep = 4;
                    router.replace({ path: "/claims/upload"})
                }
            });
        },
        prevStep:function(){
             this.$parent.currentStep = 2;
            router.replace({ path: "/claims/type"})
        },
        setDate: function(){
          this.$parent.claim.date = moment(this.selectedDate,'YYYY-MM-DD').format('YYYY-MM-DD')
        }
    },
    beforeMount() {
        this.getCurrencies()

        if(this.$parent.currentStep != 3){
            this.$parent.currentStep = 1
            router.replace({ path: "/claims/policies"});
        }

        this.dp_lang = {
            night: this.$root.labels.day_label,
            nights: this.$root.labels.days_label,
            'day-names': [
                this.$root.labels.short_sunday_label, this.$root.labels.short_monday_label, this.$root.labels.short_tuesday_label, this.$root.labels.short_wednesday_label, this.$root.labels.short_thursday_label, this.$root.labels.short_friday_label, this.$root.labels.short_saturday_label
            ],
            'check-in': this.$root.labels.date_label,
            'check-out': this.$root.labels.end_date_label,
            'month-names': [
                this.$root.labels.january_label, this.$root.labels.february_label, this.$root.labels.march_label, this.$root.labels.april_label, this.$root.labels.may_label, this.$root.labels.june_label, this.$root.labels.july_label, this.$root.labels.august_label, this.$root.labels.september_label, this.$root.labels.october_label, this.$root.labels.november_label, this.$root.labels.december_label
            ],
            'select': this.$root.labels.select_label,
            'clear': this.$root.labels.clear_label,
            'today': this.$root.labels.today_label
        }
    },
  mounted() {
    this.$parent.claim.date = new Date(this.$parent.claim.end_date).getTime() >= new Date().getTime() ? new Date() : this.$parent.claim.end_date
    this.setDate(this.$parent.claim.date)
  }
}
</script>

<style scoped>
    ul{
        list-style:none;
    }

    .cb-container{
        line-height:32px;
    }

    fieldset{
        background: rgba(58,58,58,0.05);
        border-radius:4px;
        padding:10px;
        border:none;
    }

    fieldset.no-border{
        background:none;
    }
</style>

<template>
  <div id="app">
      <notifications group="notif" position="top center"><Notification></Notification></notifications>
      <v-dialog/>


    <modal name="spss-popup" class="vue-dialog" :clickToClose="false" :height="'auto'" :scrollable="true" :adaptive="true" :styles="{margin:'50px 0', padding: '30px'}">
      <div class="dialog-content" v-if="$root.labels">
        <div class="row">
          <div class="col-12 text-center">
            <img src="~@/assets/images/swisscare-logo.svg" width="200px" class="dialog-logo" alt="Swisscare">
          </div>
          <div class="col-12">
            <h2 class="mb-4" style="font-size: 1.75em;">{{'brokerage_title_label'|translate}}</h2>
            <div v-html="$root.user.brokerage_header_label" class="mb-2"></div>
            <div class="scrollable mb-2" style="background-color: #EFEFEF; border:1px solid #CCC; padding:20px;height:300px;overflow: scroll; scroll-behavior: auto" v-html="$root.labels['brokerage_body_label']"></div>
          </div>
        </div>
        <div class="center-text">
        </div>
      </div>
      <div class="vue-dialog-buttons text-center py-2 px-4 flex-column" style="border-top: 0;">
        <a class="btn mx-auto" target="_blank" v-html="$root.labels['brokerage_button_label']" v-on:click="sendTimeStamp()"></a>
        <div v-html="$root.labels['brokerage_footer_label']" style="font-size: 0.7em;" class="pt-4 pb-3"></div>
      </div>
    </modal>


      <router-view v-if="$root.appIsLoaded"/>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossorigin="anonymous">
  </div>
</template>




<script>
import Notification from '@/components/modules/notification.vue'

import router from './router'
var qs = require('qs');

export default {
    name: 'app',
    components: {
        Notification,
    },

  methods:{
    sendTimeStamp() {
      const self = this;

      self.axios({
        data: qs.stringify({
          language: 'en',
          timezone: '+2',
          device: 'desktop',
          version: '1.0',

          authtoken: this.$root.user.authtoken,
        }),
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: this.$root.apiUrl+'acceptBrokerage'
      }).then(function () {
        localStorage.need_brokerage = 0
        self.$modal.hide('spss-popup')
      }).catch(function () {
      });

    },

    getProfile :function(){
      var self = this

      self.axios({
        data: qs.stringify({
          language: 'en',
          timezone: '+2',
          device: 'desktop',
          version: '1.0',

          authtoken: self.$root.user.authtoken,
        }),
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: self.$root.apiUrl+'getProfile'
      }).then(function (response) {
        if(response.data.result){
          self.$root.user = response.data.data
        }else{
          self.checkToken(response.data.code);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
  },
    beforeMount() {
        import('@/assets/css/main.less').then(m => {
            //this.$root.stack.form_settings.formulaIsLoaded = true
        });

        var self = this
        self.$validator.validate().then(result => {
            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: this.$root.apiUrl+'getLabels'
            }).then(function (response) {
                console.log(response);
                if(response.data.result){
                    self.$root.appIsLoaded = true
                    self.$root.labels = response.data.data
                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        });


        this.$root.user.need_brokerage = localStorage.getItem('need_brokerage')

        if(!this.$root.user.authtoken){
            if(localStorage.getItem('authtoken')){
                this.$root.user.authtoken = localStorage.getItem('authtoken')
            }
            /*else{
                router.push({ path: "/" });
            }*/
            //localStorage.clear();
            //router.push({ path: "/" });

        }
    },
  updated() {
    const self = this;

    self.$root.user.need_brokerage = localStorage.getItem('need_brokerage')

      setTimeout(function(){
        if( localStorage.getItem('need_brokerage') === '1'){
          self.$modal.show('spss-popup');
        }
      },2000)
  },

  mounted() {
      if(this.$root.user.authtoken){
          this.getProfile();
      }
  }
}
</script>

<style>

</style>

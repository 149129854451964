<template lang="pug">
  button(@click.prevent.stop='rangeSelected()') {{ label }}
</template>

<script>
import moment from 'moment'

export default {
  name: 'QuickRangeSelectorButton',
  props: {
    label: {
      type: String,
      required: true
    },
    rangeOfDays: {
      type: Number,
      required: true
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    }
  },
  methods: {
    rangeSelected() {
      let startDate = this.startDate
      let nextDisabledDate = Infinity

      if (startDate === null) {
        startDate = new Date()
        this.$emit('day-clicked', { date: startDate, nextDisabledDate })
      } else if (startDate !== null && this.endDate !== null) {
        this.$emit('day-clicked', { date: startDate, nextDisabledDate })
      }
      this.$emit('day-clicked', { date: this.getEndDate(startDate, this.rangeOfDays - 1), nextDisabledDate, rangeSelected: true })
    },
    getEndDate(startDate, offset) {
      let endDate = moment(startDate).add(offset, 'days').format('YYYY-MM-DD')
      return new Date(endDate)
    }
  }
}
</script>

<style scoped>
button {
  font-family: Source Sans Pro,sans-serif;
  font-size: 13px;
  border: 1px solid #00ca9d;
  color: #424b53;
  margin-left: 8px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  height: 40px;
  background: white;
  z-index: 99;
}

button:hover, button:active, button:focus {
  background: rgba(0,202,157,.5);
  color: white;
  border-color: rgba(0,202,157,.5);
  outline: none;
}
</style>


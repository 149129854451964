<template>
    <div id="popright" v-if="$parent.showPayNow">
        <div class="close" v-on:click="$parent.showPayNow = false"><i class="icon-cross"></i></div>
        <div class="scrolling-inner">
            <div class="inner px-3 py-5 px-md-5">
                <div class="row px-3 px-md-5">
                    <div class="col-12">
                        <fieldset class="no-border amount-field">
                            <label>{{'amount_label'|translate}}</label>
                            <span>{{$parent.selectedPayment.remaining_premium}} {{$parent.selectedPayment.currency}}</span>
                        </fieldset>
                    </div>
                </div>
                <div class="row justify-content-center mt-5">
                    <div class="col-10">
                        <h4>Select payment method</h4>
                    </div>
                    <div class="col-5" v-if="$parent.methods.includes('cc')">
                        <div class="tab text-uppercase mb-2 payment-type" v-on:click="changeTab('cc')" role="button" :class="{'active': currentTab == 'cc' }">
                            <img src="~@/assets/images/paymethods/credit-card-add.svg" class="my-2">
                            <label class="d-block">{{'credit_card_label'|translate}}</label>
                            <div class="comission-text">{{$parent.selectedPayment.commission}}&nbsp;</div>
                        </div>
                    </div>
                    <div class="col-5" v-if="$parent.methods.includes('recurring')">
                        <div class="tab text-uppercase mb-2 payment-type" v-on:click="changeTab('recurring')" role="button" :class="{'active': currentTab == 'recurring' }">
                            <img src="~@/assets/images/paymethods/credit-card.svg" class="my-2">
                            <label class="d-block">{{'saved_credit_card_label'|translate}}</label>
                            <div class="comission-text" v-if="$parent.card">{{$parent.card.bin}}******{{$parent.card.last4}}</div>
                            <div class="comission-text" v-if="!$parent.card">&nbsp;</div>
                        </div>
                    </div>
                    <div class="col-5" v-if="$parent.methods.includes('v11') || $parent.methods.includes('bt')">
                        <div class="tab text-uppercase mb-2 payment-type" v-on:click="changeTab('v11')" role="button" :class="{'active': currentTab == 'v11' }">
                            <img v-if="$parent.methods.includes('bt')" src="~@/assets/images/paymethods/bank-transfer.svg" class="my-2">
                            <img v-if="$parent.methods.includes('v11')" src="~@/assets/images/paymethods/v11-bvr.svg" class="my-2">
                            <label class="d-block">{{'bank_transfer_label'|translate}}</label>
                            <div class="comission-text">&nbsp;</div>
                        </div>
                    </div>
                </div>
                <div class="px-3 px-md-5 mt-5" v-show="currentTab == 'cc'">
                    <form id="payment-form" data-vv-scope="paymentForm">
                        <div class="row">
                            <div class="col-12">
                                <Stripe v-if="$parent.provider == 'stripe'"
                                    v-bind:on-payment-success="onPaymentSuccess"
                                    v-bind:on-payment-failure="onPaymentFailure"
                                    v-bind:create-confirmation-payload="createStripeConfirmationPayload"
                                    v-bind:create-validation-payload="createStripeValidationPayload"
                                        :formCode="this.$props.formCode"
                                />
                                <Braintree v-if="$parent.provider == 'braintree'"
                                        v-bind:amount=$parent.selectedPayment.remaining_premium
                                        v-bind:premium=$parent.selectedPayment.remaining_premium
                                        v-bind:on-payment-success="onPaymentSuccess"
                                        v-bind:on-payment-failure="onPaymentFailureBraintree"
                                        v-bind:create-client-generation-payload="createBraintreeClientTokenGenerationPayload"
                                        v-bind:create-checkout-additional-payload="createBraintreeCheckoutAdditionalPayload"
                                />
                                <span class="field-error" v-show="paymentError"><i class="fas fa-exclamation-triangle"> </i> {{paymentError}}</span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-auto">
                                <button type="button" class="btn smaller px-4" id="paySubmit"><i v-show="paySent" class="fas fa-circle-notch fa-spin"></i><em v-show="!paySent">{{'pay_label'|translate}}</em></button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="px-3 px-md-5" v-show="currentTab == 'recurring'">
                    <form id="send-application-form" data-vv-scope="send-application-form">
                        <div class="row mt-5">
                            <div class="col-auto">
                                <button type="button" class="btn smaller px-4" v-on:click="submitApplication('recurring')">{{'retry_label'|translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="px-3 px-md-5" v-show="currentTab == 'v11'">
                    <form id="send-application-form" data-vv-scope="send-application-form">
                        <div class="row mt-5">
                            <div class="col-12 bank-transfer-info" v-html="$parent.instructions"/>
                            <div class="col-auto" v-show="false">
                                <button type="button" class="btn smaller px-4" v-on:click="submitApplication('v11')" v-if="$parent.methods.includes('v11')">{{'email_invoice_label'|translate}}</button>
                                <button type="button" class="btn smaller px-4" v-on:click="submitApplication('bt')" v-if="$parent.methods.includes('bt')">{{'email_invoice_label'|translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var qs = require('qs');
import Braintree from '../braintree/Braintree.vue'
import Stripe from '../stripe/Stripe.vue'

export default {
    props:['profile','formCode'],
    components:{
        Stripe,
        Braintree
    },
    data(){
        return {
            currentTab : 'cc',
            formStatus : true,
            paymentError: null,
            token: null,
            token_3d: null,
            return_url: null,
            paySent: false
        }
    },
    methods:{
        changeTab: function(tab){
            this.currentTab = tab
        },

        sendPayment(){
            var self = this
            if(this.token && !this.paymentError){
                this.$validator.validateAll('paymentForm').then((result) => {
                    if (result){
                        self.axios({
                            data: qs.stringify({
                                language: 'en',
                                timezone: '+2',
                                device: 'desktop',
                                version: '1.0',

                                authtoken: self.$root.user.authtoken,

                                id: self.$parent.selectedPayment.id,
                                token: self.token,
                                token_3d: self.token_3d,
                                return_url: self.return_url,
                                payment: 'cc'
                            }),
                            method: 'post',
                            headers: { 'content-type': 'application/x-www-form-urlencoded' },
                            url: self.$root.apiUrl+'payPolicyPaymentById'
                        }).then(function (response) {
                          console.log(response);
                            if(response.data.result){
                                self.$notify({
                                  group: 'notif',
                                  type:'success',
                                  text: response.data.msg
                                });

                                self.$parent.showPayNow = false
                                self.$parent.$parent.getPolicyByHash()

                                self.$forceUpdate();

                            }else{
                                self.$notify({
                                  group: 'notif',
                                  type:'danger',
                                  text: response.data.msg
                                });

                                self.checkToken(response.data.code);
                            }
                        }).catch(function (error) {
                            console.log(error);

                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: error
                            });
                        });
                    }
                });
            }
        },

        payWithCard: function(){
            var self = this
            this.paymentError = null

            if(self.$parent.provider == 'braintree'){
                if(!this.token){this.paymentError = this.$root.labels.this_field_required_label}
                this.$validator.validate().then(result => {
                    if(result){
                        self.paymentError = null
                        self.$emit('tokenize');
                    }
                })
            }else{
                createToken().then(data => {
                    if(data.error){this.paymentError = data.error.message}
                    if(data.token.id){
                        this.token = data.token.id

                        this.sendPayment()
                    }
                });
            }

        },
        pay: function(){
        },

        submitApplication: function(method){

            var self = this
            self.$validator.validateAll('send-application-form').then((result) => {
                if (result){
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            authtoken: self.$root.user.authtoken,

                            id: self.$parent.selectedPayment.id,
                            token: null,
                            payment: method,
                            token_3d: self.token_3d,
                            return_url: self.return_url,
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'payPolicyPaymentById'
                    }).then(function (response) {

                      console.log('ERROR')
                        if(response.data.result){
                            self.$notify({
                              group: 'notif',
                              type:'success',
                              text: response.data.msg
                            });
                        }else{
                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: response.data.msg
                            });
                            self.checkToken(response.data.code);
                        }
                    }).catch(function (error) {
                        console.log(error);

                        self.$notify({
                          group: 'notif',
                          type:'danger',
                          text: error
                        });
                    });
                }
            });
        },
        createStripeConfirmationPayload(payment_method_id) {

          return {

            language: 'en',
            timezone: '+2',
            device: 'desktop',
            version: '1.0',
            authtoken: this.$root.user.authtoken,

            id: this.$parent.selectedPayment.id,
            token: payment_method_id,
            token_3d: payment_method_id,
            return_url: this.return_url,
            payment: 'cc'
          }
        },

        createStripeValidationPayload(payment_intent_id) {

          return {

            language: 'en',
            timezone: '+2',
            device: 'desktop',
            version: '1.0',
            authtoken: this.$root.user.authtoken,

            id: this.$parent.selectedPayment.id,
            confirm: payment_intent_id,
            return_url: this.return_url,
            payment: 'cc'
          }
        },
        createBraintreeClientTokenGenerationPayload() {
          return {
            language: 'en',
            timezone: '+2',
            device: 'desktop',
            version: '1.0',
            authtoken: this.$root.user.authtoken,

            //id: this.$parent.selectedPayment.id,
            id: this.$parent.policy.id,
            token: null,
            token_3d: null,
            return_url: this.return_url,
            payment: 'cc'
          }
        },
        createBraintreeCheckoutAdditionalPayload(verifyResult) {
          return {
            nonce: verifyResult.nonce,
            language: 'en',
            timezone: '+2',
            device: 'desktop',
            version: '1.0',
            authtoken: this.$root.user.authtoken,

            id: this.$parent.selectedPayment.id,
            token: null,
            token_3d: verifyResult.nonce,
            confirm: verifyResult.nonce,
            return_url: this.return_url,
            payment: 'cc'
          }
        },
        onPaymentSuccess(backendResponse) {
            this.$notify({
              group: 'notif',
              type:'success',
              text: backendResponse.data.msg
            });

            this.$parent.showPayNow = false
            this.$parent.$parent.getPolicyByHash()
            this.paySent = false;

            this.$forceUpdate();
            this.$parent.getPolicyPayments();
        },
        onPaymentFailure(error, details) {
            if(details.code){
                this.$notify({
                  group: 'notif',
                  type:'danger',
                  text: details.message
                });
            }else{
                this.$notify({
                  group: 'notif',
                  type:'danger',
                  text: details
                });
            }
            this.paySent = false;
        },
        onPaymentFailureBraintree(error, details) {
            if(details){
                this.paymentError = details.message
                this.$notify({
                  group: 'notif',
                  type:'danger',
                  text: details.message
                });
            }else{
                this.paymentError = error
                this.$notify({
                  group: 'notif',
                  type:'danger',
                  text: error
                });
            }
            this.paySent = false;
        }
    },
    beforeMount(){
        var self = this

        //self.getBtToken();
    }
}
</script>

<style scoped>
    #popright{
        width:90%;
        max-width:700px;
        background:#fff;
        position:fixed;
        right:0;
        top:0;
        bottom:0;
        left:auto;
        z-index:1000;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.13);
        -webkit-transition: 600ms all;
        -moz-transition:     600ms all;
        -ms-transition:      600ms all;
        -o-transition:       600ms all;
    }

    #popright .close{
        width:30px;
        height:30px;
        line-height:30px;
        border-radius:50%;
        background: #7B7698;
        color: #FFFFFF;
        text-align:center;
        font-size:14px;
        position:absolute;
        z-index:1002;
        top: 30px;
        left: -15px;
        opacity:1;
        float:none;
    }

    #popright:before{
        background: rgba(123,118,152,0.30);
        position:fixed;
        left:0;
        width:100vw;
        height:100vh;
        top:0;
        content:'';
        z-index:-1;
    }

    #popright .inner{
        background:#fff;
        z-index:1001;
        position:relative;
        height:100vh;
        width:100%;
        overflow:auto;
    }

    #popright .inner h4{
        font-weight:500;
        font-size: 18px;
        color: #4E206C;
        margin-bottom:30px;
    }

    .payment-type{
        border:1px solid rgba(155, 155, 155,.2);
        border-radius:5px;
        background: #FFFFFF;
        border: 1px solid #ECEBF4;
        box-shadow: 0 5px 20px 0 rgba(78,75,96,0.10);
        font-weight:500;
        font-size: 14px;
        color: #4E4B60;
        line-height: 19px;
        padding:20px;
    }

    .payment-type.active{
        background: #4E206C;
        box-shadow: 0 10px 30px 0 rgba(78,32,108,0.17);
        border: 1px solid #FFFFFF;
        color:#fff;
    }

    .payment-type label{
        font-weight:500;
        font-size: 14px;
        color: #4E4B60;
        line-height: 22px;
        margin-bottom:0;
    }

    em{
        font-style:normal;
        font-weight:normal;
    }

    .comission-text{
        font-size:11px;
        color: #4E206C;
        font-weight:300;
    }

    .payment-type.active img{
        filter:brightness(10);
    }
    .payment-type.active label{
        color:#fff;
    }
    .payment-type.active .comission-text{
        color:#C3A6F3;
    }

    .amount-field{
        text-align:left;
    }

    .amount-field label{
        font-weight:400;
        text-transform:uppercase;
        font-size: 26px;
        color: #4E206C;
        line-height:1em;
    }

    .amount-field span{
        font-weight:600;
        font-size: 52px;
        color: #4E206C;
        line-height:1em;
    }
</style>

<template>
    <div id="policies" v-if="policies">
        <div class="row justify-content-center mb-5">
            <div class="col-auto">
                <a :href="landingPage" target="_blank" class="btn secondary nopadding"><span><i class="icon-plus"></i></span>{{'apply_for_insurance_label'|translate}}</a>
            </div>
        </div>
        <div class="table">
            <div class="row thead mb-1 d-none d-md-flex">
                <div class="th col-md-1"></div>
                <div class="th col-md-3 text-uppercase">{{'policy_label'|translate}}</div>
                <div class="th col-md-2 text-uppercase">{{'insured_label'|translate}}</div>
                <div class="th col-md-2 text-uppercase">{{'start_date_label'|translate}}</div>
                <div class="th col-md-2 text-uppercase">{{'end_date_label'|translate}}</div>
                <div class="th col-md-2"></div>
            </div>
            <div v-for="policy in policies.active" class="row tbody justify-content-end mb-3 mb-md-0 py-3">
                <div class="th col-1" v-on:click="loadPolicy(policy.hash)"><div v-bind:class="'status '+policy.status"></div></div>
                <div class="td col-9 col-md-3" v-on:click="loadPolicy(policy.hash)"><div><span>{{policy.insurance.name}}</span>{{policy.code}}</div></div>
                <div class="td col-2 col-md-2" v-on:click="loadPolicy(policy.hash)">
                    <div class="w-100 text-right">
                        <v-popover trigger="hover" offset="0">
                            <div class="insured">{{Object.keys(policy.insureds).length}}</div>
                            <template slot="popover">
                                <p class="mb-0" v-for="insured in policy.insureds">{{insured.firstname}} {{insured.lastname}}</p>
                            </template>
                        </v-popover>
                    </div>
                </div>
                <div class="td col-3 offset-1 offset-md-0 col-md-2" v-on:click="loadPolicy(policy.hash)"><div>{{policy.start_date}}</div></div>
                <div class="td col-3 col-md-2" v-on:click="loadPolicy(policy.hash)"><div v-show="!['SPSS', 'HMIE'].includes(policy.insurance.code)">{{policy.end_date}}</div></div>
                <div class="td col-5 col-md-2">
                  <div class="text-right w-100">
                      <div v-bind:class="'status-badge '+policy.status" v-show="!policy.has_unpaid_payment && !policy.disabled_finance">{{'paid_label'|translate}}</div>
                      <div v-if="policy.insurance.reoccuring != 2" v-bind:class="'status-badge mx-auto partially-paid'" v-on:click="goToPay(policy.hash)" v-show="policy.has_unpaid_payment && !policy.disabled_finance">{{'pay_now_label'|translate}}</div>
                      <div v-if="policy.insurance.reoccuring == 2" v-bind:class="'status-badge mx-auto w-100 partially-paid'" v-on:click="goToPay(policy.hash)" v-show="policy.has_unpaid_payment && !policy.disabled_finance">{{'change_card_label'|translate}}</div>
                  </div>
                </div>
            </div>
        </div>


        <h3 v-show="policies.inactive.length">{{'inactive_policies_label'|translate}}</h3>

        <div class="table" v-if="policies.inactive">
            <div v-for="policy in policies.inactive" class="row tbody justify-content-end mb-3 mb-md-0 py-3">
                <div class="th col-1" v-on:click="loadPolicy(policy.hash)"><div v-bind:class="'status '+policy.status"></div></div>
                <div class="td col-9 col-md-3" v-on:click="loadPolicy(policy.hash)"><div><span>{{policy.insurance.name}}</span>{{policy.code}}</div></div>
                <div class="td col-2 col-md-2" v-on:click="loadPolicy(policy.hash)">
                    <div class="w-100 text-right">
                        <v-popover trigger="hover" offset="0">
                            <div class="insured">{{Object.keys(policy.insureds).length}}</div>
                            <template slot="popover">
                                <p class="mb-0" v-for="insured in policy.insureds">{{insured.firstname}} {{insured.lastname}}</p>
                            </template>
                        </v-popover>
                    </div>
                </div>
                <div class="td col-3 offset-1 offset-md-0 col-md-2" v-on:click="loadPolicy(policy.hash)"><div>{{policy.start_date}}</div></div>
                <div class="td col-3 col-md-2" v-on:click="loadPolicy(policy.hash)"><div v-show="!['SPSS', 'HMIE'].includes(policy.insurance.code)">{{policy.end_date}}</div></div>
                <div class="td col-5 col-md-2">
                  <div class="text-right w-100">
                    <div v-if="policy.insurance.reoccuring != 2" v-bind:class="'status-badge '+policy.status" v-on:click="goToPay(policy.hash)" v-show="policy.has_unpaid_payment && !policy.disabled_finance">{{'pay_now_label'|translate}}</div>
                    <div v-if="policy.insurance.reoccuring == 2" v-bind:class="'status-badge '+policy.status" v-on:click="goToPay(policy.hash)" v-show="policy.has_unpaid_payment && !policy.disabled_finance">{{'change_card_label'|translate}}</div>
                  </div>
                </div>
            </div>
        </div>

        <h3 v-show="policies.suspended.length">{{'suspended_policies_label'|translate}}</h3>

        <div class="table" v-if="policies.suspended">
            <div v-for="policy in policies.suspended" class="row tbody justify-content-end mb-3 mb-md-0 py-3">
                <div class="th col-1" v-on:click="loadPolicy(policy.hash)"><div v-bind:class="'status '+policy.status"></div></div>
                <div class="td col-9 col-md-3" v-on:click="loadPolicy(policy.hash)"><div><span>{{policy.insurance.name}}</span>{{policy.code}}</div></div>
                <div class="td col-2 col-md-2" v-on:click="loadPolicy(policy.hash)">
                    <div class="w-100 text-right">
                        <v-popover trigger="hover" offset="16">
                            <div class="insured">{{Object.keys(policy.insureds).length}}</div>
                            <template slot="popover">
                                <p class="mb-0" v-for="insured in policy.insureds">{{insured.firstname}} {{insured.lastname}}</p>
                            </template>
                        </v-popover>
                    </div>
                </div>
                <div class="td col-3 offset-1 offset-md-0 col-md-2" v-on:click="loadPolicy(policy.hash)"><div>{{policy.start_date}}</div></div>
                <div class="td col-3 col-md-2" v-on:click="loadPolicy(policy.hash)"><div v-show="!['SPSS', 'HMIE'].includes(policy.insurance.code)">{{policy.end_date}}</div></div>
                <div class="td col-5 col-md-2">
                  <div class="text-right w-100">
                    <div v-if="policy.insurance.reoccuring != 2" v-bind:class="'status-badge '+policy.status" v-show="policy.has_unpaid_payment && !policy.disabled_finance" v-on:click="goToPay(policy.hash)">{{'pay_label'|translate}}</div>
                    <div v-if="policy.insurance.reoccuring == 2" v-bind:class="'status-badge '+policy.status" v-show="policy.has_unpaid_payment && !policy.disabled_finance">{{'change_card_label'|translate}}</div>
                  </div>
                </div>
            </div>
        </div>

        <h3 v-show="policies.expired.length">{{'expired_policies_label'|translate}}</h3>

        <div class="table" v-if="policies.expired">
            <div v-for="policy in policies.expired" class="row tbody justify-content-end mb-3 mb-md-0 py-3" v-on:click="loadPolicy(policy.hash)">
                <div class="th col-1"><div v-bind:class="'status '+policy.status"></div></div>
                <div class="td col-9 col-md-3"><div><span>{{policy.insurance.name}}</span>{{policy.code}}</div></div>
                <div class="td col-2 col-md-2">
                    <div class="w-100 text-right">
                        <v-popover trigger="hover" offset="16">
                            <div class="insured">{{Object.keys(policy.insureds).length}}</div>
                            <template slot="popover">
                                <p class="mb-0" v-for="insured in policy.insureds">{{insured.firstname}} {{insured.lastname}}</p>
                            </template>
                        </v-popover>
                    </div>
                </div>
                <div class="td col-3 offset-1 offset-md-0 col-md-2"><div>{{policy.start_date}}</div></div>
                <div class="td col-3 col-md-2"><div v-show="!['SPSS', 'HMIE'].includes(policy.insurance.code)">{{policy.end_date}}</div></div>
                <div class="td col-5 col-md-2">
                  <div class="text-right w-100">
                      <div v-bind:class="'status-badge '+policy.status">{{'paid_label'|translate}}</div>
                  </div>
                </div>
            </div>
            <div class="row tfoot justify-content-end pt-3" v-if="policies.expired.length > 10">
                <div class="col-auto"><a href="#">{{'view_all_label'|translate}}</a></div>
            </div>
        </div>


        <h3 v-show="policies.cancelled.length">{{'cancelled_policies_label'|translate}}</h3>

        <div class="table" v-if="policies.cancelled">
            <div v-for="policy in policies.cancelled" class="row tbody justify-content-end mb-3 mb-md-0 py-3" v-on:click="loadPolicy(policy.hash)">
                <div class="th col-1"><div v-bind:class="'status '+policy.status"></div></div>
                <div class="td col-9 col-md-3"><div><span>{{policy.insurance.name}}</span>{{policy.code}}</div></div>
                <div class="td col-2 col-md-2">
                    <div class="w-100 text-right">
                        <v-popover trigger="hover" offset="16">
                            <div class="insured">{{Object.keys(policy.insureds).length}}</div>
                            <template slot="popover">
                                <p class="mb-0" v-for="insured in policy.insureds">{{insured.firstname}} {{insured.lastname}}</p>
                            </template>
                        </v-popover>
                    </div>
                </div>
                <div class="td col-3 offset-1 offset-md-0 col-md-2"><div>{{policy.start_date}}</div></div>
                <div class="td col-3 col-md-2"><div v-show="!['SPSS', 'HMIE'].includes(policy.insurance.code)">{{policy.end_date}}</div></div>
                <div class="td col-5 col-md-2"><div v-bind:class="'status-badge '+policy.status">{{'cancelled_label'|translate}}</div></div>
            </div>
        </div>

        <div class="row mt-5 pt-5 mb-5">
            <div class="col-12 col-md-6">
                <img src="~@/assets/images/mockup.svg" alt="Swisscare" class="w-100">
            </div>
            <div class="col-12 col-md-6 justify-content-center pt-5">
                <div class="row pt-5">
                    <div class="col-12 text-center mb-5">
                        <img src="~@/assets/images/swisscare-logo.svg" alt="Swisscare" class="w-50">
                        <span class="small text-center d-block mt-4">{{'dont_forget_to_download_our_app_label'|translate}}</span>
                    </div>
                    <div class="col-12 text-center">
                        <div class="row justify-content-center">
                            <div class="col-auto mx-2">
                                <a v-bind:href="$root.urls.android" target="_blank"><img src="~@/assets/images/google-play.svg" alt="Google Play"></a>
                            </div>
                            <div class="col-auto mx-2">
                                <a v-bind:href="$root.urls.ios" target="_blank"><img src="~@/assets/images/store.svg" alt="App Store"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

</script>


<script>
import router from '../router'

var qs = require('qs');

export default {
    name: 'policies',
    data() {
        return {
            policies:{

            }
        }
    },
    components: {

    },
    methods:{
        loadPolicy: function(policy_hash){
            router.push("policies/"+policy_hash)
        },
        goToPay: function(policy_hash){
            router.push("policies/"+policy_hash+'/finance#pay')
        },
        getPolicies: function(){
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: this.$root.user.authtoken,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getPolicies'
            }).then(function (response) {
                //console.log(response)
                if(response.data.result){
                    self.policies = response.data.data
                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    },
    beforeMount() {
        this.getPolicies()
    }
}
</script>

<style>
    .small{
        font-weight:200;
        font-size: 14px;
        color: #7B7698;
        text-align: center;
        text-transform:uppercase;
    }

    .tbody{
        cursor:pointer;
    }
</style>

<template>
    <div id="claims">
        <div class="row justify-content-center justify-content-md-start">
            <div class="auto"><h1 class="text-capitalize text-left text-sm-left mt-4">{{'submit_a_claim_label'|translate}}</h1></div>
        </div>
        <div class="row steps-bar mb-3 mb-md-5 mt-4">
            <div :class="{ done: currentStep > 1, active: currentStep == 1 }" class="step col-md-2 text-center px-0 first">
                <div class="col mb-3">
                    <div class="number"><span></span>1</div>
                </div>
                <p class="d-none d-md-block">{{'choose_policy_label'|translate}}</p>
            </div>
            <div :class="{ done: currentStep > 2, active: currentStep == 2  }" class="step col-md-2 text-center px-0">
                <div class="col mb-3">
                    <div class="number"><span></span>2</div>
                </div>
                <p class="d-none d-md-block">{{'select_claim_type_label'|translate}}</p>
            </div>
            <div :class="{ done: currentStep > 3, active: currentStep == 3 }" class="step col-md-2 text-center px-0">
                <div class="col mb-3">
                    <div class="number"><span></span>3</div>
                </div>
                <p class="d-none d-md-block">{{'claim_details_label'|translate}}</p>
            </div>
            <div :class="{ done: currentStep > 4, active: currentStep == 4 }" class="step col-md-2 text-center px-0">
                <div class="col mb-3">
                    <div class="number"><span></span>4</div>
                </div>
                <p class="d-none d-md-block">{{'upload_files_label'|translate}}</p>
            </div>
            <div :class="{ done: currentStep > 5, active: currentStep == 5 }" class="step col-md-2 text-center px-0">
                <div class="col mb-3">
                    <div class="number"><span></span>5</div>
                </div>
                <p class="d-none d-md-block">{{'bank_details_label'|translate}}</p>
            </div>
            <div :class="{ done: currentStep > 6, active: currentStep == 6 }" class="step col-md-2 text-center px-0 last">
                <div class="col mb-3">
                    <div class="number"><span></span>6</div>
                </div>
                <p class="d-none d-md-block">{{'final_confirmation_label'|translate}}</p>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <router-view/>
            </div>
        </div>

        <div id="loader" v-show="showLoader">
            <div class="loader-svg loader--style3" title="2">
              <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 width="140px" height="140px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
              <path fill="#fff" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                <animateTransform attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.6s"
                  repeatCount="indefinite"/>
                </path>
              </svg>
            </div>
        </div>

    </div>
</template>

<script>
import router from '../router'
var qs = require('qs');

export default {
    name: 'claims',
    components:{
    },
    props:{

    },
    data(){
        return{
            showLoader: false,
            currentStep: 1,
            claim: {
                id: null,
                insurance_code: '',
                type:{
                    id: null,
                    other: null,
                    text: null
                },
                date: null,
                price: {
                    currency: null,
                    amount: null
                },
                holder:{
                    firstname: null,
                    lastname: null,
                    address: null,
                    zip: null,
                    city: null,
                    country: null,
                    iscompany: false,
                    company: null,
                },
                bank:{
                    name: null,
                    address1: null,
                    address2: null,
                    zip: null,
                    city: null,
                    country: null,
                    iban: null,
                    use_iban: false,
                    swift: null,
                    account_number: null
                },
                message: null,
                working: null
            },
            files: {
                report: [],
                proof: [],
                other: [],
            },

            policies: null,
            types: null,
        }
    },
    methods:{
        submitClaims(){
            var self = this
            let formData = new FormData();
            let j = 0;

            for( var i = 0; i < this.files.report.length; i++ ){
                let file = this.files.report[i];
                formData.append('files[' + j + ']', file);
                j++;
            }
            for( var i = 0; i < this.files.proof.length; i++ ){
                let file = this.files.proof[i];
                formData.append('files[' + j + ']', file);
                j++;
            }
            for( var i = 0; i < this.files.other.length; i++ ){
                let file = this.files.other[i];
                formData.append('files[' + j + ']', file);
                j++;
            }

            formData.append('language','en');
            formData.append('timezone','+2');
            formData.append('device','desktop');
            formData.append('version','1.0');
            formData.append('authtoken',self.$root.user.authtoken);
            formData.append('id',this.claim.id);
            formData.append('type',this.claim.type.text);
            formData.append('date',this.claim.date);
            formData.append('currency',this.claim.price.currency);
            formData.append('amount',this.claim.price.amount);
            formData.append('working',this.claim.working);
            formData.append('holder_iscompany',this.claim.holder.iscompany);
            formData.append('holder_company',this.claim.holder.company);
            formData.append('holder_firstname',this.claim.holder.firstname);
            formData.append('holder_lastname',this.claim.holder.lastname);
            formData.append('holder_address',this.claim.holder.address);
            formData.append('holder_address2',this.claim.holder.address2);
            formData.append('holder_zip',this.claim.holder.zip);
            formData.append('holder_city',this.claim.holder.city);
            formData.append('holder_country',this.claim.holder.country);
            formData.append('bank_name',this.claim.bank.name);
            formData.append('bank_address1',this.claim.bank.address1);
            formData.append('bank_address2',this.claim.bank.address2);
            formData.append('bank_zip',this.claim.bank.zip);
            formData.append('bank_city',this.claim.bank.city);
            formData.append('bank_country',this.claim.bank.country);
            formData.append('use_iban',this.claim.bank.use_iban);
            formData.append('iban',this.claim.bank.iban);
            formData.append('bank_account_number',this.claim.bank.account_number);
            formData.append('swift',this.claim.bank.swift);
            formData.append('comment',this.claim.message);


            self.showLoader = true

            self.axios({
                data: formData,
                method: 'post',
                headers: { 'content-type': 'multipart/form-data' },
                url: self.$root.apiUrl+'saveClaims'
            }).then(function(response){
              if(response.data.result){

                self.$notify({
                  group: 'notif',
                  type:'success',
                  text: response.data.msg
                });

                router.replace({ path: "/thank-you"})

                }else{
                    self.$notify({
                      group: 'notif',
                      type:'danger',
                      text: response.data.msg
                    });
                }
            }).catch(function (error) {
                console.log(error);

                self.$notify({
                  group: 'notif',
                  type:'danger',
                  text: error
                });
            });
        }
    },
    beforeMount() {
    }
}
</script>

<style scoped>
    h1{
        font-size:40px;
    }
    .steps-bar .number{
        background: #FFFFFF;
        font-size: 16px;
        letter-spacing: 0;
        text-align: center;
        width:60px;
        height:60px;
        border-radius:50%;
        text-align:center;
        line-height:60px;
        margin:auto;
        font-weight:500;

        border: 2px solid #DEDEDE;
        color: #BEBEBE;
    }

    .steps-bar .step.done .number{
        border: 2px solid #64DEAB;
        color: #64DEAB;
    }
    .steps-bar .step.active {
        color: #64DEAB;
    }
    .steps-bar .step.active .number{
        border: 2px solid #64DEAB;
        color: #64DEAB;
    }

    .steps-bar .step{
        font-weight:300;
        font-size: 14px;
        color: #7B7698;
        font-weight:500;
    }

    .steps-bar .step.done{
        color: #64DEAB;
    }

    .steps-bar .number span{
        position:absolute;
        width:60px;
        height:60px;
        text-align:center;
        top:70px;
        left:calc(50% - 26px);

        -webkit-transition: all 300ms; /* Safari */
        transition: all 300ms;
    }

    .steps-bar .step.done .number{
        background: #64DEAB;
    }

    .steps-bar .step.done .number span{
        top:0;
    }

    .steps-bar .step.done .number span:before{
        content: "\e906";
        position:relative;
        font-family:'account';
        font-size:16px;
        color:#fff;
        text-align:center;
        line-height:60px;
        margin-left:-4px;
    }

    .steps-bar .col{
        position: relative;
    }
    .steps-bar .number:before{
        position:absolute;
        content:'';
        width:calc(50% - 30px);
        top:calc(50% - 1px);
        left:0;
        height:2px;
        background-color: #DEDEDE;
    }
    .steps-bar .step.done .number:before{
        background-color: #64DEAB;
    }
    .steps-bar .number:after{
        position:absolute;
        content:'';
        width:calc(50% - 30px);
        top:calc(50% - 1px);
        right:0;
        height:2px;
        background-color: #DEDEDE;
    }

    .steps-bar .step.done .number:after{
        background-color: #64DEAB;
    }

    .steps-bar .step.done + .step .number:before{
        background-color: #64DEAB;
    }


    .steps-bar .step.first .number:before{
        display:none;
    }

    .steps-bar .step.last .number:after{
        display:none;
    }

    #loader{
        position:fixed;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background:rgba(123, 118, 152,.3);
        z-index:99;
        text-align:center;
        line-height:100vh;
    }


    .loader-svg{
      margin: 0 0 2em;
      height: 100px;
      width: 20%;
      text-align: center;
      padding: 1em;
      margin: 0 auto 1em;
      display: inline-block;
      vertical-align: top;
    }

</style>

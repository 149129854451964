<template>
    <h2 v-html="countdown">
    </h2>
</template>

<script>
export default {
    data () {
         return {
            countdown: 15,
         }
    },
    mounted(){
        var self = this
        this.countdown = 15

        var countdown = setInterval(function(){
            self.countdown--
            if(self.countdown == 0){
                self.$modal.hide('policy-download');
                self.$modal.hide('invoice-download');
                self.$modal.hide('brokerage-pdf');
                clearInterval(countdown);
            }
        },1000)
    }
}
</script>

<style scoped>
    h2{
        display:inline-block;
        margin:0;
    }
</style>

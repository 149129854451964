<template>
    <div>
      <Popright v-show="showPopright" :policy="policy"></Popright>
      <editPayment v-show="showEditPayment" :policy="policy"></editPayment>
        <div class="row row-eq-height mt-5 mb-0">
            <div class="col-12 col-md-6 col-xl-4 mb-sm-5">
                <h6 class="text-uppercase mb-3 mt-3">{{'policy_label'|translate}}</h6>
                <div class="card card-90 px-3 py-3 px-md-5 py-md-5">
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'policy_id_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.code}}</p></div>
                    </div>
                    <div class="row mb-2" v-if="policy.term">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'insurance_conditions_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.term.gic_number}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'start_date_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.start_date}}</p></div>
                    </div>
                    <div class="row mb-2" v-show="!['SPSS', 'HMIE'].includes(policy.insurance.code)">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'end_date_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.end_date}}</p></div>
                    </div>
                    <div class="row mb-2" v-show="policy.cancel_date">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'cancel_date_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.cancel_date}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'first_destination_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info" v-text="policy.insureds[0].destination_obj.name"></p></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'area_of_cover_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info" v-html="policy.insurance.areacover"></p></div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-sm-5">
                <h6 class="text-uppercase mb-3 mt-3">{{'policy_holder_label'|translate}}</h6>
                <div class="card card-90 px-3 py-3 px-md-5 py-md-5">
                    <div class="row mb-2" v-show="policy.ph_is_company">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'company_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.ph_company}}</p></div>
                    </div>
                    <div class="row mb-2" v-show="!policy.ph_is_company">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'name_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.ph_firstname}} {{policy.ph_lastname}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'address_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.ph_address1}}<br>{{policy.ph_address2}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'zip_code_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.ph_zip}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'city_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.ph_city}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'country_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.ph_country_obj.name}}</p></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'phone_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.ph_phone}}</p></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'plan_level_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.plan.name}}</p></div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4 mb-sm-5">
                <h6 class="text-uppercase mb-3 mt-3">{{'insured_persons_label'|translate}}</h6>
                <div class="card px-3 py-3 px-md-5 py-md-5">
                    <div v-for="insured in policy.insureds" class="row mb-2">
                        <div class="col-12"><p class="text-uppercase mb-0">{{insured.firstname}} {{insured.lastname}}</p></div>
                        <div class="col-12"><p class="text-info">{{insured.gender}} - {{insured.dob}}</p>
                        <hr>
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6 col-xl-4 mb-sm-3">
                <h6 class="text-uppercase mb-3 mt-3" v-on:click="showCorrespondence = !showCorrespondence" role="button">+ {{'correspondence_label'|translate}}</h6>
                <div class="card px-3 py-3 px-md-5 py-md-5" v-show="showCorrespondence">
                    <div class="row mb-2" v-show="policy.cr_is_company">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'company_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.cr_company}}</p></div>
                    </div>

                    <div class="row mb-2" v-show="!policy.cr_is_company">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'name_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.cr_firstname}} {{policy.cr_lastname}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'address_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.cr_address1}}<br>{{policy.cr_address2}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'zip_code_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.cr_zip}}</p></div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'city_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.cr_city}}</p></div>
                    </div>
                    <div class="row mb-2" v-if="policy.cr_country_obj">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase">{{'country_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.cr_country_obj.name}}</p></div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0">{{'phone_label'|translate}}</p></div>
                        <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{policy.cr_phone}}</p></div>
                    </div>
                </div>
            </div>
        </div>

        <hr>
        <div class="row justify-content-between">
            <div class="col-auto"><div class="btn-tab" v-if="policy.insurance.reoccuring && version == '2.0'" v-on:click="showEditPayment = true"><i class="icon-payment"></i>{{'edit_payment_label'|translate}}</div></div>
            <div class="col-auto">
                <div class="row">
                    <div class="col-lg-auto py-2" v-if="policy.brokerage_date !== null && ['SPSS', 'HMIE', 'ISIG'].includes(policy.insurance.code)"><div v-on:click="downloadBrokeragePDF()" class="btn-tab"><i class="icon-icon-file"></i>{{'dowload_brokerage_button'|translate}}</div></div>
                    <div class="col-lg-auto py-2" v-if="policy.insurance.checkbox_file2"><a :href="policy.insurance.checkbox_file2" class="btn-tab" target="_blank"><i class="icon-icon-file"></i>{{'ipid_download_label'|translate}}</a></div>
                    <div class="col-lg-auto py-2" v-if="policy.term"><a :href="policy.term.filename" class="btn-tab" target="_blank"><i class="icon-icon-file"></i>{{'general_terms_label'|translate}}</a></div>
                    <div class="col-lg-auto py-2"><div class="btn-tab" v-on:click="showEdit"><i class="icon-edit"></i>{{'edit_address_label'|translate}}</div></div>
                    <div class="col-lg-auto py-2"><div class="btn-tab" v-on:click="cancelPolicyQuestion" v-if="policy.status == 'active' && version == '2.0'"><i class="icon-cross"></i>{{'cancel_policy_label'|translate}}</div></div>
                </div>
            </div>
        </div>

      <modal name="brokerage-pdf" class="vue-dialog">
        <div class="dialog-content" style="padding:50px;">
          <div class="text-center">
            <div class="row">
              <div class="col-12">
                <img src="~@/assets/images/swisscare-logo.svg" class="dialog-logo mx-auto" alt="Swisscare" width="200px">
              </div>
              <div class="col-12 pt-2">
                {{'you_can_download_the_brokerage_label'|translate}} <counter></counter> {{'seconds_label'|translate}}
              </div>
              <div class="col-12 pt-4">
                <a :href="pdfUrl" class="btn" target="_blank">{{'dowload_brokerage_button'|translate}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="vue-dialog-buttons">
          <button type="button" class="vue-dialog-button" v-on:click="$modal.hide('brokerage-pdf')" style="flex: 1 1 50%;">{{'close_label'|translate}}</button>
        </div>
      </modal>

    </div>
</template>

<script>
import router from '../router'
import Popright from '@/components/modules/popright.vue'
import editPayment from '@/components/modules/editPayment.vue'
import counter from '@/components/modules/counter.vue'
var qs = require('qs');

export default {
    name: 'policy_details',
    props:['policy'],
    data(){
        return {
            showPopright : false,
            showCorrespondence: false,
            showEditPayment: false,
            pdfUrl: null
        }
    },
    components:{
        Popright,
        editPayment,
        counter
    },
    methods:{
        showEdit:function(){
            this.showPopright = true
        },
        cancelPolicyQuestion:function(){
            this.showModal(this.$root.labels['cancel_policy_label'], this.$root.labels['policy_cancel_modal_text'],[{title: this.$root.labels['close_label']},{title: this.$root.labels['yes_label'],handler: () => {this.cancelPolicy()}}])
        },
        cancelPolicy:function(){
              var self = this

                self.axios({
                    data: qs.stringify({
                        language: 'en',
                        timezone: '+2',
                        device: 'desktop',
                        version: '1.0',

                        authtoken: this.$root.user.authtoken,
                        id:this.policy.id

                    }),
                    method: 'post',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    url: self.$root.apiUrl+'cancelPolicyById'
                }).then(function (response) {
                    if(response.data.result){
                        self.$modal.hide('dialog')

                        self.$notify({
                          group: 'notif',
                          type:'success',
                          text: self.$root.labels['succesfull_policy_cancel']
                        });

                        router.push("/policies")
                    }else{
                        self.checkToken(response.data.code);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
        },
      downloadBrokeragePDF: function(){
        var self = this

        self.axios({
          data: qs.stringify({
            language: 'en',
            timezone: '+2',
            device: 'desktop',
            version: '1.0',

            authtoken: this.$root.user.authtoken,
            id: self.policy.id,
          }),
          method: 'post',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: self.$root.apiUrl+'downloadBrokeragePDF'
        }).then(function (response) {
          if(response.data.result){
            self.pdfUrl = response.data.data;
            self.$modal.show('brokerage-pdf', {
            });
            self.enableDownload = true;
          }else{
            self.checkToken(response.data.code);
            self.showModal('', response.data.msg,[{title: self.$root.labels['close_label']}])
          }
        }).catch(function (error) {
          //console.log(error);
        });
      }
    },
    mounted() {
        this.$parent.pageTitle = 'Policy'
    },
}
</script>

<style scoped>

    .card-90{
      height:90%;
    }

    hr{
        height:1px;
        border:none;
        background:#E9E8F3;
    }
</style>

import {getNewClientToken, startPaymentOnServer, startChangeCardOnServer} from "./backend"
import {brainTreeFields, brainTreeStyle} from "./config";


export const preparePayment = (braintree, createClientGenerationPayload) => new Promise((resolve, reject) => {
    getNewClientToken(createClientGenerationPayload()).then(async response => {
        if (clientTokenMissingFrom(response)) {
            reject({
                error: 'get new client_token server call error',
                details: 'no client_token in response'
            });
            return;
        }
        
        try {
            const hostedFields = await createHostedFields(response.data.data, braintree)
            const threeDSecure = await createThreeDSecure(response.data.data, braintree)
            resolve({
                hostedFields: hostedFields,
                threeDSecure: threeDSecure
            })

        } catch (error) {
            reject({
                error: 'braintree components generation error',
                details: error
            });
        }
    }).catch(error => reject({
        error: 'get new client_token server call error',
        details: error
    }))
});


export const onSubmitButton = (amount, event, hostedFields, threeDSecure, doneCallback, errorCallback, createCheckoutAdditionalPayload) => {
    hostedFields.tokenize().then(payload => threeDSecure.verifyCard({
        onLookupComplete: function (data, next) {
            next();
        },
        amount: amount,
        nonce: payload.nonce,
        bin: payload.details.bin,
    })).then(verifyResult => {
        if (verifyResult.threeDSecureInfo.status === 'authenticate_successful') {
            sendPaymentToServer(verifyResult, doneCallback, errorCallback, createCheckoutAdditionalPayload)
        } else {
            errorCallback('3DS verification error', 'failed authentication')
        }
    }).catch(error => errorCallback('tokenize or verify start error', error))
}

export const onChangeButton = (amount, event, hostedFields, threeDSecure, doneCallback, errorCallback, createCheckoutAdditionalPayload) => {
    hostedFields.tokenize().then(payload => threeDSecure.verifyCard({
        onLookupComplete: function (data, next) {
            next();
        },
        amount: 1,
        nonce: payload.nonce,
        bin: payload.details.bin,
    })).then(verifyResult => {
        if (verifyResult.threeDSecureInfo.status === 'authenticate_successful') {
            sendChangeCardToServer(verifyResult, doneCallback, errorCallback, createCheckoutAdditionalPayload)
        } else {
            errorCallback('3DS verification error', 'failed authentication')
        }
    }).catch(error => errorCallback('tokenize or verify start error', error))
}

const sendPaymentToServer = (verifyResult, doneCallback, errorCallback, createCheckoutAdditionalPayload) => {
    startPaymentOnServer(createCheckoutAdditionalPayload(verifyResult)).then(response => {
        doneCallback(response)
    }).catch(error => {
        errorCallback('start payment on server with nonce error', error)
    })
}

const sendChangeCardToServer = (verifyResult, doneCallback, errorCallback, createCheckoutAdditionalPayload) => {
    startChangeCardOnServer(createCheckoutAdditionalPayload(verifyResult)).then(response => {
        doneCallback(response)
    }).catch(error => {
        errorCallback('start payment on server with nonce error', error)
    })
}

const createHostedFields = (clientToken, braintree) => braintree.hostedFields.create({
    authorization: clientToken,
    styles: brainTreeStyle,
    fields: brainTreeFields
})

const createThreeDSecure = (clientToken, braintree) => braintree.threeDSecure.create({
    authorization: clientToken,
    version: 2
})

const clientTokenMissingFrom = response => !response.data || !response.data.data

import  backendService  from "./backend"
import {paymentConfirmationEndPoint, payment3DConfirmationEndPoint} from "./config";

var qs = require('qs');

export const initiatePayment = (stripe, cardElement, cardholderName, createConfirmationPayload, createValidationPayload) => {

    return new Promise((resolve, reject) => {
        stripe.createPaymentMethod('card', cardElement, {
            billing_details: {name: cardholderName.value}
        }).then(response => {
            confirmPayment( stripe, createConfirmationPayload, createValidationPayload, response.paymentMethod, (doneMessage) => {
                resolve(doneMessage)
            }, (type, error) => {
                reject({type: type, error: error})
            })
        }).catch(error => {
            reject({type: 'initiatePayment error', error: error})
        })
    })
}

const confirmPayment = (stripe, createConfirmationPayload, createValidationPayload, paymentMethod, doneCallback, errorCallback) => {
    backendService.post('payPolicyPaymentById', qs.stringify(createConfirmationPayload(paymentMethod.id))).then(response => {
        if (response.data.action_required === true && response.data.intent.client_secret) {
            handleCardAction( stripe, createValidationPayload, response.data.intent.client_secret, doneCallback, errorCallback)
        } else if (response.data.result === true) {
            doneCallback(response);
        } else {
            errorCallback('payment error', response.data.msg);
        }
    }).catch(error => {
        errorCallback('payment error', error);
    })

}

const handleCardAction = (stripe, createValidationPayload, payment_intent_client_secret, doneCallback, errorCallback) => {
    stripe.handleCardAction(payment_intent_client_secret).then(response => {
        if (response.paymentIntent) {
            confirmValidatedPayment(createValidationPayload, response.paymentIntent.id, doneCallback, errorCallback)
        } else if (response.error) {
            errorCallback('handleCardAction error', response.error);
        }
    }).catch(error => {
        errorCallback('handleCardAction error', error);
    })
}

const confirmValidatedPayment = (createValidationPayload, payment_intent_id, doneCallback, errorCallback) => {
    backendService.post('confirmPolicyPaymentById', qs.stringify(createValidationPayload(payment_intent_id))).then(response => {
        if (response.data.result === true) {
            doneCallback(response)
        } else {
            errorCallback('confirmValidatedPayment error', response.data.msg);
        }
    }).catch(error => {
        errorCallback('confirmValidatedPayment error', error);
    })
}

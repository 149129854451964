<template>
  <div class="boxed">
      <div id="hosted-fields">
      <div class="row">
        <div class="col-12 mt-3 line">
            <fieldset>
                <label for="hf-number">{{'card_number_label'|translate}}</label>
                <div id="hf-number" class="form-control"></div>
            </fieldset>
          </div>
            <div class="col-6 mt-3 line">
                <fieldset>
                    <label for="hf-date">{{'expiry_label'|translate}}</label>
                    <div id="hf-date" class="form-control"></div>
                </fieldset>
            </div>
            <div class="col-6 mt-3 line">
                <fieldset>
                    <label for="hf-date">{{'cvv_label'|translate}}</label>
                    <div id="hf-cvv" class="form-control"></div>
                </fieldset>
            </div>
      </div>
    </div>
  </div>
</template>

<script>

  import {preparePayment, onSubmitButton, onChangeButton} from "./BraintreeService";

export default {
  name: 'Braintree',
  props: {
      amount: String,
      premium: String,
      onPaymentSuccess: Function,
      onPaymentFailure: Function,
      createClientGenerationPayload: Function,
      createCheckoutAdditionalPayload: Function,
  },
  mounted() {
    preparePayment(window.braintree, this.createClientGenerationPayload).then(paymentComponents => {

        if(document.getElementById("paySubmit")) {
            document.querySelector('#paySubmit').addEventListener('click', event => {

                var self = this
                if (!self.$parent.paySent) {
                    self.$parent.paySent = true;

                    self.$parent.$validator.validate().then(result => {
                        if (result) {
                            self.$parent.paySent = true
                            onSubmitButton(
                                self.premium,
                                event,
                                paymentComponents.hostedFields,
                                paymentComponents.threeDSecure,
                                backendResponse => {
                                    this.onPaymentSuccess(backendResponse)
                                },
                                (error, details) => {
                                    this.onPaymentFailure(error, details)
                                },
                                this.createCheckoutAdditionalPayload
                            )
                        }
                    });


                }
            });
        }
        if(document.getElementById("changeSubmit")) {
            document.querySelector('#changeSubmit').addEventListener('click', event => {

                var self = this
                if (!self.$parent.paySent) {
                    self.$parent.paySent = true;

                    self.$parent.$validator.validate().then(result => {
                        if (result) {
                            self.$parent.paySent = true
                            onChangeButton(
                                self.premium,
                                event,
                                paymentComponents.hostedFields,
                                paymentComponents.threeDSecure,
                                backendResponse => {
                                    this.onPaymentSuccess(backendResponse)
                                },
                                (error, details) => {
                                    this.onPaymentFailure(error, details)
                                },
                                this.createCheckoutAdditionalPayload
                            )
                        }
                    });


                }
            });
        }

    }).catch(error => {
        this.onPaymentFailure(error.error, error.details)
    })

  }
}
</script>

<style scoped>
    .form-control{
        background:none;
        border:none;
        padding:0;
        margin:0;
        height:35px;
    }
</style>

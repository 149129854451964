<template>
    <div id="thanks">
        <div class="row justify-content-center mt-5">
            <div class="col-12 col-md-8 col-lg-4 text-center mt-5">
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <div class="checkmark mb-3"><img src="~@/assets/images/checkmark.svg" width="85px" height="85px" alt="Swisscare"></div>
                        <span class="green">{{'thank_you_label'|translate}}</span>
                    </div>
                    <div class="col-12 text-center mt-3">
                        <p>{{'thank_you_text'|translate}}</p>
                    </div>
                    <div class="col-12 text-center mt-5">
                        <button type="button" class="btn" v-on:click="goToHome">{{'back_to_homepage_label'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
var qs = require('qs');

export default {
    name: 'thanks',
    components:{
    },
    props:{
    },
    methods:{
        goToHome:function(){
            router.replace({ path: "/policies"})
        }
    },
    mounted() {
       this.$parent.showLoader = false
    }
}
</script>

<style scoped>
    .green{
        font-weight:500;
        font-size: 42px;
        color: #64DEAB;
        text-align: center;
    }

    p{
        font-weight:normal;
        font-size: 19px;
        color: #3A3A3A;
        letter-spacing: 0;
        text-align: center;
        line-height: 28px;
    }
</style>

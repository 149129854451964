<template>
  <div :class="wrapperClass">

    <div v-show="!hostedFieldsInstance" class="loader" id="loader-1"></div>

    <div class="row" v-show="hostedFieldsInstance">
      
      <div class="line" v-if="collectCardHolderName">
        <label for="cardholder">Card Holder
          <input type="text" class="input-field" id="cardholder" name="cardholder" placeholder="Name">
        </label>
      </div>
        
        <div class="col-12 mt-3 line">
            <fieldset>
                <label for="card-number">Card number</label>
                <div class="input-field" id="number"></div>
            </fieldset>
        </div>
        <div class="col-6 mt-3 line">
            <fieldset>
                <label for="expiration-date">Expiry</label>
                <div class="input-field" id="expiration-date"></div>
            </fieldset>
        </div>
        <div class="col-6 mt-3 line">
            <fieldset>
                <label for="cvv">CVV (3 digits)</label>
                <div class="input-field" id="cvv"></div>
            </fieldset>
        </div>

    </div>

  </div>
</template>

<script>
    var threeDSecure = require('braintree-web/three-d-secure');
  export default {
    props: {
      authToken: {
        value: String,
      },
      wrapperClass: {
        value: String,
      },
      loaderClass: {
        value: String,
      },
      inputClass: {
        value: String,
      },
      collectCardHolderName: {
        value: Boolean,
      },
      collectPostalCode: {
        value: Boolean,
      },
      enableDataCollector: {
        value: Boolean,
      }
    },
    created() {
      this.createBT();

      this.$parent.$on('tokenize', () => {
        this.tokenizeHF();
      });
      this.$parent.$on('verify', () => {
        this.verify();
      });
      this.$parent.$on('createBT', () => {
        this.createBT();
      });
    },
    data () {
      return {
        errorMessage: '',
        clientInstance: '',
        threeDSecureInstance: '',
        hostedFieldsInstance: '',
        tokenizePayload: '',
        dataCollectorPayload: '',
        token_3d: this.$parent.token_3d
      }
    },
    methods: {
       createBT () {
        const client = require('braintree-web/client');
        client.create({
          authorization: this.authToken
        }, (clientErr, clientInstance) => {
          if (clientErr) {
            this.errorMessage = 'There was an error setting up the client instance. Message: ' + clientErr.message;
            this.$emit('bthferror', this.errorMessage);
            return;
          } else {
            this.clientInstance = clientInstance
            this.createHF();

            if (this.enableDataCollector) {
              this.dataCollectorCreate();
            }
          }
        });
      },
      createHF () {
          var self = this;
        const hostedFields = require('braintree-web/hosted-fields');
        hostedFields.create({
          client: self.clientInstance,
          styles: {
            'input': {
                 'width':'100%',
                'font-weight':'normal',
                'font-style': 'normal',
                'font-size': '16px',
                'letter-spacing': '1px',
                '-webkit-appearance':'none',
                'height':'25px',
                'font-family': 'Montserrat'
            },
            'input.invalid': {
              'color': 'red'
            },
            'input.valid': {
              'color': 'green'
            }
          },
          fields: {
            number: {
              selector: '#number',
              placeholder: '',
            },
            cvv: {
              selector: '#cvv',
              placeholder: '',
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: '',
            }
          },
        }, (hostedFieldsErr, hostedFieldsInstance) => {
          if (hostedFieldsErr) {
            // Handle error in Hosted Fields creation
            this.errorMessage = hostedFieldsErr.message;
            this.$emit('bthferror', this.errorMessage);
            return;
          } else {
            this.$emit('bthfready');
            this.hostedFieldsInstance = hostedFieldsInstance;
          }

        });
      },
      tokenizeHF () {
        const additionalFields = {
          cardholderName: null,
          billingAddress: {
            postalCode: null,
          },
        };
        if (this.collectCardHolderName) {
          additionalFields.cardholderName = document.querySelector('#cardholder').value;
        }
        if (this.collectPostalCode) {
          additionalFields.billingAddress.postalCode = document.querySelector('#postal').value;
        }
        //console.log(additionalFields);
        this.hostedFieldsInstance.tokenize(additionalFields, (tokenizeErr, payload) => {
          if (tokenizeErr) {
            this.errorMessage = tokenizeErr.message;
            this.$emit('bthferror', this.errorMessage);
            return;
          }

          this.tokenizePayload = payload;
          this.$emit('bthfpayload', payload);
          this.teardownHF();

        });
      },
      teardownHF () {
        this.hostedFieldsInstance.teardown( (teardownErr) => {
            if (teardownErr) {
              this.errorMessage = 'There was an error tearing it down! Message: ' + teardownErr.message;
              this.$emit('bthferror', this.errorMessage);
              return;
            } else {
              this.hostedFieldsInstance = '';
              return;
            }
        });
      },
      dataCollectorCreate() {
        const dataCollector = require('braintree-web/data-collector');
        dataCollector.create({
          client: this.clientInstance,
          kount: true,
        }, (dataCollectorErr, dataCollectorInstance) => {
          if (dataCollectorErr) {
            this.errorMessage = 'There was an error setting up the data collector! Message: ' + dataCollectorErr.message;
            this.$emit('bthferror', this.dataCollectorErr);
            return;
          }

          this.$emit('device.data.payload', dataCollectorInstance.deviceData);
          this.dataCollectorPayload = dataCollectorInstance;

        });

      }
    },
  };
</script>

<style>
</style>
<template>
<div class="boxed">
        <div class="row">
            <div class="col-12 mt-3 line">
                <fieldset>
                    <label for="card-card-number">{{'card_number_label'|translate}}</label>
                    <div id="card-number" class='stripe-element card-number' ref='cardNumber'/>
                </fieldset>
            </div>
            <div class="col-6 mt-3 line">
                <fieldset>
                    <label for="card-expiry">{{'expiry_label'|translate}}</label>
                    <div id="card-expiry" class='stripe-element card-expiry' ref='cardExpiry'></div>
                </fieldset>
            </div>
            <div class="col-6 mt-3 line">
                <fieldset>
                    <label for="card-cvv">{{'cvv_label'|translate}}</label>
                    <div id="card-cvc" class='stripe-element card-cvc' ref='cardCvc'></div>
                </fieldset>
            </div>
        </div>
</div>
</template>

<script>

  import {initiatePayment} from "./StripeService";
  import {stripeApiKey, stripeApiKeyHMIE, stripeApiKeySGIS} from "./config";
  let Stripe = window.Stripe

export default {
  name: 'Stripe',
  props: {
    createConfirmationPayload: Function,
    createValidationPayload: Function,
    onPaymentSuccess: Function,
    onPaymentFailure: Function,
    formCode: String
  },
  mounted() {
    const StripeKey = this.$props.formCode === 'SGIS' ? stripeApiKeySGIS : this.$props.formCode === 'HMIE' ? stripeApiKeyHMIE : stripeApiKey
    const stripe = Stripe(StripeKey)
    var style = {
      base: {
        lineHeight: '25px',
        fontWeight: 400,
        fontFamily: "'Montserrat', sans-serif",
        fontSize: '16px',

        '::placeholder': {
          color: '#CFD7E0',
          fontWeight: 300,
        },
      },
    };


    const elements = stripe.elements();
    /*const cardElement = elements.create('card', {
        style: style
    });
    cardElement.mount('#card-element');*/

    var cardNumberElement = elements.create('cardNumber', {
        style: style,
        placeholder: '4000 0000 0000 0000',
    });

    cardNumberElement.mount('#card-number');

    var cardExpiryElement = elements.create('cardExpiry', {
        style: style,
        placeholder: '01/20',
    });
    cardExpiryElement.mount('#card-expiry');

    var cardCvcElement = elements.create('cardCvc', {
        style: style,
        placeholder: '111',
    });
    cardCvcElement.mount('#card-cvc');


    const cardholderName = '';
    const cardButton = document.getElementById('paySubmit');

    cardButton.addEventListener('click',  () =>
            {
            if(!this.$parent.paySent){
                var self = this
                self.$parent.paySent = true;
                this.$parent.$validator.validateAll('paymentForm').then((result) => {
                    if(result){
                        initiatePayment(
                                stripe,
                                cardNumberElement,
                                cardholderName,
                                this.createConfirmationPayload,
                                this.createValidationPayload,
                        ).then(result => {

                          this.onPaymentSuccess(result)
                        }).catch(error => {

                          this.onPaymentFailure(error.type, error.error);
                        })
                    }else{
                    }
                })
            }
        }
    );
  }
}


</script>

<style scoped>

#card-element {
  width: 100%;

}

#card-element * {
  font-size: 16px;
  font-weight: 400;
}

#card-element fieldset {
  border: 1px solid #b5a4ed;
  padding: 15px;
  border-radius: 6px;
}

#card-element fieldset legend {
  padding: 0;
  text-align: center;
    text-transform:uppercase;
    letter-spacing:1px;
    margin:0 0 5px 0;
    font-size:11px;
    font-weight:normal;
    font-style:normal;
}

#card-element fieldset legend + * {
  clear: both;
}

#card-element .card-only {
  display: block;
}
#card-element .payment-request-available {
  display: none;
}

#card-element .row {
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 10px;
}

#card-element .field {
  position: relative;
  width: 100%;
}

#card-element .field + .field {
  margin-left: 10px;
}

#card-element label {
  width: 100%;
  color: red;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#card-element .input {
  width: 100%;
  color: #fff;
  background: transparent;
  padding: 5px 0 6px 0;
  border-bottom: 1px solid #a988ec;
  transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#card-element .input::-webkit-input-placeholder {
  color: #bfaef6;
}

#card-element .input::-moz-placeholder {
  color: #bfaef6;
}

#card-element .input:-ms-input-placeholder {
  color: #bfaef6;
}

#card-element .input.StripeElement--focus,
#card-element .input:focus {
  border-color: #fff;
}
#card-element .input.StripeElement--invalid {
  border-color: #ffc7ee;
}

#card-element input:-webkit-autofill,
#card-element select:-webkit-autofill {
  -webkit-text-fill-color: #fce883;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
}

#card-element .StripeElement--webkit-autofill {
  background: transparent !important;
}

#card-element input,
#card-element button,
#card-element select {
  -webkit-animation: 1ms void-animation-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
  border-radius: 0;
}

#card-element select.input,
#card-element select:-webkit-autofill {
  background-image: url('data:image/svg+xml;utf8,<svg width="10px" height="5px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="#fff" d="M5.35355339,4.64644661 L9.14644661,0.853553391 L9.14644661,0.853553391 C9.34170876,0.658291245 9.34170876,0.341708755 9.14644661,0.146446609 C9.05267842,0.0526784202 8.92550146,-2.43597394e-17 8.79289322,0 L1.20710678,0 L1.20710678,0 C0.930964406,5.07265313e-17 0.707106781,0.223857625 0.707106781,0.5 C0.707106781,0.632608245 0.759785201,0.759785201 0.853553391,0.853553391 L4.64644661,4.64644661 L4.64644661,4.64644661 C4.84170876,4.84170876 5.15829124,4.84170876 5.35355339,4.64644661 Z" id="shape"></path></svg>');
  background-position: 100%;
  background-size: 10px 5px;
  background-repeat: no-repeat;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
    font-size:16px;
}

#card-element button {
  display: block;
  width: 100%;
  height: 40px;
  margin: 20px 0 0;
  background-color: #fff;
  border-radius: 6px;
  color: #9169d8;
  font-weight: 500;
  cursor: pointer;
}

#card-element button:active {
  background-color: #cdd0f8;
}

#card-element .error svg .base {
  fill: #fff;
}

#card-element .error svg .glyph {
  fill: #9169d8;
}

#card-element .error .message {
  color: #fff;
}

#card-element .success .icon .border {
  stroke: #bfaef6;
}

#card-element .success .icon .checkmark {
  stroke: #fff;
}

#card-element .success .title {
  color: #fff;
}

#card-element .success .message {
  color: #cdd0f8;
}

#card-element .success .reset path {
  fill: #fff;
}
</style>

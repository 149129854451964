<template>
    <div id="popright">
        <div class="close" v-on:click="$parent.showEditPayment = false"><i class="icon-cross"></i></div>
        <div class="scrolling-inner">
            <div class="inner px-3 py-5 px-md-5">
                <div class="px-3 px-md-5" id="editPayment">
                    <div class="row">
                        <div class="col-12"><h2 class="text-capitalize">{{'edit_payment_label'|translate}}</h2></div>
                    </div>
                    <form id="editPayment-form" data-vv-scope="editPayment">
                        <div class="row">
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'payment_rate_label'|translate}}</label>

                                    <div class="select-wrapper">
                                        <select id="period" name="period" v-model="period" v-validate="'required'">
                                            <option v-for="period in periods" v-bind:value="period.id">{{period.name}}</option>    
                                        </select>
                                    </div>
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPayment.period')">{{'this_field_required_label'|translate}}</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-5">
                            <div class="col-auto">
                                <button type="button" class="btn smaller" v-on:click="editPolicyPayment()">{{'save_label'|translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var qs = require('qs'); 

export default {
    props:['policy'],
    data(){
        return {
            periods: null,
            period: this.policy.period.id
        }
    },
    methods:{
        editPolicyPayment: function(){
            var self = this

            self.$validator.validateAll('editPayment').then((result) => {
                if (result){
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            authtoken: self.$root.user.authtoken,

                            id: self.policy.id,
                            period_id: self.period
                            
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'editPolicyById'
                    }).then(function (response) {
                        if(response.data.result){
                            self.$notify({
                              group: 'notif',
                              type:'success',
                              text: response.data.msg
                            });
                            
                            self.$parent.showEditPayment = false
                        }else{
                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: response.data.msg
                            });
                            self.checkToken(response.data.code);
                        }
                    }).catch(function (error) {
                        console.log(error);

                        self.$notify({
                          group: 'notif',
                          type:'danger',
                          text: error
                        });
                    });  
                }
            });
            
        }, 
    },
    beforeMount(){
        var self = this

        self.axios({
            data: qs.stringify({
                language: 'en',
                timezone: '+2',
                device: 'desktop',
                version: '1.0',

                authtoken: this.$root.user.authtoken,
            }),
            method: 'post',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: self.$root.apiUrl+'getPeriods'
        }).then(function (response) {
            if(response.data.result){
                self.periods = response.data.data
            }else{
                self.checkToken(response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });  
    }
}
</script>

<style scoped>
    #popright{
        width:90%;
        max-width:700px;
        background:#fff;
        position:fixed;
        right:0;
        top:0;
        bottom:0;
        left:auto;
        z-index:1000;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.13);
        -webkit-transition: 600ms all;  
        -moz-transition:     600ms all;
        -ms-transition:      600ms all; 
        -o-transition:       600ms all;  
    }

    #popright .close{
        width:30px;
        height:30px;
        line-height:30px;
        border-radius:50%;
        background: #7B7698;
        color: #FFFFFF;
        text-align:center;
        font-size:14px;
        position:absolute;
        z-index:1002;
        top: 30px;
        left: -15px;
        opacity:1;
        float:none;
    }
    
    #popright:before{
        background: rgba(123,118,152,0.30);
        position:fixed;
        left:0;
        width:100vw;
        height:100vh;
        top:0;
        content:'';
        z-index:-1;
    }
    
    #popright .inner{
        background:#fff;
        z-index:1001;
        position:relative;
        height:100vh;
        width:100%;
        overflow:auto;
    }
</style>
<template>
    <div class="login-box mx-auto">
        <form id="activate-form" data-vv-scope="activate-form">
            <div class="row justify-content-center">
                <div class="col-auto mb-3 mt-2 mt-sm-5">
                    <img src="~@/assets/images/swisscare-logo.svg" width="190px" height="35px" alt="Swisscare">
                </div>
                <div class="col-12 mt-5">
                    <p class="text-center small text-info">{{'activate_your_account_label'|translate}}</p>
                </div>
                <div class="col-12 mt-2 mt-sm-3">
                    <fieldset>
                        <label>{{'new_password_label'|translate}}</label>
                        <div class="password-container">
                            <input :type="showPassword === true ? 'text' : 'password'" id="act_new_password" name="act_new_password" v-model="password" v-validate="'required'" v-on:keyup.enter="activateAccount" tabindex="1" ref="password_act_new" :data-vv-as="'new_password_label'|translate">
                            <i class="fas fa-eye show-icon" v-if="showPassword" @click="showPassword = !showPassword"></i>
                            <i class="fas fa-eye-slash hide-icon" v-else  @click="showPassword = !showPassword"></i>
                            <div class="strength" :class="'level_' + this.strengthLevel"></div>
                        </div>
                    </fieldset>
                    <span class="field-error">{{ errors.first('activate-form.act_new_password')}}</span>
                </div>
                <div class="col-12 mt-2 mt-sm-3">
                    <fieldset>
                        <label>{{'new_password_again_label'|translate}}</label>
                        <input type="password" id="act_new_password_again" name="act_new_password_again" v-validate="'required|confirmed:password_act_new'" v-on:keyup.enter="activateAccount" tabindex="1" :data-vv-as="'new_password_again_label'|translate">
                    </fieldset>
                    <span class="field-error">{{ errors.first('activate-form.act_new_password_again')}}</span>
                </div>

                <div class="col-auto mt-2 mt-sm-5">
                    <button type="button" class="btn" v-on:click="activateAccount" tabindex="3">{{'send_label'|translate}}</button>
                </div>
            </div>
        </form>
        <div class="row justify-content-center">
            <div class="col-auto mt-3">
                <a href="#" class="link" v-on:click="$parent.showActivate = false">{{'back_to_login_label'|translate}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router'
var qs = require('qs');

export default {
    name: 'activate',
    data: function () {
        return {
            password:'',
            showPassword: false,
            token: null
        }
    },
      computed: {
        scorePassword() {
          let self = this;
          let score = 0;
          if (self.password === '') return score;

          let variations = {
            digits: /\d/.test(self.password),
            lower: /[a-z]/.test(self.password),
            upper: /[A-Z]/.test(self.password),
            //special: /\W/.test(self.password),
            length: self.password.length >= 8
          }

          let variationCount = 0;
          for (let check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
          }

          score += (variationCount) * 10;

          return parseInt(score);
        },
        strengthLevel() {
          let pass = this.scorePassword;
          if(pass === 0) return 0;
          if(pass < 15) return 1;
          if(pass < 25) return 2;
          if(pass < 35) return 3;
          if(pass >= 40) return 4;
        }
      },
    methods:{
        activateAccount() {
            var self = this
            if(self.scorePassword < 40){
                self.$notify({
                  group: 'notif',
                  type:'danger',
                  text: self.$root.labels.password_info_text
                });
            }else{
                self.$validator.validateAll('activate-form').then((result) => {
                    if(result) {
                        self.axios({
                            data: qs.stringify({
                                language: 'en',
                                timezone: '+2',
                                device: 'desktop',
                                version: '1.0',

                                password: self.password,
                                token: self.token,
                            }),
                            method: 'post',
                            headers: { 'content-type': 'application/x-www-form-urlencoded' },
                            url: self.$root.apiUrl+'activation'
                        }).then(function (response) {
                            if(response.data.result){
                                self.$notify({
                                  group: 'notif',
                                  type:'success',
                                  text: response.data.msg
                                });

                                self.$parent.showActivate = false

                            }else{
                                self.$notify({
                                  group: 'notif',
                                  type:'danger',
                                  text: response.data.msg
                                });
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });
                    }
                });
            }
        }
    },
    mounted(){
        this.token = this.$route.params.token
        //localStorage.clear();
        localStorage.removeItem("authtoken");
        localStorage.removeItem("need_brokerage");
    }
}

</script>

<template>
    <div v-if="$root.user">
        <form data-vv-scope="claims-form">
            <div class="row justify-content-center mt-2 mt-md-5">
                <div class="col-12 col-md-6">
                    <div class="select-box">
                        <select name="claim_type" v-model="$parent.claim.type.id" v-validate="'required'">
                            <option :value="type.name" v-for="type in $parent.types">{{type.name}}</option>
                            <option value="other">{{'other_label'|translate}}</option>
                        </select>
                    </div>
                    <span class="field-error" v-show="errors.first('claims-form.claim_type')">{{'please_select_label'|translate}}</span>
                </div>
            </div>
            <div class="row justify-content-center mt-5">
                <div class="col-12 col-md-6" v-show="$parent.claim.type.id == 'other'">
                    <fieldset class="no-border">
                        <label>{{'type_other_claim_label'|translate}}</label>
                        <textarea id="other" name="other" v-model="$parent.claim.type.other" v-validate="$parent.claim.type.id == 'other' ? 'required' : ''"></textarea>
                    </fieldset>
                    <span class="field-error" v-show="errors.first('claims-form.other')">{{'this_field_required_label'|translate}}</span>
                </div>
            </div>
            <div class="row justify-content-center mt-md-5">
                <div class="col-auto"><button class="btn empty" type="button" v-on:click="prevStep"><i class="icon-arrow_left mr-3"></i>{{'step_back_label'|translate}}</button></div>
                <div class="col-auto"><button class="btn" type="button" v-on:click="nextStep">{{'next_label'|translate}}</button></div>
            </div>
        </form>
    </div>
</template>

<script>
import router from '../router'
var qs = require('qs');

export default {
    name: 'claims_type',
    components:{

    },
    props:{

    },
    data(){
        return{
            showOtherTextarea : false
        }
    },
    methods:{
        getClaimsTypes: function(){
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: self.$root.user.authtoken,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getClaimsTypes'
            }).then(function (response) {
                if(response.data.result){
                    self.$parent.types = response.data.data
                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        nextStep: function(){
            var self = this
            self.$parent.claim.type.id == 'other' ? self.$parent.claim.type.text = self.$parent.claim.type.other : self.$parent.claim.type.text = self.$parent.claim.type.id;

            self.$validator.validateAll('claims-form').then((result) => {
                if (result){
                    this.$parent.currentStep = 3;
                    router.replace({ path: "/claims/details"})
                }
            });
        },
        prevStep:function(){
             this.$parent.currentStep = 1;
            router.replace({ path: "/claims/policies"})
        }
    },
    beforeMount() {
        this.getClaimsTypes()

        if(this.$parent.currentStep != 2){
            this.$parent.currentStep = 1
            router.replace({ path: "/claims/policies"});
        }
        //this.$parent.currentStep = 2
    }
}
</script>

<style scoped>
    .types{
        list-style:none;
    }

    .types li{
        font-size: 16px;
        color: #3A3A3A;
        letter-spacing: 0;
        line-height:32px;
        width:50%;
        float:left;
    }

    fieldset{
        background: rgba(58,58,58,0.05);
        border-radius:4px;
        padding:10px;
        border:none;
        margin:5px 0;
    }

    textarea{
        width:100%;
        border:none;
        background:none;
        min-height:100px;
        resize:none;
    }

    .select-box{
        width:100%;
        position:relative;

    }
    .select-box:before{
        content: "\e902";
        font-family:'account';
        position:absolute;
        right:20px;
        top:19px;
        pointer-events: none;
    }
    select{
        width:100%;
        height:60px;
        background:#fff;
        border:1px solid #DEDEDE;
        margin:auto;
        display:table;
        box-shadow: 0 5px 20px 0 rgba(78,32,108,0.1);
        -webkit-appearance: none;
        -moz-appearance: none;
        padding:0 30px;
    }
</style>

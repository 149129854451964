<template>
    <div v-if="$root.user">
        <form data-vv-scope="claims-form">
            <div class="row mt-0">
                <div class="col-12 col-lg-4 text-center">
                    <div class="row">
                        <div class="col-12 my-3">
                            <h5 class="mb-3">{{'detailed_medical_report_label'|translate}} <p class="required">*</p></h5>
                             <input type="file" id="report" ref="report" v-on:change="handleFileUpload('report')"/>
                             <button type="button" class="btn secondary" @click="$refs.report.click()"><span><i class="icon-plus"></i></span>{{'upload_file_label'|translate}}</button>
                             <span class="field-error" v-show="filesError.report">{{'please_upload_file_label'|translate}}</span>
                        </div>
                        <div class="col-12 mt-4" v-if="$parent.files.report">
                            <div class="row mb-2 file-row justify-content-center" v-for="(document,index) in $parent.files.report">
                                <div class="col-auto"><a href="#" class="action-btn" @click.prevent="deleteFile('report',index, $event)"><i class="icon-cross"></i></a></div>
                                <div class="col-auto"><div class="icon" @click.prevent="previewFile('report',index)"><i class="icon-icon-image" v-show="document.type == 'image/png'"></i><i class="icon-icon-file" v-show="document.type != 'image/png'"></i></div></div>
                                <div class="col-auto"><span class="document-name text-truncate d-inline-block">{{document.name}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 text-center">
                    <div class="row">
                        <div class="col-12 my-3">
                            <h5 class="mb-3">{{'proof_of_payment_label'|translate}} <p class="required">*</p></h5>
                             <input type="file" id="proof" ref="proof" v-on:change="handleFileUpload('proof')"/>
                             <button type="button" class="btn secondary" @click="$refs.proof.click()"><span><i class="icon-plus"></i></span>{{'upload_file_label'|translate}}</button>
                             <span class="field-error" v-show="filesError.proof">{{'please_upload_file_label'|translate}}</span>
                        </div>
                        <div class="col-12 mt-4" v-if="$parent.files.proof">
                            <div class="row mb-2 file-row justify-content-center" v-for="(document,index) in $parent.files.proof">
                                <div class="col-auto"><a href="#" class="action-btn" @click.prevent="deleteFile('proof',index, $event)"><i class="icon-cross"></i></a></div>
                                <div class="col-auto"><div class="icon" @click.prevent="previewFile('proof',index)"><i class="icon-icon-image"></i></div></div>
                                <div class="col-auto"><span class="document-name text-truncate d-inline-block">{{document.name}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 text-center">
                    <div class="row">
                        <div class="col-12 my-3">
                            <h5 class="mb-3">{{'other_documents_label'|translate}}</h5>
                             <input type="file" id="other" ref="other" v-on:change="handleFileUpload('other')"/>
                             <button type="button" class="btn secondary" @click="$refs.other.click()"><span><i class="icon-plus"></i></span>{{'upload_file_label'|translate}}</button>
                        </div>
                        <div class="col-12 mt-4" v-if="$parent.files.other">
                            <div class="row mb-2 file-row justify-content-center" v-for="(document,index) in $parent.files.other">
                                <div class="col-auto"><a href="#" class="action-btn" @click.prevent="deleteFile('other',index, $event)"><i class="icon-cross"></i></a></div>
                                <div class="col-auto"><div class="icon" @click.prevent="previewFile('other',index)"><i class="icon-icon-image"></i></div></div>
                                <div class="col-auto"><span class="document-name text-truncate d-inline-block">{{document.name}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-0">
                    <div class="row justify-content-center mt-5">
                        <div class="col-auto"><button class="btn empty" type="button" v-on:click="prevStep"><i class="icon-arrow_left mr-3"></i>{{'step_back_label'|translate}}</button></div>
                        <div class="col-auto"><button class="btn" type="button" v-on:click="nextStep">{{'next_label'|translate}}</button></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import router from '../router'

var qs = require('qs');

export default {
    name: 'claims_upload',
    components:{
    },
    props:{

    },
    data(){
        return{
            filesError:{
                report: false,
                proof: false
            }
        }
    },
    methods:{
        handleFileUpload(ref){
              if (this.$refs.report.files[0].size > 1024 * 1024 * 5) {
                  this.showModal(this.$root.labels.upload_error_label,this.$root.labels.too_large_file_label);
                return;
              }else{
                if(ref=='report'){this.$parent.files.report.push(this.$refs.report.files[0]);}
                if(ref=='proof'){this.$parent.files.proof.push(this.$refs.proof.files[0]);}
                if(ref=='other'){this.$parent.files.other.push(this.$refs.other.files[0]);}
              }

        },
        deleteFile(ref,index,e){
            if(ref=='report'){this.$parent.files.report.splice(index, 1)}
            if(ref=='proof'){this.$parent.files.proof.splice(index, 1)}
            if(ref=='other'){this.$parent.files.other.splice(index, 1)}
            e.stopPropagation()
        },
        nextStep: function(){
            var self = this
            self.filesError.proof = false
            self.filesError.report = false
            self.filesError.other = false

            if(!self.$parent.files.report.length){self.filesError.report = true}
            if(!self.$parent.files.proof.length){self.filesError.proof = true}
            if(!self.$parent.files.other.length && self.$parent.claim.insurance_code == 'SPSS'){self.filesError.other = true}

            self.$validator.validateAll('claims-form').then((result) => {
                if (result && !self.filesError.report && !self.filesError.proof){
                    this.$parent.currentStep = 5;
                    router.replace({ path: "/claims/bank-details"})
                }
            });
        },
        prevStep:function(){
             this.$parent.currentStep = 3;
            router.replace({ path: "/claims/details"})
        },
        previewFile:function(ref,index){
            if(ref=='report'){this.file = this.$parent.files.report[index]}
            if(ref=='proof'){this.file = this.$parent.files.proof[index]}
            if(ref=='other'){this.file = this.$parent.files.other[index]}

            const objectURL = window.URL.createObjectURL(this.file);
            window.open(objectURL,'_blank');
        }
    },
    beforeMount() {
        if(this.$parent.currentStep != 4){
            this.$parent.currentStep = 1
            router.replace({ path: "/claims/policies"});
        }
        //this.$parent.currentStep = 4
    }
}
</script>

<style scoped>
    input{
        display:none;
    }

    .btn.secondary{
        background: #FFFFFF;
        border: 1px solid #E9E8F3;
        box-shadow: 0 5px 20px 0 rgba(0,0,0,0.05);
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
        border-radius:8px;
        line-height:30px;
        text-transform: none;
    }

    .btn.secondary span{
        width:30px;
        height:30px;
        line-height:32px;
        margin-right:10px;
    }
    .btn.secondary i{
        float:none;
        margin:auto;
    }

    h5{
        font-weight: 600;
        font-size: 13px;
        color: #3A3A3A;
        letter-spacing: 0;
        line-height: 18px;
    }
    .required{
        color:red;
        display:inline-block;
        margin:0;
    }

    hr{
        background: #EBEBEB;
        height:1px;
        width:100%;
        border:none;
    }

    .action-btn{
        border: 2px solid #D2D0DD;
        color:#D2D0DD;
        border-radius:50%;
        text-align:center;
        width:20px;
        height:20px;
        line-height:18px;
        display:inline-block;
        font-size:12px;
        margin-top:8px;
    }

    .icon{
        background:#FAFAFC;
        border: 2px solid rgba(188,182,224,0.43);
        color: #9F98C8;
        width:36px;
        height:36px;
        line-height:36px;
        border-radius:50%;
        text-align:center;
    }

    .document-name{
        line-height:36px;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0;
        max-width:160px;
    }

    .file-row:hover .action-btn{
        border-color:red;
        color:red;
    }

    .field-error{
        margin:20px 0;
    }
</style>

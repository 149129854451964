<template>
  <div id="popright" v-if="$parent.showChangeCard">
    <div class="close" v-on:click="$parent.showChangeCard = false"><i class="icon-cross"></i></div>
    <div class="scrolling-inner">
      <div class="inner px-3 py-5 px-md-5">
        <div class="px-3 px-md-5 mt-5">
          <form id="payment-form" data-vv-scope="paymentForm">
            <div class="row">
              <div class="col-12">
                <Braintree
                           amount=1
                           premium=1
                           v-bind:on-payment-success="onPaymentSuccess"
                           v-bind:on-payment-failure="onPaymentFailureBraintree"
                           v-bind:create-client-generation-payload="createBraintreeClientTokenGenerationPayload"
                           v-bind:create-checkout-additional-payload="createBraintreeCheckoutAdditionalPayload"
                />
                <span class="field-error" v-show="paymentError"><i class="fas fa-exclamation-triangle"> </i> {{paymentError}}</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-auto">
                <button type="button" class="btn smaller px-4" id="changeSubmit"><i v-show="paySent" class="fas fa-circle-notch fa-spin"></i><em v-show="!paySent">{{'save_label'|translate}}</em></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var qs = require('qs');
import Braintree from '../braintree/Braintree.vue'

export default {
  props:['profile','policy'],
  components:{
    Braintree
  },
  data(){
    return {
      formStatus : true,
      paymentError: null,
      token: null,
      token_3d: null,
      return_url: null,
      paySent: false,
    }
  },
  methods:{
    createBraintreeClientTokenGenerationPayload() {
      return {
        language: 'en',
        timezone: '+2',
        device: 'desktop',
        version: '1.0',
        authtoken: this.$root.user.authtoken,

        id: this.$parent.policy.id,
        token: null,
        token_3d: null,
        return_url: this.return_url,
        payment: 'cc'
      }
    },

    createBraintreeCheckoutAdditionalPayload(verifyResult) {
      return {
        nonce: verifyResult.nonce,
        language: 'en',
        timezone: '+2',
        device: 'desktop',
        version: '1.0',
        authtoken: this.$root.user.authtoken,

        id: this.$parent.policy.id,
        token: verifyResult.nonce,
        token_3d: verifyResult.nonce,
        confirm: verifyResult.nonce,
        return_url: this.return_url,
        payment: 'cc'
      }
    },
    onPaymentSuccess(backendResponse) {
      this.$notify({
        group: 'notif',
        type:'success',
        text: backendResponse.data.msg
      });

      this.$parent.showPayNow = false
      this.$parent.$parent.getPolicyByHash()
      this.paySent = false;

      this.$forceUpdate();
      this.$parent.getPolicyPayments();
    },
    onPaymentFailureBraintree(error, details) {
      if(details){
        this.paymentError = details.message
        this.$notify({
          group: 'notif',
          type:'danger',
          text: details.message
        });
      }else{
        this.paymentError = error
        this.$notify({
          group: 'notif',
          type:'danger',
          text: error
        });
      }
      this.paySent = false;
    }
  },
  beforeMount(){
    var self = this
  },
}
</script>

<style scoped>
#popright{
  width:90%;
  max-width:700px;
  background:#fff;
  position:fixed;
  right:0;
  top:0;
  bottom:0;
  left:auto;
  z-index:1000;
  box-shadow: 0 0 40px 0 rgba(0,0,0,0.13);
  -webkit-transition: 600ms all;
  -moz-transition:     600ms all;
  -ms-transition:      600ms all;
  -o-transition:       600ms all;
}

#popright .close{
  width:30px;
  height:30px;
  line-height:30px;
  border-radius:50%;
  background: #7B7698;
  color: #FFFFFF;
  text-align:center;
  font-size:14px;
  position:absolute;
  z-index:1002;
  top: 30px;
  left: -15px;
  opacity:1;
  float:none;
}

#popright:before{
  background: rgba(123,118,152,0.30);
  position:fixed;
  left:0;
  width:100vw;
  height:100vh;
  top:0;
  content:'';
  z-index:-1;
}

#popright .inner{
  background:#fff;
  z-index:1001;
  position:relative;
  height:100vh;
  width:100%;
  overflow:auto;
}

#popright .inner h4{
  font-weight:500;
  font-size: 18px;
  color: #4E206C;
  margin-bottom:30px;
}

.payment-type{
  border:1px solid rgba(155, 155, 155,.2);
  border-radius:5px;
  background: #FFFFFF;
  border: 1px solid #ECEBF4;
  box-shadow: 0 5px 20px 0 rgba(78,75,96,0.10);
  font-weight:500;
  font-size: 14px;
  color: #4E4B60;
  line-height: 19px;
  padding:20px;
}

.payment-type.active{
  background: #4E206C;
  box-shadow: 0 10px 30px 0 rgba(78,32,108,0.17);
  border: 1px solid #FFFFFF;
  color:#fff;
}

.payment-type label{
  font-weight:500;
  font-size: 14px;
  color: #4E4B60;
  line-height: 22px;
  margin-bottom:0;
}

em{
  font-style:normal;
  font-weight:normal;
}

.comission-text{
  font-size:11px;
  color: #4E206C;
  font-weight:300;
}

.payment-type.active img{
  filter:brightness(10);
}
.payment-type.active label{
  color:#fff;
}
.payment-type.active .comission-text{
  color:#C3A6F3;
}

.amount-field{
  text-align:left;
}

.amount-field label{
  font-weight:400;
  text-transform:uppercase;
  font-size: 26px;
  color: #4E206C;
  line-height:1em;
}

.amount-field span{
  font-weight:600;
  font-size: 52px;
  color: #4E206C;
  line-height:1em;
}
</style>
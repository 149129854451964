import Vue from 'vue'
//import VeeValidate from 'vee-validate';
import Router from 'vue-router'

import Login from '@/components/Login.vue'

import Dashboard from '@/components/Dashboard.vue'

import Policies from '@/components/Policies.vue'
import Policy from '@/components/Policy.vue'
import Policy_details from '@/components/Policy_details.vue'
import Policy_finance from '@/components/Policy_finance.vue'
import Policy_insurance_card from '@/components/Policy_insurance_card.vue'
import Policy_emergency_contacts from '@/components/Policy_emergency_contacts.vue'

import Profile from '@/components/Profile.vue'

import Claims from '@/components/Claims.vue'
import Claims_policies from '@/components/Claims_policies.vue'
import Claims_type from '@/components/Claims_type.vue'
import Claims_details from '@/components/Claims_details.vue'
import Claims_upload from '@/components/Claims_upload.vue'
import Claims_bank from '@/components/Claims_bank.vue'
import Claims_finish from '@/components/Claims_finish.vue'

import Thanks from '@/components/Thanks.vue'
import Switching from '@/components/Switching.vue'

import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
Vue.use(VueDatePicker);


Vue.use(Router)
/*Vue.use(VeeValidate, {
  events: ''
});*/

export default new Router({
  //mode: 'history',
  hashbang: true,
  history: true,
  linkExactActiveClass: 'current',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      props: true,
    },
    {
        path: '/reset-password/:token',
        name: 'resetPass',
        component: Login,
        props: {
            showReset: Boolean,
            default: true
        },
    },
    {
        path: '/activate/:token',
        name: 'activate',
        component: Login,
        props: {
            showActivate: Boolean,
            default: true
        },
    },
    {
        path: '/switch/:token',
        name: 'switchWithoutLogin',
        component: Login,
        props: {
            showSwitch: Boolean,
            default: true
        },
    },
    {
      path: '/:pagetype',
      component: Dashboard,
      children: [
        {
            path: '/policies',
            name: 'policies',
            component: Policies,
            props: true,
        },
        {
            path: '/policies/:id',
            name: 'policy',
            component: Policy,
            props: true,
            children: [
                {
                    path: '/policies/:id/',
                    name: 'policy_details',
                    component: Policy_details,
                    props: true,
                },{
                    path: '/policies/:id/finance',
                    name: 'policy_finance',
                    component: Policy_finance,
                    props: true,
                },
                {
                    path: '/policies/:id/emergency-contacts',
                    name: 'policy_emergency_contacts',
                    component: Policy_emergency_contacts,
                    props: true
                }
            ],
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            props: true,
        },
        {
            path: '/claims',
            name: 'claims',
            component: Claims,
            props: true,
            children: [
                {
                    path: '/claims/policies/',
                    name: 'claims_policies',
                    component: Claims_policies,
                    props: true,
                },{
                    path: '/claims/type/',
                    name: 'claims_type',
                    component: Claims_type,
                    props: true,
                },
                {
                    path: '/claims/details',
                    name: 'claims_details',
                    component: Claims_details,
                    props: true
                },
                {
                    path: '/claims/upload',
                    name: 'claims_upload',
                    component: Claims_upload,
                    props: true
                },
                {
                    path: '/claims/bank-details',
                    name: 'claims_bank',
                    component: Claims_bank,
                    props: true
                },
                {
                    path: '/claims/finish',
                    name: 'claims_finish',
                    component: Claims_finish,
                    props: true
                }
            ],
        },
        {
            path: '/thank-you',
            name: 'thank-you',
            component: Thanks,
            props: true,
        },
        {
            path: '/switching-plans',
            name: 'switching-plans',
            component: Switching,
            props: true,
        },
      ],
      name: 'dashboard'
    }
  ]
})

<template>
    <div v-if="$root.user">
        <form data-vv-scope="claims-form">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-6">
                    <div class="row">
                        <div class="col-12 mb-5 mt-5">
                            <p>{{'final_confirmation_text'|translate}}</p>
                            <p v-if="$parent.claim.need_extra_info">{{'claim_extra_info_text'|translate}}</p>
                        </div>
                        <div class="col-12">
                            <h5>{{'add_a_comment_label'|translate}}</h5>
                        </div>
                        <div class="col-12">
                            <fieldset class="no-border">
                                <label>{{'your_comment_label'|translate}}</label>
                                <textarea id="message" name="message" v-model="$parent.claim.message"></textarea>
                            </fieldset>
                            <span class="field-error" v-show="errors.first('claims-form.lastname')">{{'this_field_required_label'|translate}}</span>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-5">
                        <div class="col-auto"><button class="btn empty" type="button" v-on:click="prevStep"><i class="icon-arrow_left mr-3"></i>{{'step_back_label'|translate}}</button></div>
                        <div class="col-auto"><button class="btn" type="button" v-on:click="$parent.submitClaims">{{'finish_label'|translate}}</button></div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import router from '../router'
var qs = require('qs');

export default {
    name: 'claims_finish',
    components:{

    },
    props:{

    },
    data(){
        return{

        }
    },
    methods:{
        nextStep: function(){
            var self = this
            self.$validator.validateAll('claims-form').then((result) => {
                if (result){
                    router.replace({ path: "/thank-you"})
                }
            });
        },
        prevStep:function(){
             this.$parent.currentStep = 5;
            router.replace({ path: "/claims/bank-details"})
        }
    },
    beforeMount() {
        if(this.$parent.currentStep != 6){
            this.$parent.currentStep = 1
            router.replace({ path: "/claims/policies"});
        }
        //this.$parent.currentStep = 6
    }
}
</script>

<style scoped>
    fieldset{
        background: rgba(58,58,58,0.05);
        border-radius:4px;
        padding:10px;
        border:none;
        margin:5px 0;
    }

    h5{
        font-weight:600;
        font-size: 13px;
        color: #3A3A3A;
        letter-spacing: 0;
        line-height: 18px;
    }

    textarea{
        width:100%;
        border:none;
        background:none;
        min-height:100px;
        resize:none;
    }
</style>

export const baseUrl = process.env.VUE_APP_ROOT_API

export const generateClientTokenEndpoint = 'getBtTokenByPolicyId'
//export const generateClientTokenEndpoint = 'getBtToken'
export const startPaymentWithNonceEndpoint = 'payPolicyPaymentById'
export const startChangeCardWithNonceEndpoint = 'changeCard'

export const brainTreeStyle = {
    input: {
        'font-size': '16px',
        'font-family': '"Montserrat", sans-serif',
        
        ':placeholder': {
          'color': '#CFD7E0',
          'font-weight': '300',
        },
    }
}

export const brainTreeFields = {
    number: {
        selector: '#hf-number',
        placeholder: '4111 1111 1111 1111'
    },
    cvv: {
        selector: '#hf-cvv',
        placeholder: '123'
    },
    expirationDate: {
        selector: '#hf-date',
        placeholder: '12 / 34'
    }
}

<template>
    <div class="login-box mx-auto">
        <div id="forgot-form" data-vv-scope="forgot-form">
            <div class="row justify-content-center">
                <div class="col-auto mb-3 mt-2 mt-sm-5">
                    <img src="~@/assets/images/swisscare-logo.svg" width="190px" height="35px" alt="Swisscare">
                </div>
                <div class="col-12 mt-5">
                    <p class="text-center small text-info">{{'enter_your_mail_label'|translate}}</p>
                </div>
                <div class="col-12 mt-2 mt-sm-3">
                    <fieldset>
                        <label>{{'email_label'|translate}}</label>
                        <input type="text" id="fp_email" name="fp_email" v-model="$parent.input.email" v-validate="'required|email'" v-on:keyup.enter="sendActivation" tabindex="1">
                    </fieldset>
                    <span class="field-error">{{ errors.first('forgot-form.fp_email')}}</span>
                </div>
                
                <div class="col-12 mt-5">
                    <p class="text-center small text-muted">{{'you_will_receive_mail_label'|translate}}</p>
                </div>
                
                <div class="col-auto mt-2 mt-sm-5">
                    <button type="button" class="btn" v-on:click="sendActivation" tabindex="3">{{'send_label'|translate}}</button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-auto mt-3">
                <a href="#" class="link" v-on:click="$parent.showForgotPass = false">{{'back_to_login_label'|translate}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router'
var qs = require('qs'); 
    
export default {
    name: 'forgotPassword',
    methods:{
        sendActivation() {
            var self = this
            self.$validator.validateAll('forgot-form').then((result) => {
                if(result) {
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            username: this.$parent.input.email,
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'forgotPassword'
                    }).then(function (response) {
                        if(response.data.result){
                            self.$notify({
                              group: 'notif',
                              type:'success',
                              text: response.data.msg
                            });
                        }else{
                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: response.data.msg
                            });
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            });
        }
    }
}

</script>
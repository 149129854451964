<template lang="pug">
  button(
    @click="onButtonClicked()"
    :class="colorClass"
    type="button"
  ) {{ text }}
</template>

<script>
export default {
  name: 'RegularButton',
  props: {
    text: {
      type: String,
      default: 'Select'
    },
    colorClass: {
      type: String,
      default: 'green'
    }
  },
  methods: {
    onButtonClicked() {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
button.red {
    background:none;
    border-color: #DDAABB;
}

button.red:hover, button.red:active, button.red:focus {
  background: rgba(221, 170, 187, .5);
  border-color: rgba(221, 170, 187, .5);
}

</style>

<template>
    <div id="profile" v-if="$root.user.authtoken">

        <ChangeAddress v-show="showPopright" :profile="$root.user"></ChangeAddress>
        <div class="row mb-5">
            <div class="col-12"><h1 class="text-capitalize text-center text-sm-left">{{'user_profile_label'|translate}}</h1></div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="row justify-content-center justify-content-sm-start">
                    <div class="col-auto mb-3 mb-sm-0">
                        <div class="btn-tab" v-on:click="showPopright = true, currentTab = 'policyholder'"><i class="icon-edit"></i>{{'edit_address_label'|translate}}</div>
                    </div>
                    <div class="col-auto">
                        <div class="btn-tab" v-on:click="showPopright = true, currentTab = 'password'"><i class="icon-edit"></i>{{'change_password_label'|translate}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-md-12 col-lg-6 mb-5">
                <div class="row">
                    <div class="col-12 mt-5">
                        <div class="card px-3 py-3 px-md-5 py-md-5">
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'name_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{$root.user.ph_firstname}} {{$root.user.ph_lastname}}</p></div>
                            </div>
                            <div class="row mb-2" v-show="$root.user.ph_company">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'company_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{$root.user.ph_company}}</p></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'address_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info mb-0">{{$root.user.ph_address1}} {{$root.user.ph_address2}}</p></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'zip_code_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{$root.user.ph_zip}}</p></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'city_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{$root.user.ph_city}}</p></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'country_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info" v-if="$root.user.ph_country_obj">{{$root.user.ph_country_obj.name}}</p></div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'phone_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">+{{$root.user.ph_phone_country}} {{$root.user.ph_phone}}</p></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-5">
                        <h6 class="text-uppercase mb-3 mt-3 text-gray">{{'correspondence_label'|translate}}</h6>
                        <div class="card px-3 py-3 px-md-5 py-md-5">
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'name_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{$root.user.cr_firstname}} {{$root.user.cr_lastname}}</p></div>
                            </div>
                            <div class="row mb-2" v-show="$root.user.cr_company">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'company_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{$root.user.cr_company}}</p></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'address_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info mb-0">{{$root.user.cr_address1}} {{$root.user.cr_address2}}</p></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'zip_code_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{$root.user.cr_zip}}</p></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'city_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">{{$root.user.cr_city}}</p></div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'country_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info" v-if="$root.user.cr_country_obj">{{$root.user.cr_country_obj.name}}</p></div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-uppercase mb-0 strong">{{'phone_label'|translate}}</p></div>
                                <div class="col-12 col-sm-6 col-md-12 col-lg-6"><p class="text-info">+{{$root.user.cr_phone_country}} {{$root.user.cr_phone}}</p></div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="row justify-content-start mt-5">
                            <div class="col-auto">
                                <button class="btn smaller" v-on:click="logout">{{'logout_label'|translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-12 col-lg-6 pt-5 mt-5 mb-5 px-5">
                <div class="row">
                    <div class="col-12">
                        <img src="~@/assets/images/mockup.svg" alt="Swisscare" class="w-100">
                    </div>
                    <div class="col-12 justify-content-center">
                        <div class="row">
                            <div class="col-12 text-center mb-5">
                                <img src="~@/assets/images/swisscare-logo.svg" alt="Swisscare" class="w-50">
                                <span class="small text-center d-block mt-4">{{'dont_forget_to_download_our_app_label'|translate}}</span>
                            </div>
                            <div class="col-12 text-center">
                                <div class="row justify-content-center">
                                    <div class="col-auto mx-2 mb-3">
                                        <a v-bind:href="$root.urls.android" target="_blank"><img src="~@/assets/images/google-play.svg" alt="Google Play"></a>
                                    </div>
                                    <div class="col-auto mx-2 mb-3">
                                        <a v-bind:href="$root.urls.ios" target="_blank"><img src="~@/assets/images/store.svg" alt="App Store"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
import ChangeAddress from '@/components/modules/changeAddress.vue'
var qs = require('qs');

export default {
    name: 'profile',
    components:{
        ChangeAddress
    },
    props:{
        showPopright : false
    },
    data(){
        return{
            currentTab: null,
            userData:{
                firstname: this.$root.user.ph_firstname,
                lastname: this.$root.user.ph_lastname,
                gender: this.$root.user.ph_gender,
                company: this.$root.user.ph_company,
                iscompany: this.$root.user.ph_is_company,
                address:{
                    address1: this.$root.user.ph_address1,
                    address2: this.$root.user.ph_address2,
                    country: this.$root.user.ph_country,
                    zip: this.$root.user.ph_zip,
                    city: this.$root.user.ph_city,
                    countrycode: this.$root.user.ph_phone_country,
                    phone: this.$root.user.ph_phone,
                },
                correspondence:{
                    firstname: this.$root.user.cr_firstname,
                    lastname: this.$root.user.cr_lastname,
                    company: this.$root.user.cr_company,
                    gender: this.$root.user.cr_gender,
                    address1: this.$root.user.cr_address1,
                    address2: this.$root.user.cr_address2,
                    country: this.$root.user.cr_country,
                    zip: this.$root.user.cr_zip,
                    city: this.$root.user.cr_city,
                    countrycode: this.$root.user.cr_phone_country,
                    phone: this.$root.user.cr_phone,
                    iscompany: this.$root.user.cr_is_company,
                }
            }
        }
    },
    methods:{
        getProfile :function(){
            var self = this

                self.axios({
                    data: qs.stringify({
                        language: 'en',
                        timezone: '+2',
                        device: 'desktop',
                        version: '1.0',

                        authtoken: self.$root.user.authtoken,
                    }),
                    method: 'post',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    url: self.$root.apiUrl+'getProfile'
                }).then(function (response) {
                    if(response.data.result){
                        self.$root.user = response.data.data
                    }else{
                        self.checkToken(response.data.code);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
        },

        logout: function(){
            var self = this

                self.axios({
                    data: qs.stringify({
                        language: 'en',
                        timezone: '+2',
                        device: 'desktop',
                        version: '1.0',

                        authtoken: self.$root.user.authtoken,
                    }),
                    method: 'post',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    url: self.$root.apiUrl+'logout'
                }).then(function (response) {
                    if(response.data.result){
                        self.$root.user.authtoken = null;
                        localStorage.clear();
                        //localStorage.removeItem("authtoken");

                      router.push({ path: "/" })
                    }else{
                        self.checkToken(response.data.code);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
        }
    },
    mounted() {
        this.getProfile()
    },
}
</script>

<style scoped>
    .strong{
        font-weight:600;
    }
</style>

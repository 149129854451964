import { render, staticRenderFns } from "./Claims_finish.vue?vue&type=template&id=3beea1be&scoped=true&"
import script from "./Claims_finish.vue?vue&type=script&lang=js&"
export * from "./Claims_finish.vue?vue&type=script&lang=js&"
import style0 from "./Claims_finish.vue?vue&type=style&index=0&id=3beea1be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3beea1be",
  null
  
)

export default component.exports
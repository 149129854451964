<template>
    <div id="notifications">
        <div class="notif-box" v-show="$parent.notifications">
            <div class="row mx-0">
                <div class="col-12 px-4 py-3 text-left" v-for="notif in $parent.notifications" :class="'seen'+notif.seen" v-on:click="loadNotif(notif.id)">
                    <div class="row">
                        <div class="col-1"><span class="dot "></span></div>
                        <div class="col-11"><em>{{notif.title}}</em>{{notif.text}}</div>
                    </div>
                </div>
                <div class="col-12 px-4 py-3 text-left" v-show="!$parent.notifications">{{'no_notif_label'|translate}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
var qs = require('qs');

export default {
    name: 'notifications',
    data(){
      return {
        notifData: ''
      }
    },
    methods:{
        loadNotif: function(id){
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: this.$root.user.authtoken,
                    id: id,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'seenNotificationById'
            }).then(function (response) {
                if(response.data.result){
                    self.notifData = response.data.data
                    if(self.notifData.type == 1){
                        router.push({ path: "/policies/"+self.notifData.hash })
                    }
                    if(self.notifData.type == 2){
                        router.push({ path: "/policies/"+self.notifData.hash+"/finance/" })
                    }
                    if(self.notifData.type == 3){
                        window.open(self.notifData.value, "_blank");
                    }
                    self.$parent.getNotifs()
                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });

        }
    },
    beforeMount(){
    }
}
</script>

<style scoped>
    .notif-box{
        position:absolute;
        background: #FFFFFF;
        border: 1px solid #E9E8F3;
        box-shadow: 0 20px 40px 0 rgba(222,222,222,0.50);
        border-radius: 8px;
        width:450px;
        max-width:80vw;
        transform:translateX(-90%);
        margin-top:30px;
        font-weight:normal;
        z-index:1000;
    }

    .notif-box:before{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 20px 20px;
        border-color: transparent transparent #fff transparent;
        content:'';
        position:absolute;
        margin-top:-20px;
        right:16px;
        border-radius:3px;
        z-index:1;
    }
    .notif-box:after{
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 22px 22px 22px;
        border-color: transparent transparent #E9E8F3 transparent;
        content:'';
        position:absolute;
        margin-top:-22px;
        right:14px;
        border-radius:3px;
        top:0;
        z-index:0;
    }

    .notif-box > .row > div{
        border-bottom: 1px solid #E9E8F3;
    }

    .notif-box .disabled{
        background:#F8F8FB;
        font-size: 14px;
        color: rgba(78,75,96,0.50);
        letter-spacing: 0;
        line-height: 22px;
    }

    .notif-box em{
        display:block;
        font-weight:300;
        font-style: normal;
    }

    .dot{
        background: #C3A6F3;
        width:8px;
        height:8px;
        border-radius:50%;
        display:inline-block;
        margin-right:10px;
    }
    .seen1{
        background:#F8F8FB;
        color: rgba(78,75,96,0.50);
    }

    .seen1 .dot{
        display:none;
    }
</style>

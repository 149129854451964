<template>
    <div>
        <div class="row mt-5" v-for="insured in policy.insureds">
            <div class="col-12 col-md-6">
                <div class="insurance-card mt-5 py-5 px-5 mb-5">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-2"><img src="~@/assets/images/swisscare-icon.png" class="w-100" alt="Swisscare"></div>
                                <div class="col-10 pl-2">
                                    <div class="row">
                                        <div class="col-12"><label class="text-uppercase text-muted mb-0">{{'policy_number_label'|translate}}</label></div>
                                        <div class="col-12">{{policy.code}}</div>
                                        <div class="col-12 text-muted" v-html="policy.plan.text"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-12"><label class="text-uppercase text-muted mb-0">{{'name_label'|translate}}</label></div>
                                <div class="col-12 text-info">{{insured.firstname}} {{insured.lastname}}</div>
                                <div class="col-12">{{insured.gender}} &#8226; {{insured.dob}}</div>
                            </div>
                        </div>
                        <div class="col-md-6 text-right">
                            <div class="row">
                                <div class="col-12 pt-3"><label class="text-uppercase text-muted mt-2 mb-0">{{'policy_expiry_label'|translate}}</label></div>
                                <div class="col-12 text-info">{{policy.end_date}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="insurance-card mt-5 mb-5">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-4 pt-4 pl-5"><img src="~@/assets/images/swisscare-logo.svg" alt="Swisscare" class="mt-2 w-100"></div>
                                <div class="col-8 pt-4 pb-1">
                                    <div class="row">
                                        <div class="col-12"><label class="small text-uppercase text-muted mb-0">{{'emergency_label'|translate}}</label></div>
                                        <div class="col-12 text-info">PHONE</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4 pb-5 purple justify-content-center">
                        <div class="col-10 col-md-6 pt-3 mt-2">
                            <div v-html="policy.insurance.cover"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../router'
export default {
    name: 'policy_insurance_card',
    props:['policy'],
    mounted() {
        this.$parent.pageTitle = 'Insurance card'
    }
}
</script>

<style scoped>
    .purple{
        background-image: linear-gradient(-229deg, #C3A6F3 0%, #7B8BF2 100%);
        color:#fff;
    }
</style>
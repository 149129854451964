<template>
    <div>
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <div class="tab px-4 py-1 text-uppercase mb-2" v-on:click="changeTab('policyholder')" role="button" :class="{'active': currentTab == 'policyholder' }">{{'policyholder_address_label'|translate}}</div>
                    </div>
                    <div class="col-auto">
                        <div class="tab px-4 py-1 text-uppercase mb-2" v-on:click="changeTab('correspondence')" role="button" :class="{'active': currentTab == 'correspondence' }">{{'correspondence_address_label'|translate}}</div>
                    </div>
                </div>
                <div class="px-3 px-md-5" id="editPHAddress" v-show="currentTab == 'policyholder'">
                    <div class="row">
                        <div class="col-12"><h2 class="text-capitalize">{{'edit_address_label'|translate}}</h2></div>
                    </div>
                    <form id="editPHAddress-form" data-vv-scope="editPHAddress">
                        <div class="row">
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'address1_label'|translate}}</label>
                                    <input type="text" id="address1" name="address1" v-model="policyData.address.address1" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.address1')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'address2_label'|translate}}</label>
                                    <input type="text" id="address2" name="address2" v-model="policyData.address.address2">
                                </fieldset>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'country_label'|translate}}</label>

                                    <div class="select-wrapper">
                                        <select id="country" name="country" v-model="policyData.address.country" v-validate="'required'">
                                            <option v-for="country in policy.ph_countries" v-bind:value="country.id">{{country.name}}</option>    
                                        </select>
                                    </div>
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.country')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <label>{{'zip_code_label'|translate}}</label>
                                    <input type="text" id="zip" name="zip" v-model="policyData.address.zip" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.zip')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <label>{{'city_label'|translate}}</label>
                                    <input type="text" id="city" name="city" v-model="policyData.address.city" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.city')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-4">
                                <fieldset class="countrycode">
                                    <label>{{'country_code_label'|translate}}</label>
                                    <input type="number" min="1" name="countrycode" id="countrycode" v-model="policyData.address.countrycode" v-validate="'required|numeric'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.countrycode')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-8">
                                <fieldset>
                                    <label>{{'phone_label'|translate}}</label>
                                    <input type="text" id="phone" name="phone" v-model="policyData.address.phone" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.phone')">{{'this_field_required_label'|translate}}</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-5">
                            <div class="col-auto">
                                <button type="button" class="btn smaller" v-on:click="editPHAddress">{{'save_label'|translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="px-3 px-md-5" id="editCRAddress" v-show="currentTab == 'correspondence'">
                    <div class="row">
                        <div class="col-12"><h2 class="text-capitalize">{{'edit_correspondence_address_label'|translate}}</h2></div>
                    </div>
                    <form id="editCRAddress-form" data-vv-scope="editCRAddress">
                        <div class="row">
                            <div class="col-12">
                                <label class="cb-container mb-4" >{{'im_company_label'|translate}}
                                    <input type="checkbox" id="iscompany" name="iscompany" v-model="policyData.correspondence.iscompany">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-12" v-show="!policyData.correspondence.iscompany">
                                <fieldset>
                                    <label>{{'firstname_label'|translate}}</label>
                                    <input type="text" id="cr_firstname" name="cr_firstname" v-model="policyData.correspondence.firstname" v-validate="policyData.correspondence.iscompany ? '' : 'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_firstname')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12" v-show="!policyData.correspondence.iscompany">
                                <fieldset>
                                    <label>{{'lastname_label'|translate}}</label>
                                    <input type="text" id="cr_lastname" name="cr_lastname" v-model="policyData.correspondence.lastname" v-validate="policyData.correspondence.iscompany ? '' : 'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_lastname')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12"  v-show="!policyData.correspondence.iscompany">
                                <fieldset class="no-border">
                                    <label>{{'gender_label'|translate}}</label>
                                    <div class="radio-group">
                                        <div class="side">
                                            <input type="radio" value="male" id="cr_gender-male" name="cr_gender" v-model="policyData.correspondence.gender" v-validate="policyData.correspondence.iscompany ? '' : 'required'" >
                                            <label class="side-label" for="cr_gender-male">{{'male_label'|translate}}</label>
                                            <div class="color"></div>
                                        </div>
                                        <div class="side">
                                            <input type="radio" value="female" id="cr_gender-female" name="cr_gender" v-model="policyData.correspondence.gender">
                                            <label class="side-label" for="cr_gender-female">{{'female_label'|translate}}</label>
                                            <div class="color"></div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_gender')">{{'this_field_required_label'|translate}}</span>
                            </div>                            
                            <div class="col-12" v-show="policyData.correspondence.iscompany">
                                <fieldset>
                                    <label>{{'company_label'|translate}}</label>
                                    <input type="text" id="cr_company" name="cr_company" v-model="policyData.correspondence.company" v-validate="!policyData.correspondence.iscompany ? '' : 'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_company')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'address1_label'|translate}}</label>
                                    <input type="text" id="address1" name="address1" v-model="policyData.correspondence.address1" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.address1')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'address2_label'|translate}}</label>
                                    <input type="text" id="address2" name="address2" v-model="policyData.correspondence.address2">
                                </fieldset>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'country_label'|translate}}</label>

                                    <div class="select-wrapper">
                                        <select id="country" name="country" v-model="policyData.correspondence.country" v-validate="'required'">
                                            <option v-for="country in countries" v-bind:value="country.id">{{country.name}}</option>    
                                        </select>
                                    </div>
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.country')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <label>{{'zip_code_label'|translate}}</label>
                                    <input type="text" id="zip" name="zip" v-model="policyData.correspondence.zip" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.zip')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <label>{{'city_label'|translate}}</label>
                                    <input type="text" id="city" name="city" v-model="policyData.correspondence.city" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.city')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-4">
                                <fieldset class="countrycode">
                                    <label>{{'country_code_label'|translate}}</label>
                                    <input type="number" min="1" name="countrycode" id="countrycode" v-model="policyData.correspondence.countrycode" v-validate="'required|numeric'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.countrycode')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-8">
                                <fieldset>
                                    <label>{{'phone_label'|translate}}</label>
                                    <input type="text" id="phone" name="phone" v-model="policyData.correspondence.phone" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.phone')">{{'this_field_required_label'|translate}}</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-5">
                            <div class="col-auto">
                                <button type="button" class="btn smaller" v-on:click="editCRAddress">{{'save_label'|translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
    </div>
</template>

<script>
var qs = require('qs'); 

export default {
    props:['policy'],
    data(){
        return {
            policyDetails: null,
            countries:null,
            currentTab: 'policyholder',
            policyData:{
                id: this.policy.id,
                address:{
                    address1: this.policy.ph_address1,
                    address2: this.policy.ph_address2,
                    country: this.policy.ph_country,
                    zip: this.policy.ph_zip,
                    city: this.policy.ph_city,
                    countrycode: this.policy.ph_phone_country,
                    phone: this.policy.ph_phone,
                },
                correspondence:{
                    address1: this.policy.cr_address1,
                    address2: this.policy.cr_address2,
                    country: this.policy.cr_country,
                    zip: this.policy.cr_zip,
                    city: this.policy.cr_city,
                    countrycode: this.policy.cr_phone_country,
                    phone: this.policy.cr_phone,
                    firstname: this.policy.cr_firstname,
                    lastname: this.policy.cr_lastname,
                    gender: this.policy.cr_gender,
                    company: this.policy.cr_company,
                    iscompany: this.policy.cr_is_company,
                }
            }
        }
    },
    methods:{
        changeTab: function(tab){
            this.currentTab = tab
        },
        changePassword: function(){
            
        },
        editCRAddress: function(){
            var self = this

            self.$validator.validateAll('editCRAddress').then((result) => {
                if (result){
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            authtoken: self.$root.user.authtoken,

                            id: self.policyData.id,
                            firstname: self.policyData.correspondence.firstname,
                            lastname: self.policyData.correspondence.lastname,
                            gender: self.policyData.correspondence.gender,
                            company: self.policyData.correspondence.company,
                            phone_country: self.policyData.correspondence.countrycode,
                            phone: self.policyData.correspondence.phone,
                            address1: self.policyData.correspondence.address1,
                            address2: self.policyData.correspondence.address2,
                            zip: self.policyData.correspondence.zip,
                            city: self.policyData.correspondence.city,
                            country: self.policyData.correspondence.country,
                            is_company: self.policyData.correspondence.iscompany,
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'editPolicyCRAddress'
                    }).then(function (response) {
                        if(response.data.result){
                            self.$parent.$parent.policy = response.data.data

                            self.$notify({
                              group: 'notif',
                              type:'success',
                              text: self.$root.labels['succesfull_correspondence_address_save']
                            });
                        }else{
                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: response.data.msg
                            });
                            
                            self.checkToken(response.data.code);
                        }
                    }).catch(function (error) {
                        console.log(error);

                        self.$notify({
                          group: 'notif',
                          type:'danger',
                          text: error
                        });
                    });  
                }
            });
            
        },
        editPHAddress: function(){
            var self = this

            self.$validator.validateAll('editPHAddress').then((result) => {
                if (result){
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            authtoken: self.$root.user.authtoken,

                            id: self.policyData.id,
                            firstname: self.policyData.firstname,
                            lastname: self.policyData.lastname,
                            gender: self.policyData.gender,
                            company: self.policyData.company,
                            phone_country: self.policyData.address.countrycode,
                            phone: self.policyData.address.phone,
                            address1: self.policyData.address.address1,
                            address2: self.policyData.address.address2,
                            zip: self.policyData.address.zip,
                            city: self.policyData.address.city,
                            country: self.policyData.address.country,
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'editPolicyPHAddress'
                    }).then(function (response) {
                        if(response.data.result){
                            self.$parent.$parent.$parent.policy = response.data.data

                            self.$notify({
                              group: 'notif',
                              type:'success',
                              text: response.data.msg
                            });
                        }else{
                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: response.data.msg
                            });
                            
                            self.checkToken(response.data.code);
                        }
                    }).catch(function (error) {
                        console.log(error);

                        self.$notify({
                          group: 'notif',
                          type:'danger',
                          text: error
                        });
                    });  
                }
            });
        }  
    },
    beforeMount(){
        var self = this

        self.axios({
            data: qs.stringify({
                language: 'en',
                timezone: '+2',
                device: 'desktop',
                version: '1.0',

                authtoken: this.$root.user.authtoken,
            }),
            method: 'post',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: self.$root.apiUrl+'getCountries'
        }).then(function (response) {
            if(response.data.result){
                self.countries = response.data.data
            }else{
                self.checkToken(response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });  
    },
}
</script>

<style scoped>

</style>
<template>
    <div class="login-box mx-auto">
        <div id="login-form" data-vv-scope="login-form">
            <div class="row justify-content-center">
                <div class="col-auto mb-3 mt-2 mt-sm-5">
                    <img src="~@/assets/images/swisscare-logo.svg" width="190px" height="35px" alt="Swisscare">
                </div>
                <div class="col-12 mt-2 mt-sm-3">
                    <fieldset>
                        <label>{{'email_label'|translate}}</label>
                        <input type="text" id="email" name="email" v-model="$parent.input.email" v-validate="'required|email'" v-on:keyup.enter="login" tabindex="1">
                    </fieldset>
                    <span class="field-error">{{ errors.first('login-form.email')}}</span>
                </div>
                <div class="col-12 mt-2 mt-sm-3">
                    <fieldset>
                        <label>{{'password_label'|translate}}</label>
                        <input type="password" id="password" name="password" v-model="$parent.input.password" v-validate="'required'" v-on:keyup.enter="login" tabindex="2">
                    </fieldset>
                    <span class="field-error">{{ errors.first('login-form.password')}}</span>
                </div>
                <div class="col-auto mt-2 mt-sm-5">
                    <button type="button" class="btn" v-on:click="login" tabindex="3">{{'login_label'|translate}}</button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-auto mt-3">
                <a href="#" class="link" v-on:click="$parent.showForgotPass = true">{{'forgot_password_label'|translate}}</a>
            </div>
        </div>
    </div>
</template>

<script>
import router from '../../router'
var qs = require('qs'); 
    
export default {
    name: 'Login',
    data: function () {
        return {
            enteredEmail:null,
        }
    },
    methods:{
        login() {
            var self = this
            self.enteredEmail = self.$parent.input.email;
            self.$validator.validateAll('login-form').then((result) => {
                if(result && !self.$parent.clickedLogin) {
                    self.$parent.clickedLogin = true;
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            username: this.$parent.input.email,
                            password: this.$parent.input.password,
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'login'
                    }).then(function (response) {
                        if(response.data.result){
                            self.$parent.user.isValid = true
                        }else{
                            if(response.data.code == 701){
                                self.showModal(
                                    self.$root.labels['login_label'], 
                                    response.data.msg,
                                    [
                                        {title: self.$root.labels['close_label']},
                                        {title: self.$root.labels['send_activation_label'], handler: () => {
                                            
                                           
                                            self.axios({
                                                data: qs.stringify({
                                                    language: 'en',
                                                    timezone: '+2',
                                                    device: 'desktop',
                                                    version: '1.0',

                                                    username: self.$parent.input.email
                                                }),
                                                method: 'post',
                                                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                                                url: self.$root.apiUrl+'resendActivation'
                                            }).then(function (response) {
                                                self.hideModal()
                                                self.showModal(self.$root.labels['login_label'], response.data.msg,[{title: self.$root.labels['close_label']}])
                                            }).catch(function (error) {
                                                console.log(error);
                                            });    
                                            
                                        }}
                                    ]
                                );
                            }else{
                                self.showModal(self.$root.labels['login_label'], response.data.msg,[{title: self.$root.labels['close_label']}])
                            }
                            self.$parent.clickedLogin = false;
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            });
        }
    },
}

</script>

<style scoped>
    .alert{
        color:#EB3152;
        background:#FFF2F5;
        cursor:pointer;
        font-size:12px;
    }
</style>
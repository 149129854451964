<template>
    <div id="login">
        <div class="login-row">
            <div class="col left" v-if="!showSwitch">
                <h1 v-html="labels.h1"></h1>
                {{'login_box_text'|translate}}
            </div>
            <div class="col">
                <loginBox v-if="!user.isValid && !showForgotPass && !showReset && !showActivate && !showSwitch"></loginBox>
                <twofa v-if="user.isValid && !showForgotPass && !showReset && !showSwitch"></twofa>
                <forgotPassword v-if="showForgotPass && !showReset && !showSwitch"></forgotPassword>
                <resetPassword v-if="showReset"></resetPassword>
                <activate v-if="showActivate"></activate>
            </div>
        </div>
      <div class="p-4">
        <switching v-if="showSwitch" :token="this.$route.params.token"></switching>
      </div>

    </div>

</template>

<script>
import router from '../router'
import loginBox from '@/components/modules/login_box.vue'
import twofa from '@/components/modules/twofa.vue'
import forgotPassword from '@/components/modules/forgotPassword.vue'
import resetPassword from '@/components/modules/resetPassword.vue'
import activate from '@/components/modules/activate.vue'
import Switching from "@/components/Switching";

var qs = require('qs');

export default {
    name: 'Login',
    components:{
      Switching,
        loginBox,
        twofa,
        forgotPassword,
        resetPassword,
        activate
    },
    props:['showReset','showActivate','showSwitch'],
    data() {
        return {
            showForgotPass: false,
            clickedLogin:false,
            user:{
                isValid: false,
                authtoken: null,
            },
            input: {
                email: null,
                password: null,
                verification: null
            },
            labels:{
                h1: this.$root.labels.login_box_title
            }
        }
    },
    methods: {
    },
    beforeCreate: function(){
        if(localStorage.getItem('authtoken')){
            this.$root.user.authtoken = localStorage.authtoken
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: self.$root.user.authtoken,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getProfile'
            }).then(function (response) {
                if(response.data.result){
                    self.$root.user = response.data.data
                    router.replace({ path: "/policies"})
                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
               console.log(error);
            });
        }
    }
}
</script>

<style scoped>
    #login{
        height:100vh;
        overflow:auto;
        position:relative;
    }
    .login-box {
        width: 100%;
        background: #FFFFFF;
        padding:150px 130px;
        z-index:1;
    }

    .login-box img{
        display:inline-block;
    }

    .login-box .link{
        text-transform: uppercase;
    }

    .login-row{
        display:table;
        width:80%;
        max-width:1280px;
        box-shadow: 0 20px 30px 0 rgba(40,37,59,0.05);
        border-radius:12px;
        margin:auto;
        top:50%;
        left:50%;
        transform:translateX(-50%) translateY(-50%);
        position:absolute;
        overflow:hidden;
    }

    .col{
        display:table-cell;
        vertical-align:middle;
        width:50%;
    }

    .col.left{
        background-color:#FAF7FF;
        background-image:url(../assets/images/login-bg.svg);
        background-position:center bottom;
        background-repeat:no-repeat;
        background-size: contain;
        padding:0 100px 100px 100px;

        font-weight:300;
        font-size: 16px;
        color: #000000;
        line-height: 32px;
    }

    .col.left h1{
        font-weight:300;
        font-size: 42px;
        color: #4E206C;
        margin-bottom:20px;
    }

    @media (max-width: 1200px) {
        .col.left{
            padding:0 50px 50px;
        }
        .login-box{
            padding:100px 50px;
        }
    }

    @media (max-width: 1080px) {
        .col.left{
            font-size:14px;
            line-height:26px;
        }
        .col.left h1{
            font-size:36px;
        }
    }

    @media (max-width: 860px) {
        .col.left{
            display:none;
        }
    }

    @media (max-height: 920px) {
        .login-box{
            padding:100px;
        }
    }
    @media (max-height: 680px) {
        .login-row{
            margin:50px auto;
        }
    }

</style>

<template>
  <div id="switching">
    <form id="switch-form" data-vv-scope="switchForm">
      <div class="row justify-content-center">
        <div class="col-12">
          <div class="row justify-content-center mt-2">
            <div class="col">
              <h1 class="text-center" ref="title">Switching Plans</h1>
              <div class="attention">
                <span>ATTENTION :</span> Switching possible only until <strong>30.09.2024</strong> at latest
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12 col-md-3">
              <fieldset>
                <legend>Policy Number</legend>
                <div class="select-wrapper">
                  <select id="plan" name="plan" v-model="plan" v-validate="'required'" v-on:change="this.loadPlan">
                    <option value=""></option>
                    <option v-for="plan in plans" v-bind:value="plan">{{plan.code}}</option>
                  </select>
                </div>
              </fieldset>
            </div>
            <div class="col-12 col-md-3">
              <fieldset>
                <legend>First Name</legend>
                <input type="text" readonly="true" v-model="plan.firstname">
              </fieldset>
            </div>
            <div class="col-12 col-md-3 px-2">
              <fieldset>
                <legend>Last Name</legend>
                <input type="text" readonly="true" v-model="plan.lastname">
              </fieldset>
            </div>
            <div class="col-12 col-md-3 px-2">
              <fieldset>
                <legend>Date of Birth</legend>
                <input type="text" readonly="true" v-model="plan.dob">
              </fieldset>
            </div>
          </div>


          <div class="row mt-5" v-show="plan && plan.length !== 1">
            <div class="col-12 col-lg-4"></div>
            <div class="col-12 col-lg-4 px-0">
              <div class="plan plan-left">
                <div class="badge">Best Seller</div>
                <div class="title">
                  <fieldset>
                    <div class="select-wrapper">
                      <select id="newPlan" name="newPlan" v-model="selectedPlan" v-validate="'required'" v-on:change="this.switchPlans">
                        <option v-for="planType in planTypes" v-bind:value="planType">{{planType}}</option>
                      </select>
                    </div>
                  </fieldset>
                </div>

                <div class="subtitle">
                  Claims paid in 8 days
                </div>

                <div class="price">CHF {{ newPlan.premium }} <span>/ month</span></div>

                <div class="deductible">
                  <div class="row align-items-center">
                    <div class="col-12 col-lg-auto">
                      Deductible
                    </div>
                    <div class="col-12 col-lg">
                      <fieldset class="simple">
                        <div class="select-wrapper">
                          <select id="deductible" name="deductible" v-model="selectedDeductible" v-on:change="this.switchPlans" v-validate="'required'">
                            <option v-for="deductible in deductibles" v-bind:value="deductible">CHF {{deductible}}</option>
                          </select>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>

                <div class="row pt-2" v-show="selectedDeductible >= 2000">
                  <div class="col-12 ">
                    <small>Swisscare does not recommend a deductible higher than CHF 1500. You will pay full medical bills up to your deductible amount. Deductibles can only be changed once per year (01.01).</small>

                  </div>
                </div>

                <a :href="newPlan.url" target="_blank" v-if="!this.$props.token"><span class="button">Product Detail</span></a>
              </div>

              <div class="row d-flex d-lg-none mb-5" v-show="plan.length !== 1">
                <div class="col-10 pr-0">
                  <ul class="features">
                    <li class="left" v-for="(item,index) in tableItems" :ref="'mlistitem'+index" :id="'mlistitem'+index" v-show="plan.table_items[index] || newPlan.table_items[index]">{{ item }}</li>
                  </ul>
                </div>
                <div class="col-2 pl-0">
                  <ul class="features text-center">
                    <li v-for="(item,index) in newPlan.table_items" :id="'mrightitem'+index" v-show="item !== plan.table_items[index] || item">
                      <img src="../assets/images/icons/check-green.svg" alt="" v-show="item">
                      <img src="../assets/images/icons/cross-red.svg" alt="" v-show="!item">
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 px-0">
              <div class="plan">
                <div class="badge">Current</div>
                <div class="title">
                  <span>
                    {{ plan.plan }}
                  </span>
                </div>

                <div class="subtitle">&nbsp;</div>

                <div class="price">CHF {{ plan.premium }} <span>/ month</span></div>

                <div class="deductible">
                  <div class="row align-items-center h-100">
                    <div class="col-12 col-lg-auto pr-0">
                      Deductible
                    </div>
                    <div class="col-12 col-lg">
                      <span>CHF {{plan.deductible}}</span>
                    </div>
                  </div>
                </div>

                <a :href="plan.url" target="_blank" v-if="!this.$props.token"><span class="button">Product Detail</span></a>
              </div>
              <div class="row d-flex d-lg-none mb-5" v-show="plan && plan.length !== 1">
                <div class="col-10 pr-0">
                  <ul class="features">
                    <li class="left" v-for="(item,index) in tableItems" :ref="'m2listitem'+index" :id="'m2listitem'+index" v-show="plan.table_items[index] || newPlan.table_items[index]">{{ item }}</li>
                  </ul>
                </div>
                <div class="col-2 pl-0">
                  <ul class="features text-center">
                    <li v-for="(item,index) in plan.table_items" :id="'menditem'+index" v-show="item !== newPlan.table_items[index] || item">
                      <img src="../assets/images/icons/check-green.svg" alt="" v-show="item">
                      <img src="../assets/images/icons/cross-red.svg" alt="" v-show="!item">
                    </li>
                  </ul>
                </div>
                <div class="col-12">
                  <div class="telemedicine" v-html="$root.labels['telemedicine_label']"/>
                </div>
              </div>
            </div>

          </div>
          <div class="row d-none d-lg-flex" v-if="plan.length !== 1">
            <div class="col-12 col-lg-4 px-0">
              <ul class="features">
                <li class="left" v-for="(item,index) in tableItems" :ref="'listitem'+index" :id="'listitem'+index" v-show="plan.table_items[index] || newPlan.table_items[index]">{{ item }}</li>
              </ul>
            </div>
            <div class="col-12 col-lg-4 px-0">
              <ul class="features text-center">
                <li v-for="(item,index) in newPlan.table_items" :id="'rightitem'+index" v-show="item !== plan.table_items[index] || item">
                  <img src="../assets/images/icons/check-green.svg" alt="" v-show="item">
                  <img src="../assets/images/icons/cross-red.svg" alt="" v-show="!item">
                </li>
                <li>
                  <div class="telemedicine" v-html="$root.labels['telemedicine_label']"/>
                </li>
              </ul>
            </div>
            <div class="col-12 col-lg-4 px-0">
              <ul class="features text-center">
                <li v-for="(item, index) in plan.table_items" :id="'enditem'+index" v-show="item !== newPlan.table_items[index] || item">
                  <img src="../assets/images/icons/check-green.svg" alt="" v-show="item">
                  <img src="../assets/images/icons/cross-red.svg" alt="" v-show="!item">
                </li>
              </ul>
            </div>

          </div>

          <div class="legal mt-5">
            <div class="title">Legal terms and conditions</div>

            <div class="checkbox-line" v-for="(checkbox,index) in checkboxes" v-if="index<3">
              <div class="row">
                <div class="col-auto">
                  <img src="../assets/images/icons/check-green.svg" width="11px" alt="">
                </div>
                <div class="col pl-0" v-html="checkbox.text"/>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="cb-container" >I agree with the above legal terms and conditions
                  <input type="checkbox" id="terms" name="terms" v-validate="'required'">
                  <span class="checkmark"></span>
                </label>
                <span class="field-error" v-show="errors.first('switchForm.terms')">{{'this_field_required_label'|translate}}</span>
              </div>
            </div>


            <div class="row">
              <div class="col-12 pt-4">
                <div class="title">Data protection</div>
              </div>
              <div class="col-12">
                <label class="cb-container"  style="font-size:13px;line-height:1.25em"><em v-html="checkboxes[3].text"/>
                  <input type="checkbox" id="agree" name="agree" v-validate="'required'">
                  <span class="checkmark"></span>
                </label>
                <span class="field-error" v-show="errors.first('switchForm.agree')">{{'this_field_required_label'|translate}}</span>
              </div>
            </div>

          </div>

          <div class="row mt-4">
            <div class="col-12 info-text" style="padding:0 40px; font-size:14px;font-weight:500;">
              If you have ongoing treatments, your claims will continue to be settled also under the new plan.
            </div>
            <div class="col-12 pt-4 terms" v-if="plan.length !== 1" style="padding:0 35px">
              <label class="cb-container mb-4" style="font-size:13px;line-height:1.25em" >I am formally requesting to switch to the new plan on January 1st, 2025, and terminate my existing insurance ({{plan.code}}) on December 31st, 2024.
                <input type="checkbox" id="request" name="request" v-validate="'required'">
                <span class="checkmark"></span>
              </label>
              <span class="field-error" v-show="errors.first('switchForm.request')">{{'this_field_required_label'|translate}}</span>
            </div>
          </div>

          <button type="button" class="btn w-100 mt-4" v-on:click="saveSwitch" v-if="plan.length !== 1" :disabled="!selectedPlan || !selectedDeductible || !enableSwitch">
            Switch plan from 01.01.2025
          </button>
        </div>
      </div>
    </form>

    <div class="new-popup" v-if="showNotif">
      <div class="popup-bg" v-on:click="goToHome"></div>
      <div class="popup-inner">
        <div class="close" v-on:click="goToHome"><i class="icon-cross"></i></div>

        <div class="title">Well done :)</div>

        As per your request, we confirm that your new {{ selectedPlan }} insurance plan will start on <strong>January 1st, 2025. Your current SPSS plan will terminate on December 31st, 2024.</strong>

      </div>
    </div>

  </div>
</template>

<script>
import router from '../router'
var qs = require('qs');

export default {
  name: 'switching',
  props:['token'],
  data(){
    return {
      showNotif: false,
      enableSwitch: true,
      plans: Object,
      plan: Object,
      selectedPlan: this.plan && this.plan.suggested,
      newPlan: Object,
      deductibles : [
        300,500,1000,1500,2000,2500
      ],
      selectedDeductible: null,
      planTypes : [
        'essential', 'superior'
      ],
      checkboxes : [],
      tableItems : []
    }
  },
  components:{
  },
  methods:{

    sameHeight:function(){
      const self = this;
      setTimeout(function(){
        for (let index = 0; index < 50; index++) {
          if(self.$refs['listitem'+index]){
            document.getElementById('rightitem'+index).style.height = document.getElementById('listitem'+index).clientHeight+'px'
            document.getElementById('enditem'+index).style.height = document.getElementById('listitem'+index).clientHeight+'px'

            //MOBILE
            document.getElementById('mrightitem'+index).style.height = document.getElementById('mlistitem'+index).clientHeight+'px'
            document.getElementById('menditem'+index).style.height = document.getElementById('m2listitem'+index).clientHeight+'px'
          }
        }
      },500)
    },

    goToHome:function(){
      if(this.$props.token){
        document.location.href = 'swisscare://completeSwitching';
        //router.push({ path: "swisscare://completeSwitching"})
      }else{
        router.push({ path: "/policies"})
      }
    },

    getPlans :function(){
      var self = this

      self.axios({
        data: qs.stringify({
          language: 'en',
          timezone: '+2',
          device: 'desktop',
          version: '1.0',

          authtoken: self.$root.user.authtoken || self.$props.token,
        }),
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: self.$root.apiUrl+'getSwitchingPlans'
      }).then(function (response) {

        if(response.data.result){

          if(response.data.data.policies.length === 0){
            self.$root.user.need_switching = false
          }

          self.plans = response.data.data.policies
          self.plan = self.plans[0]
          self.checkboxes = response.data.data.checkboxes
          self.tableItems = response.data.data.table_items

          self.loadPlan()

        }else{
          self.checkToken(response.data.code);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },

    loadPlan: function(){
      const self = this;
      self.selectedPlan = self.plan.suggested || self.planTypes[0]
      self.selectedDeductible = self.plan.deductible || self.deductibles[0]

      self.switchPlans();
    },

    switchPlans : async function(){
      var self = this
      self.enableSwitch = false

      if(self.selectedPlan && self.selectedDeductible){
        self.axios({
          data: qs.stringify({
            language: 'en',
            timezone: '+2',
            device: 'desktop',
            version: '1.0',

            authtoken: self.$root.user.authtoken || self.$props.token,
            policy_id: self.plan.id,
            plan: self.selectedPlan ,
            deductible: self.selectedDeductible
          }),
          method: 'post',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: self.$root.apiUrl+'calcSwitchingPlan'
        }).then(function (response) {
          if(response.data.result){
            self.newPlan = response.data.data;
            self.sameHeight();
          }else{
            self.checkToken(response.data.code);
          }
          self.enableSwitch = true
        }).catch(function (error) {
          console.log(error);
        });
      }
    },

    saveSwitch: function(){
      var self = this

      self.$validator.validateAll('switchForm').then((result) => {
        if (result){
          self.axios({
            data: qs.stringify({
              language: 'en',
              timezone: '+2',
              device: 'desktop',
              version: '1.0',

              authtoken: self.$root.user.authtoken || self.$props.token,

              policy_id: self.plan.id,
              plan: self.selectedPlan,
              deductible: self.selectedDeductible,
            }),
            method: 'post',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: self.$root.apiUrl+'saveSwitchingPlan'
          }).then(function (response) {
            if(response.data.result){
              self.showNotif = true;
              self.getPlans();
            }else{
              self.$notify({
                group: 'notif',
                type:'danger',
                text: response.data.msg
              });

              self.checkToken(response.data.code);
            }
          }).catch(function (error) {
            console.log(error);

            self.$notify({
              group: 'notif',
              type:'danger',
              text: error
            });
          });
        }
      });
    }
  },
  mounted() {
    const self = this;
    self.$parent.showLoader = false
    self.getPlans();
    self.sameHeight();
  },
  created() {
    window.addEventListener('resize', this.sameHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.sameHeight);
  },
}
</script>

<style scoped>
h1{
  font-size:42px;
}
.attention{
  font-weight:500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-top:30px;
}

.attention span{
  color: #EB3152;
  font-weight:bold;
}

.attention strong{
  font-weight:bold;
}

fieldset{
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
  padding-bottom: 10px;
}

fieldset legend{
  font-weight:bold;
  font-size: 10px;
  color: rgba(0,0,0,.3);
  letter-spacing: 0;
  line-height: 18px;
}

p{
  font-weight:normal;
  font-size: 19px;
  color: #3A3A3A;
  letter-spacing: 0;
  text-align: center;
  line-height: 28px;
}

.features{
  list-style:none;
  border-top: 1px solid #E8DEE9;
}

.features li{
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.3px;
  padding:15px;
  border-left: 1px solid #E8DEE9;
  font-weight:600;
  display:flex;
  align-items: center;
}

.features li:nth-child(odd){
  background:#F8F8FB;
}

.features li.left{
  border-left:none;
}

.features li img{
    margin:auto;
}

.plan{
  background: #FFFFFF;
  border-top: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  border-left: 1px solid #E6E6E6;
  border-radius: 0 16px 0 0;
  padding:35px 30px;
}

.plan.plan-right{
  border-left: unset;
  border-radius: 0 16px 0 0;
  background-image: linear-gradient(179deg, #F2F0FF 0%, #FFFFFF 100%);
}
.plan.plan-left{
  border-right: unset;
  border-radius: 16px 0 0 0;
  background-image: linear-gradient(179deg, #F2F0FF 0%, #FFFFFF 100%);
}

.plan .badge{
  background: #C3A6F3;
  border-radius: 12px;
  font-weight:bold;
  font-size: 11px;
  color: #FFFFFF;
  letter-spacing: -0.23px;
  line-height: 27px;
  position:absolute;
  padding:0 12px;
  text-transform: uppercase;
  top:-12px;
}

.plan.plan-right .badge{
  background: #F1405F;
}
.plan.plan-left .badge{
  background: #F1405F;
}

.plan .title{
  border-bottom: 1px solid #E6E6E6;
  font-size: 30px;
  color: #000000;
  letter-spacing: -0.98px;
  width:100%;
  height:35px;
  line-height:28px;
  font-weight:600
}

.plan .title fieldset{
  margin:unset;
  padding:unset;
  border:none;
}

.plan .title .select-wrapper select{
  font-size: 30px;
  color: #000000;
  height:auto;
  cursor:pointer;
  text-transform: capitalize;
  line-height:28px;
  font-weight:600;
}

.plan .title .select-wrapper select option{
  font-size:16px !important;
}

.plan button,
.plan .button{
  background: #F2F1FA;
  border-radius: 6px;
  font-weight:bold;
  font-size: 13px;
  color: #4E206C;
  letter-spacing: 0;
  text-align: center;
  border:none;
  width:100%;
  padding:12px 0;
  margin-top:20px;
  transition:300ms all ease-in-out;
  cursor:pointer;
  text-transform:uppercase;
  display: block;
}

.plan button:hover,
.plan .button:hover{
  background: #e5e4f1;
}


.plan .price{
  font-weight:bold;
  font-size: 28px;
  color: #000000;
  letter-spacing: -1.3px;
  line-height: 20px;
  margin-top:45px;
}

.plan .price span{
  font-weight:500;
  font-size: 12px;
  color: rgba(0,0,0,.5);
  line-height: 20px;
}

.plan .deductible{
  font-size: 17px;
  color: #000000;
  letter-spacing: -0.57px;
  line-height: 20px;
  margin-top:30px;
  font-weight:500;
}

.plan .deductible span{
  height:45px;
  display:inline-block;
  line-height:45px;
  font-weight:600;
}

.plan .subtitle{
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.19px;
  line-height: 16px;
  margin-top:15px;
  min-height:2.5em;
}

fieldset.simple{
  padding:0;
  border:none;
  margin:0;
}

fieldset.simple .select-wrapper{
  background: #FFFFFF;
  border: 1px solid rgba(154,96,161,0.24);
  border-radius: 6px;
  padding:10px;
}

fieldset.simple .select-wrapper:after{
  top:14px;
  right:15px;
}

.legal{
  background: #F8F8FB;
  border-radius: 8px;
  padding:25px;
  font-size: 13px;
  color: #000000;
  letter-spacing: -0.24px;
  line-height: 19px;
}

.legal .checkbox-line{
  margin-top:15px;
}

.legal .cb-container{
  margin-top:25px;
}

.telemedicine{
  padding:20px;
  font-weight:500;
  font-size: 11px;
  color: #000000;
  letter-spacing: -0.18px;
  line-height: 16px;
}

button:disabled:hover{
  background:#e5e4f1;
  cursor: not-allowed;
}

</style>

<template>
    <div v-if="$root.user">
        <form data-vv-scope="claims-form">
            <div class="row">
                <div v-for="policy in policies" class="col-12 col-md-6">
                    <label :for="'policy_'+policy.id" class="policy-card">
                        <div class="row" >
                            <div class="col-2">
                                <div class="cb-container">
                                    <input type="radio" name="policy" :id="'policy_'+policy.id" :value="policy.id" v-model="$parent.claim.id" v-on:change="changePolicy(policy)" v-validate="'required'">
                                    <span class="checkmark"></span>
                                </div>
                            </div>
                            <div class="col-7 px-0">{{policy.insurance.name}}</div>
                            <div class="col-3 px-0 pl-3 text-right"><small>{{policy.code}}</small></div>
                        </div>
                    </label>
                </div>
            </div>
            <div class="row justify-content-start">
                <div class="col-auto"><span class="field-error" v-show="errors.first('claims-form.policy')">{{'please_select_label'|translate}}</span></div>
            </div>
            <div class="row justify-content-center mt-5">
                <div class="col-auto">
                    <button type="button" class="btn" v-on:click="nextStep">{{'next_label'|translate}}</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import router from '../router'
import moment from 'moment'

var qs = require('qs');

export default {
    name: 'claims_policies',
    components:{

    },
    props:{

    },
    data(){
        return{
            policies: null
        }
    },
    methods:{
        changePolicy: function(policy){
          this.$parent.claim.insurance_code = policy.insurance.code
          this.$parent.claim.is_migrated = policy.is_migrated
          this.$parent.claim.need_extra_info = policy.need_extra_info
          //this.$parent.claim.date = moment(policy.start_date,'YYYY-MM-DD').format('YYYY-MM-DD')
          this.$parent.claim.start_date = moment(policy.start_date,'DD.MM.YYYY').format('YYYY-MM-DD')
          this.$parent.claim.end_date = moment(policy.end_date,'DD.MM.YYYY').format('YYYY-MM-DD')
        },

        getClaimPolicies: function(){
            var self = this

            self.axios({
                data: qs.stringify({
                    language: 'en',
                    timezone: '+2',
                    device: 'desktop',
                    version: '1.0',

                    authtoken: self.$root.user.authtoken,
                }),
                method: 'post',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: self.$root.apiUrl+'getClaimPolicies'
            }).then(function (response) {
                if(response.data.result){
                    self.policies = response.data.data
                }else{
                    self.checkToken(response.data.code);
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        nextStep: function(){
            var self = this
            self.$validator.validateAll('claims-form').then((result) => {
                if (result){
                    this.$parent.currentStep = 2;
                    router.replace({ path: "/claims/type"})
                }
            });
        }
    },
    beforeMount() {
        this.getClaimPolicies()
        this.$parent.currentStep = 1

        if(this.$parent.currentStep != 1){
            this.$parent.currentStep = 1
            router.replace({ path: "/claims/policies"});
        }
    }
}
</script>

<style scoped>
    .policy-card{
        background: #FFFFFF;
        border: 1px solid #E9E9E9;
        box-shadow: 0 5px 20px 0 rgba(78,75,96,0.10);
        font-size: 15px;
        color: #373737;
        letter-spacing: 0;
        line-height: 22px;
        padding:25px 20px;
        border-radius:8px;
        cursor:pointer;
        width:100%;
    }

    .policy-card small{
        font-size: 10px;
        letter-spacing: 0;
        text-align: right;
        line-height: 14px;
        color: rgba(58,58,58,0.45);
    }

    .policy-card label{
        margin-bottom:0;
    }

    .policy-card .cb-container{
        padding-left:0;
    }

    .policy-card .cb-container .checkmark{
        position:relative;
        display:inline-block;
    }

    .policy-card .row{
        display:table;
        width:100%;
    }
    .policy-card .row > div{
        display:inline-flex;
        vertical-align:middle;
    }
</style>

<template>
    <div id="popright">
        <div class="close" v-on:click="$parent.showPopright = false"><i class="icon-cross"></i></div>
        <div class="scrolling-inner">
            <div class="inner px-3 py-5 px-md-5">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <div class="tab px-4 py-1 text-uppercase mb-2" v-on:click="changeTab('policyholder')" role="button" :class="{'active': $parent.currentTab == 'policyholder' }">{{'address_label'|translate}}</div>
                    </div>
                    <div class="col-auto">
                        <div class="tab px-4 py-1 text-uppercase mb-2" v-on:click="changeTab('correspondence')" role="button" :class="{'active': $parent.currentTab == 'correspondence' }">{{'correspondence_address_label'|translate}}</div>
                    </div>
                    <div class="col-auto">
                        <div class="tab px-4 py-1 text-uppercase" v-on:click="changeTab('password')" role="button" :class="{'active': $parent.currentTab == 'password' }">{{'change_password_label'|translate}}</div>
                    </div>
                </div>
                <div class="px-3 px-md-5" id="editPHAddress" v-show="$parent.currentTab == 'policyholder'">
                    <div class="row">
                        <div class="col-12"><h2 class="text-capitalize">{{'edit_address_label'|translate}}</h2></div>
                    </div>
                    <form id="editPHAddress-form" data-vv-scope="editPHAddress">
                        <div class="row">
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'address1_label'|translate}}</label>
                                    <input type="text" id="address1" name="address1" v-model="$parent.userData.address.address1" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.address1')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'address2_label'|translate}}</label>
                                    <input type="text" id="address2" name="address2" v-model="$parent.userData.address.address2">
                                </fieldset>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'country_label'|translate}}</label>

                                    <div class="select-wrapper">
                                        <select id="country" name="country" v-model="$parent.userData.address.country" v-validate="'required'">
                                            <option v-for="country in countries" v-bind:value="country.id">{{country.name}}</option>    
                                        </select>
                                    </div>
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.country')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <label>{{'zip_code_label'|translate}}</label>
                                    <input type="text" id="zip" name="zip" v-model="$parent.userData.address.zip" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.zip')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <label>{{'city_label'|translate}}</label>
                                    <input type="text" id="city" name="city" v-model="$parent.userData.address.city" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.city')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-sm-4">
                                <fieldset class="countrycode">
                                    <label>{{'country_code_label'|translate}}</label>
                                    <input type="number" min="1" name="countrycode" id="countrycode" v-model="$parent.userData.address.countrycode" v-validate="'required|numeric'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.countrycode')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-sm-8">
                                <fieldset>
                                    <label>{{'phone_label'|translate}}</label>
                                    <input type="text" id="phone" name="phone" v-model="$parent.userData.address.phone" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPHAddress.phone')">{{'this_field_required_label'|translate}}</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-5">
                            <div class="col-auto">
                                <button type="button" class="btn smaller" v-on:click="editPHAddress">{{'save_label'|translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="px-3 px-md-5" id="editCRAddress" v-show="$parent.currentTab == 'correspondence'">
                    <div class="row">
                        <div class="col-12"><h2 class="text-capitalize">{{'edit_correspondence_address_label'|translate}}</h2></div>
                    </div>
                    <form id="editCRAddress-form" data-vv-scope="editCRAddress">
                        <div class="row">
                            
                            <div class="col-12 col-md-6">
                                <label class="cb-container mb-4" >{{'im_company_label'|translate}}
                                    <input type="checkbox" id="iscompany" name="iscompany" v-model="$parent.userData.correspondence.iscompany">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-12 col-md-12" v-show="!$parent.userData.correspondence.iscompany">
                                <fieldset>
                                    <label>{{'firstname_label'|translate}}</label>
                                    <input type="text" id="cr_firstname" name="cr_firstname" v-model="$parent.userData.correspondence.firstname" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_firstname')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-12" v-show="!$parent.userData.correspondence.iscompany">
                                <fieldset>
                                    <label>{{'lastname_label'|translate}}</label>
                                    <input type="text" id="cr_lastname" name="cr_lastname" v-model="$parent.userData.correspondence.lastname" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_lastname')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12" v-show="$parent.userData.correspondence.iscompany">
                                <fieldset>
                                    <label>{{'company_label'|translate}}</label>
                                    <input type="text" id="cr_company" name="cr_company" v-model="$parent.userData.correspondence.company">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_company')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12" v-show="!$parent.userData.correspondence.iscompany">
                                <fieldset class="no-border">
                                    <label>{{'gender_label'|translate}}</label>
                                    <div class="radio-group">
                                        <div class="side">
                                            <input type="radio" value="male" id="cr_gender-male" name="cr_gender" v-model="$parent.userData.correspondence.gender" v-validate="'required'" >
                                            <label class="side-label" for="cr_gender-male">{{'male_label'|translate}}</label>
                                            <div class="color"></div>
                                        </div>
                                        <div class="side">
                                            <input type="radio" value="female" id="cr_gender-female" name="cr_gender" v-model="$parent.userData.correspondence.gender">
                                            <label class="side-label" for="cr_gender-female">{{'female_label'|translate}}</label>
                                            <div class="color"></div>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_gender')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'address1_label'|translate}}</label>
                                    <input type="text" id="cr_address1" name="cr_address1" v-model="$parent.userData.correspondence.address1" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_address1')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'address2_label'|translate}}</label>
                                    <input type="text" id="cr_address2" name="cr_address2" v-model="$parent.userData.correspondence.address2">
                                </fieldset>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'country_label'|translate}}</label>

                                    <div class="select-wrapper">
                                        <select id="cr_country" name="cr_country" v-model="$parent.userData.correspondence.country" v-validate="'required'">
                                            <option v-for="country in countries" v-bind:value="country.id">{{country.name}}</option>    
                                        </select>
                                    </div>
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_country')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <label>{{'zip_code_label'|translate}}</label>
                                    <input type="text" id="cr_zip" name="cr_zip" v-model="$parent.userData.correspondence.zip" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_zip')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-6">
                                <fieldset>
                                    <label>{{'city_label'|translate}}</label>
                                    <input type="text" id="cr_city" name="cr_city" v-model="$parent.userData.correspondence.city" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_city')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-4">
                                <fieldset class="countrycode">
                                    <label>{{'country_code_label'|translate}}</label>
                                    <input type="number" min="1" name="cr_countrycode" id="cr_countrycode" v-model="$parent.userData.correspondence.countrycode" v-validate="'required|numeric'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_countrycode')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12 col-md-8">
                                <fieldset>
                                    <label>{{'phone_label'|translate}}</label>
                                    <input type="text" id="cr_phone" name="cr_phone" v-model="$parent.userData.correspondence.phone" v-validate="'required'">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editCRAddress.cr_phone')">{{'this_field_required_label'|translate}}</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-5">
                            <div class="col-auto">
                                <button type="button" class="btn smaller" v-on:click="editCRAddress">{{'save_label'|translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="px-3 px-md-5" id="changePassword" v-show="$parent.currentTab == 'password'">
                    <div class="row">
                        <div class="col-12"><h2 class="text-capitalize">{{'change_password_label'|translate}}</h2></div>
                    </div>
                    <form id="editPassword-form" data-vv-scope="editPassword">
                        <div class="row">
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'old_password_label'|translate}}</label>
                                    <input type="password" id="password_old" name="password_old" v-model="password_old" v-validate="'required'" :data-vv-as="'old_password_label'|translate">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPassword.password_old')">{{'this_field_required_label'|translate}}</span>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'new_password_label'|translate}}</label>
                                    <div class="password-container">
                                        <input :type="showPassword === true ? 'text' : 'password'" id="password_new" name="password_new" v-model="password_new" v-validate="'required'" ref="password_new">
                                        <i class="fas fa-eye show-icon" v-if="showPassword" @click="showPassword = !showPassword" :data-vv-as="'new_password_label'|translate"></i>
                                        <i class="fas fa-eye-slash hide-icon" v-else  @click="showPassword = !showPassword"></i>
                                        <div class="strength" :class="'level_' + this.strengthLevel"></div>
                                    </div>
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPassword.password_new')">{{'this_field_required_label'|translate}}</span>
                                <div class="password-info" v-show="scorePassword < 40 && scorePassword > 0">{{'password_info_text'|translate}}</div>
                            </div>
                            <div class="col-12">
                                <fieldset>
                                    <label>{{'new_password_again_label'|translate}}</label>
                                    <input type="password" id="password_new_again" name="password_new_again" v-validate="'required|confirmed:password_new'" :data-vv-as="'new_password_again_label'|translate">
                                </fieldset>
                                <span class="field-error" v-show="errors.first('editPassword.password_new_again')">{{errors.first('editPassword.password_new_again')}}</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-5">
                            <div class="col-auto">
                                <button type="button" class="btn smaller" v-on:click="changePassword">{{'save_label'|translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
var qs = require('qs'); 

export default {
    props:['profile'],
    data(){
        return {
            countries:null,
            password_old: '',
            password_new: '',
            showPassword: false
        }
    },
      computed: {
        scorePassword() {
          let self = this;
          let score = 0;
          if (self.password_new === '') return score;

          /*let letters = {};
          for (let i = 0; i < self.password_new.length; i++) {
            letters[self.password_new[i]] = (letters[self.password_new[i]] || 0) + 1;
            score += 5.0 / letters[self.password_new[i]];
          }*/

          let variations = {
            digits: /\d/.test(self.password_new),
            lower: /[a-z]/.test(self.password_new),
            upper: /[A-Z]/.test(self.password_new),
            //special: /\W/.test(self.password_new),
            length: self.password_new.length >= 8
          }

          let variationCount = 0;
          for (let check in variations) {
            variationCount += (variations[check] == true) ? 1 : 0;
          }

          score += (variationCount) * 10;

          return parseInt(score);
        },
        strengthLevel() {
          let pass = this.scorePassword;
          if(pass === 0) return 0;
          if(pass < 15) return 1;
          if(pass < 25) return 2;
          if(pass < 35) return 3;
          if(pass >= 40) return 4;
        }
      },
    methods:{
        changeTab: function(tab){
            this.$parent.currentTab = tab
        },
        changePassword: function(){
            var self = this
            if(self.scorePassword < 40){
                self.$notify({
                  group: 'notif',
                  type:'danger',
                  text: self.$root.labels.password_info_text
                });
            }else{
                self.$validator.validateAll('editPassword').then((result) => {
                    if (result){
                        self.axios({
                            data: qs.stringify({
                                language: 'en',
                                timezone: '+2',
                                device: 'desktop',
                                version: '1.0',

                                authtoken: self.$root.user.authtoken,

                                password: self.password_new,
                                old_password: self.password_old,
                            }),
                            method: 'post',
                            headers: { 'content-type': 'application/x-www-form-urlencoded' },
                            url: self.$root.apiUrl+'changePassword'
                        }).then(function (response) {
                            if(response.data.result){
                                self.$root.user = response.data.data
                                self.$notify({
                                  group: 'notif',
                                  type:'success',
                                  text: response.data.msg
                                });
                            }else{
                                self.$notify({
                                  group: 'notif',
                                  type:'danger',
                                  text: response.data.msg
                                });
                            }
                        }).catch(function (error) {
                            console.log(error);

                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: error
                            });
                        });  
                    }
                });
            }
        },
        editCRAddress: function(){
            var self = this

            self.$validator.validateAll('editCRAddress').then((result) => {
                if (result){
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            authtoken: self.$root.user.authtoken,

                            firstname: self.$parent.userData.correspondence.firstname,
                            lastname: self.$parent.userData.correspondence.lastname,
                            gender: self.$parent.userData.gender,
                            company: self.$parent.userData.company,
                            phone_country: self.$parent.userData.correspondence.countrycode,
                            phone: self.$parent.userData.correspondence.phone,
                            address1: self.$parent.userData.correspondence.address1,
                            address2: self.$parent.userData.correspondence.address2,
                            zip: self.$parent.userData.correspondence.zip,
                            city: self.$parent.userData.correspondence.city,
                            country: self.$parent.userData.correspondence.country,
                            company: self.$parent.userData.correspondence.company,
                            is_company: self.$parent.userData.correspondence.iscompany,
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'editClientCRAddress'
                    }).then(function (response) {
                        if(response.data.result){
                            self.$root.user = response.data.data
                            self.$notify({
                              group: 'notif',
                              type:'success',
                              text: response.data.msg
                            });
                        }else{
                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: response.data.msg
                            });
                            self.checkToken(response.data.code);
                        }
                    }).catch(function (error) {
                        console.log(error);

                        self.$notify({
                          group: 'notif',
                          type:'danger',
                          text: error
                        });
                    });  
                }
            });
            
        },
        editPHAddress: function(){
            var self = this

            self.$validator.validateAll('editPHAddress').then((result) => {
                if (result){
                    self.axios({
                        data: qs.stringify({
                            language: 'en',
                            timezone: '+2',
                            device: 'desktop',
                            version: '1.0',

                            authtoken: self.$root.user.authtoken,
                            
                            phone_country: self.$parent.userData.address.countrycode,
                            phone: self.$parent.userData.address.phone,
                            address1: self.$parent.userData.address.address1,
                            address2: self.$parent.userData.address.address2,
                            zip: self.$parent.userData.address.zip,
                            city: self.$parent.userData.address.city,
                            country: self.$parent.userData.address.country,
                            firstname: self.$parent.userData.firstname,
                            lastname: self.$parent.userData.lastname,
                            gender: self.$parent.userData.gender,
                            is_company: self.$parent.userData.iscompany,
                            company: self.$parent.userData.company,
                        }),
                        method: 'post',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        url: self.$root.apiUrl+'editClientPHAddress'
                    }).then(function (response) {
                        if(response.data.result){
                            self.$root.user = response.data.data

                            self.$notify({
                              group: 'notif',
                              type:'success',
                              text: response.data.msg
                            });
                        }else{
                            self.$notify({
                              group: 'notif',
                              type:'danger',
                              text: response.data.msg
                            });
                            
                            self.checkToken(response.data.code);
                        }
                    }).catch(function (error) {
                        console.log(error);

                        self.$notify({
                          group: 'notif',
                          type:'danger',
                          text: error
                        });
                    });  
                }
            });
        }  
    },
    beforeMount(){
        var self = this

        self.axios({
            data: qs.stringify({
                language: 'en',
                timezone: '+2',
                device: 'desktop',
                version: '1.0',

                authtoken: this.$root.user.authtoken,
            }),
            method: 'post',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: self.$root.apiUrl+'getCountries'
        }).then(function (response) {
            if(response.data.result){
                self.countries = response.data.data
            }else{
                self.checkToken(response.data.code);
            }
        }).catch(function (error) {
            console.log(error);
        });  
    }
}
</script>

<style scoped>
    #popright{
        width:90%;
        max-width:700px;
        background:#fff;
        position:fixed;
        right:0;
        top:0;
        bottom:0;
        left:auto;
        z-index:1000;
        box-shadow: 0 0 40px 0 rgba(0,0,0,0.13);
        -webkit-transition: 600ms all;  
        -moz-transition:     600ms all;
        -ms-transition:      600ms all; 
        -o-transition:       600ms all;  
    }

    #popright .close{
        width:30px;
        height:30px;
        line-height:30px;
        border-radius:50%;
        background: #7B7698;
        color: #FFFFFF;
        text-align:center;
        font-size:14px;
        position:absolute;
        z-index:1002;
        top: 30px;
        left: -15px;
        opacity:1;
        float:none;
    }
    
    #popright:before{
        background: rgba(123,118,152,0.30);
        position:fixed;
        left:0;
        width:100vw;
        height:100vh;
        top:0;
        content:'';
        z-index:-1;
    }
    
    #popright .inner{
        background:#fff;
        z-index:1001;
        position:relative;
        height:100vh;
        width:100%;
        overflow:auto;
    }
</style>
<template lang="pug">
  button(@click.prevent.stop='onClick') {{ label }}
</template>

<script>
import moment from 'moment'

export default {
  name: 'QuickRangeSelectorButton',
  props: {
    label: {
      type: String,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit('today-clicked')
    }
  }
}
</script>

<style scoped>
button {
  font-family: Source Sans Pro,sans-serif;
  font-size: 13px;
  border: 1px solid #00ca9d;
  color: #424b53;
  margin-left: 8px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  height: 40px;
  background: white;
  z-index: 99;
}

button:hover, button:active, button:focus {
  background: rgba(0,202,157,.5);
  color: white;
  border-color: rgba(0,202,157,.5);
  outline: none;
}
</style>

